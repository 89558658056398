import { yupResolver } from "@hookform/resolvers/yup";
import { Button, ButtonGroup } from "@mui/material";
import classNames from "classnames";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import * as Yup from "yup";
import Loading from "../../../../components/Loading/Loading";
import { Plan, PlanDurationType } from "../../../../types";
import { toPurposeOfUse } from "../../../../utils/coimex-utils";
import {
  selectIsGetPlansPending,
  selectPlans,
} from "../../../plans/plans.selectors";
import { useCompanyForm } from "./CompanyFormProvider";
import PlanCard from "../../../../components/PlanCard/PlanCard";

const validationSchema = Yup.object().shape({
  planId: Yup.string().required(""),
});

export default function CompanyFormPlan({ children }) {
  const { company, setCompany, next } = useCompanyForm();
  const plans = useSelector(selectPlans);
  const [durationType, setDurationType] = useState<PlanDurationType>(
    PlanDurationType.Annually
  );
  const isLoading = useSelector(selectIsGetPlansPending);
  const formOptions = {
    resolver: yupResolver(validationSchema),
    mode: "onSubmit",
  };
  const {
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm(formOptions as any);

  const handleSelect = (plan: Plan) => {
    setCompany((company) => ({ ...company, planId: plan.id }));
  };

  const onSubmit = (value) => {
    next(value);
  };

  useEffect(() => {
    if (company.planId && plans.length) {
      setValue("planId", company.planId);
      setDurationType(
        plans.find((p) => p.id == company.planId)?.duration?.type
      );
    }
  }, [company, plans]);

  return (
    <div className="flex-1 w-full">
      {isLoading ? (
        <Loading size={40} />
      ) : (
        <form
          className="h-full flex flex-col gap-4"
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className="relative flex-1 flex flex-col items-center gap-2 px-2">
            <ButtonGroup
              className="mb-2"
              disableElevation
              variant="outlined"
              size="small"
            >
              <Button
                className="normal-case w-32"
                variant={
                  durationType == PlanDurationType.Annually
                    ? "contained"
                    : "outlined"
                }
                onClick={() => setDurationType(PlanDurationType.Annually)}
              >
                Annually
              </Button>
              <Button
                className="normal-case w-32"
                variant={
                  durationType == PlanDurationType.Biannually
                    ? "contained"
                    : "outlined"
                }
                onClick={() => setDurationType(PlanDurationType.Biannually)}
              >
                Biannually
              </Button>
            </ButtonGroup>
            {plans
              .filter(
                (p) =>
                  toPurposeOfUse(p.roles) === company.purposeOfUse &&
                  p.duration.type == durationType
              )
              .map((p) => (
                <PlanCard
                  key={p.id}
                  plan={p}
                  selected={watch("planId") == p.id}
                  onSelect={handleSelect}
                />
              ))}
            {errors?.planId && !watch("planId") && (
              <span className="absolute left-4 -bottom-2.5 self-start text-red-500 text-sm">
                Please select a plan
              </span>
            )}
          </div>
          {children}
        </form>
      )}
    </div>
  );
}
