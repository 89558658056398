import { createSlice } from "@reduxjs/toolkit";
import { Schedule } from "../../types";
import { addCommonReducers } from "../../utils/store-utils";
import { forceRunScheduleAction, getSchedulesAction, updateScheduleAction } from "./schedules.effects";

export const SCHEDULES_STATE_NAME = 'schedules';

export interface SchedulesState {
    schedules: Schedule[]
    pendingForceRunScheduleId: string | null
    pendingUpdateScheduleId: string | null
}

export const schedulesState = createSlice({
    name: SCHEDULES_STATE_NAME,
    initialState: {
        schedules: [],
        pendingForceRunScheduleId: null,
        pendingUpdateScheduleId: null
    } as SchedulesState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getSchedulesAction.fulfilled, (state, action) => {
                state.schedules = action.payload;
            })
            .addCase(forceRunScheduleAction.pending, (state, action) => {
                state.pendingForceRunScheduleId = action.meta.arg.id;
            })
            .addCase(forceRunScheduleAction.fulfilled, (state, action) => {
                state.schedules = [...state.schedules.filter(schedule => schedule.id !== action.payload.id), action.payload];
                state.pendingForceRunScheduleId = null;
            })
            .addCase(forceRunScheduleAction.rejected, (state, action) => {
                state.pendingForceRunScheduleId = null;
            })
            .addCase(updateScheduleAction.pending, (state, action) => {
                state.pendingUpdateScheduleId = action.meta.arg.id;
            })
            .addCase(updateScheduleAction.fulfilled, (state, action) => {
                state.schedules = [...state.schedules.filter(schedule => schedule.id !== action.payload.id), action.payload];
                state.pendingUpdateScheduleId = null;
            })
            .addCase(updateScheduleAction.rejected, (state, action) => {
                state.pendingUpdateScheduleId = null;
            })
        addCommonReducers(builder, SCHEDULES_STATE_NAME);
    }
});

export const schedulesActions = schedulesState.actions;
export const schedulesReducer = schedulesState.reducer;