import { KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { Button, MobileStepper } from "@mui/material";
import { useMemo } from "react";
import CompanyFormAddress from "./CompanyFormAddress";
import CompanyFormCategoriesAndCountries from "./CompanyFormCategoriesAndCountries";
import CompanyFormContact from "./CompanyFormContact";
import CompanyFormInfo from "./CompanyFormInfo";
import CompanyFormPlan from "./CompanyFormPlan";
import { useCompanyForm } from "./CompanyFormProvider";

const STEPS = 5;

export default function CompanyForm() {
  const { step, back, nexting } = useCompanyForm();

  const stepContent: { Component; title } = useMemo(() => {
    switch (step) {
      case 0:
        return {
          Component: CompanyFormInfo,
          title: "Company Info",
        };

      case 1:
        return {
          Component: CompanyFormPlan,
          title: "Plan Subscription",
        };

      case 2:
        return {
          Component: CompanyFormCategoriesAndCountries,
          title: "Interested Categories & Countries",
        };

      case 3:
        return {
          Component: CompanyFormAddress,
          title: "Address Info",
        };

      case 4:
        return {
          Component: CompanyFormContact,
          title: "Business Contact Info",
        };
    }
  }, [step]);

  return (
    <div
      className="w-96 mt-2 p-4 flex flex-col gap-2"
      style={{ minHeight: "200px" }}
    >
      <div className="border-b border-border pb-2 mb-2">
        {stepContent.title}
      </div>
      <stepContent.Component>
        <MobileStepper
          variant="progress"
          color="secondary"
          steps={STEPS}
          position="static"
          activeStep={step}
          nextButton={
            <LoadingButton
              size="small"
              type="submit"
              loading={nexting}
              tabIndex={0}
            >
              {step < STEPS - 1 ? "Next" : "Save"}
              {step < STEPS - 1 && <KeyboardArrowRight />}
            </LoadingButton>
          }
          backButton={
            <Button
              size="small"
              onClick={back}
              disabled={step === 0}
              tabIndex={1}
            >
              <KeyboardArrowLeft />
              Back
            </Button>
          }
        />
      </stepContent.Component>
    </div>
  );
}
