import { DataGrid, GridColDef, GridSortModel } from "@mui/x-data-grid";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import CurrencyFormat from "../../../components/CurrencyFormat/CurrencyFormat";
import NoRowsOverlay from "../../../components/NoRowsOverlay/NoRowsOverlay";
import PaymentMethodChip from "../../../components/PaymentMethodChip/PaymentMethodChip";
import PlanSubscriptionChip from "../../../components/PlanSubscriptionChip/PlanSubscriptionChip";
import TransactionStatusChip from "../../../components/TransactionStatusChip/TransactionStatusChip";
import { Pagination } from "../../../types";
import { getCurrencySymbol } from "../../../utils/coimex-utils";
import { getPaymentsAction } from "../payments.effects";
import {
  selectIsGetPaymentsPending,
  selectPayments,
  selectPaymentsOrderByFilter,
  selectPaymentsPagination,
} from "../payments.selectors";

export default function PaymentsTable() {
  const payments = useSelector(selectPayments);
  const pagination = useSelector(selectPaymentsPagination);
  const isGetPaymentsPending = useSelector(selectIsGetPaymentsPending);
  const orderBy = useSelector(selectPaymentsOrderByFilter);
  const [rows, setRows] = useState([]);
  const dispatch = useDispatch();

  const handlePagination = (
    pg: Partial<Pick<Pagination, "page" | "pageSize">>
  ) => {
    dispatch(getPaymentsAction(pg));
  };

  const handleSorting = (sort: GridSortModel) => {
    dispatch(getPaymentsAction({ filters: { orderBy: sort?.[0] as any } }));
  };

  useEffect(() => {
    setRows(
      payments?.map((payment, index) => ({
        ...payment,
        rowNo: pagination.page * pagination.pageSize + index + 1,
      })) as any
    );
  }, [payments]);

  return (
    <div className="w-full mt-4">
      <DataGrid
        columns={cols}
        rows={rows}
        disableColumnMenu
        disableSelectionOnClick
        style={{ minHeight: "300px", height: "calc(100vh - 200px)" }}
        loading={isGetPaymentsPending}
        pagination
        paginationMode="server"
        autoPageSize
        rowCount={pagination.total}
        page={pagination.page}
        sortingMode="server"
        sortModel={orderBy ? [orderBy] : []}
        onSortModelChange={handleSorting}
        onPageChange={(page) => handlePagination({ page })}
        onPageSizeChange={(pageSize) => handlePagination({ pageSize })}
        components={{
          NoRowsOverlay: () => <NoRowsOverlay emptyMessage="No payments" />,
        }}
      />
    </div>
  );
}

const cols: GridColDef[] = [
  {
    field: "rowNo",
    headerName: "",
    width: 50,
    sortable: false,
  },
  {
    field: "txId",
    headerName: "Transaction Id",
    renderCell: ({ value }) => value ?? "-",
    flex: 1,
  },
  {
    field: "companyName",
    headerName: "Company",
    renderCell: (params) => (
      <Link
        className="link"
        to={`/companies/${params.row.companyId}`}
        onClick={(e) => e.stopPropagation()}
      >
        {params.value}
      </Link>
    ),
    flex: 1,
  },
  {
    field: "paymentMethod",
    headerName: "Payment",
    renderCell: (params) => <PaymentMethodChip method={params.value} />,
    width: 120,
  },
  {
    field: "planType",
    headerName: "Plan",
    renderCell: (params) => (
      <PlanSubscriptionChip
        type={params.value}
        durationType={params.row?.planDuration}
        purposeOfUse={params.row?.planPurposeOfUse}
      />
    ),
    width: 190,
  },
  {
    field: "discount",
    headerName: "Discount",
    renderCell: (params) => (
      <CurrencyFormat
        amount={params.value}
        symbol={getCurrencySymbol(params.row.currency)}
      />
    ),
    width: 120,
  },
  {
    field: "total",
    headerName: "Total",
    renderCell: (params) => (
      <CurrencyFormat
        amount={params.value}
        symbol={getCurrencySymbol(params.row.currency)}
      />
    ),
    width: 120,
  },
  {
    field: "status",
    headerName: "Status",
    renderCell: (params) => (
      <TransactionStatusChip
        status={params.value}
        message={params.row.additionalLog}
        failedStage={params.row.failedStage}
      />
    ),
    width: 170,
  },
  {
    field: "createdAt",
    headerName: "Created At",
    valueFormatter: (params) => moment(params.value).format("YYYY-MM-DD"),
    width: 120,
  },
];
