import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { getOffersAction, getOffersStatusCountsAction } from "./offers.effects";

export const selectOffersState = (state: RootState) => state.offers;

export const selectOffers = createSelector(
    selectOffersState,
    state => state.offers || []
)

export const selectIsGetOffersPending = createSelector(
    selectOffersState,
    state => state[getOffersAction.pending.type]
)

export const selectOffersPagination = createSelector(
    selectOffersState,
    state => state.pagination
)

export const selectOffersStatusCounts = createSelector(
    selectOffersState,
    state => state.statusCounts
)

export const selectOffersUnderViewCount = createSelector(
    selectOffersStatusCounts,
    counts => counts?.UnderReview
)

export const selectIsGetOffersStatusCountsPending = createSelector(
    selectOffersState,
    state => state[getOffersStatusCountsAction.pending.type]
)

export const selectOffersFilters = createSelector(
    selectOffersState,
    state => state.filters
)

export const selectOffersStatusFilter = createSelector(
    selectOffersFilters,
    filters => filters.status
)

export const selectOffersSubCategoryIdFilter = createSelector(
    selectOffersFilters,
    filters => filters.subCategoryId
)

export const selectOffersSearchFilter = createSelector(
    selectOffersFilters,
    filters => filters.search
)

export const selectOffersOrderByFilter = createSelector(
    selectOffersFilters,
    filters => filters.orderBy
)