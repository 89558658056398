import { Button, Card, Chip } from "@mui/material";
import classNames from "classnames";
import SmartDealPromptPayRequestReview from "../../modules/blockchain/components/SmartDealPromptPayReqeustReview";
import { SmartDeal } from "../../modules/blockchain/types";
import { useModal } from "../../providers/ModalProvider";
import { SmartDealPromptPayStatus } from "../../types/smart-deal-prompt-pay-status.type";
import CometStart from "../Svg/CometStart";

interface IProps {
  smartDeal: SmartDeal;
  onUpdate: (update: Partial<SmartDeal>) => void;
  className?: string;
}

export default function SmartDealPromptPayStatusBtn({
  smartDeal,
  onUpdate,
  className,
}: IProps) {
  const openModal = useModal();
  const promptPay = smartDeal?.promptPay;

  const handleReviewClick = async () => {
    const promptPay = await openModal(
      (props) => (
        <SmartDealPromptPayRequestReview {...props} smartDeal={smartDeal} />
      ),
      { hideCloseButton: false }
    );
    if (promptPay) {
      onUpdate({ promptPay });
    }
  };

  return promptPay ? (
    promptPay.status == SmartDealPromptPayStatus.UnderReview ? (
      <Card
        className={classNames(
          "-mt-1 px-3 py-1 flex items-center gap-3 text-sm bg-slate-800 text-white cursor-pointer",
          className
        )}
        onClick={handleReviewClick}
      >
        <span className="rounded-full w-[7px] h-[7px] bg-yellow-400 animate-pulse" />
        Seller Requested for Prompt Pay
        <Button color="yellow" size="small" disableRipple>
          Review
        </Button>
      </Card>
    ) : (
      <Chip
        className={className}
        color={
          promptPay.status == SmartDealPromptPayStatus.Approved
            ? "info"
            : "error"
        }
        label={
          <div className="flex items-center gap-2">
            <CometStart className="stroke-white" />
            Safe Payment{" "}
            {promptPay.status == SmartDealPromptPayStatus.Approved
              ? "Approved"
              : "Rejected"}
          </div>
        }
      />
    )
  ) : null;
}
