import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { useEffectOnce } from "usehooks-ts";
import { AppPage } from "../../../types/app-page.type";
import { AppRefreshStatus } from "../../../types/app-refresh-status.type";
import {
  selectRefreshPage,
  selectRefreshStatus,
} from "../../app/app.selectors";
import {
  getRequestsAction,
  getRequestsStatusCountsAction,
} from "../requets.effects";
import RequestsFilters from "./RequestFilters";
import RequestPage from "./RequestPage";
import RequestsTable from "./RequestsTable";
import { useModal } from "../../../providers/ModalProvider";

export default function Requests() {
  const refreshPage = useSelector(selectRefreshPage);
  const refreshStatus = useSelector(selectRefreshStatus);
  const params = useParams();
  const navigate = useNavigate();
  const openModal = useModal();
  const dispatch = useDispatch();

  const getRequests = () => {
    dispatch(getRequestsStatusCountsAction({ changeRefresh: true }));
    dispatch(getRequestsAction({ page: 0 }));
  };

  const showRequestPage = async (id) => {
    const cancelNavigate = await openModal((props) => (
      <RequestPage id={id} {...props} />
    ));
    if (!cancelNavigate) {
      handleRequestPageClose();
    }
  };

  const handleRequestPageClose = () => {
    navigate("/requests");
  };

  useEffectOnce(() => {
    dispatch(getRequestsStatusCountsAction({ changeRefresh: true }));
  });

  useEffect(() => {
    const { id } = params;
    id && showRequestPage(id);
  }, [params?.id]);

  useEffect(() => {
    if (
      refreshStatus === AppRefreshStatus.Invalidated &&
      refreshPage === AppPage.Requests
    ) {
      getRequests();
    }
  }, [refreshStatus]);

  return (
    <div className="w-constraint">
      <RequestsFilters />
      <RequestsTable />
    </div>
  );
}
