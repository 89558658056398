import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useEffectOnce } from "usehooks-ts";
import { AppPage } from "../../../types/app-page.type";
import { AppRefreshStatus } from "../../../types/app-refresh-status.type";
import { selectRefreshPage, selectRefreshStatus } from "../../app/app.selectors";
import { appActions } from "../../app/app.state";
import { getSchedulesAction } from "../schedules.effects";
import { selectIsGetSchedulesPending, selectSchedules } from "../schedules.selectors";
import SchedulesTable from "./SchedulesTable";

export default function Schedules() {
    const schedules = useSelector(selectSchedules);
    const isGetSchedulesPending = useSelector(selectIsGetSchedulesPending);
    const refreshPage = useSelector(selectRefreshPage);
    const refreshStatus = useSelector(selectRefreshStatus);
    const dispatch = useDispatch();

    const getSchedules = () => {
        dispatch(getSchedulesAction());
    }

    useEffectOnce(() => {
        if (!schedules?.length && !isGetSchedulesPending) {
            getSchedules();
        } else {
            dispatch(appActions.changeRefresh({ page: AppPage.Schedules }));
        }
    })

    useEffect(() => {
        if (refreshStatus === AppRefreshStatus.Invalidated && refreshPage === AppPage.Schedules) {
            getSchedules();
        }
    }, [refreshStatus])

    return (
        <div className="w-constraint">
            <SchedulesTable />
        </div>
    )
}