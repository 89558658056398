import api from ".";
import { SaveInvestPoolRequest } from "../modules/blockchain/types";
import { InvestPool, InvestPoolInvests, InvestPoolMeta, Paginated, PaginationQuery, TokenTransferTx } from "../types";

export const investApi = {
    getInvestPools: async (params?: PaginationQuery): Promise<Paginated<InvestPoolMeta>> => {
        return api.get('/invest/pools', { params });
    },
    getInvestPool: async (id: InvestPool['id']): Promise<InvestPool> => {
        return (await api.get(`/invest/pools/${id}`)).data;
    },
    saveInvestPool: async (model: SaveInvestPoolRequest): Promise<InvestPool> => {
        return (await api.post("/invest/pools", model)).data;
    },
    publishInvestPool: (id: InvestPool['id']): Promise<void> => {
        return api.patch(`/invest/pools/${id}/publish`);
    },
    deleteInvestPool: (id: InvestPool['id']): Promise<void> => {
        return api.delete(`/invest/pools/${id}`);
    },
    getInvestPoolInvests: async (id: InvestPool['id']): Promise<InvestPoolInvests> => {
        return (await api.get(`/invest/pools/${id}/invests`)).data;
    },
    getInvestPoolTxs: async (ids: TokenTransferTx['id'][]): Promise<TokenTransferTx[]> => {
        return (await api.get('/invest/pools/txs', {
            params: { ids }
        })).data;
    }
}