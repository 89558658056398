import { Chip } from "@mui/material";
import {
  getSubscriptionStatusColor,
  getSubscriptionStatusText,
} from "../../../utils/coimex-utils";

interface IProps {
  isActive: boolean;
}

export default function SubscriptionStatusChip({ isActive }: IProps) {
  return (
    <Chip
      label={getSubscriptionStatusText(isActive)}
      color={getSubscriptionStatusColor(isActive)}
      size="small"
      variant="filled"
    />
  );
}
