import { createAsyncThunk } from "@reduxjs/toolkit";
import { dashboardApi } from "../../http/dashboard.api";
import { PurposeOfUse, TimeWindow } from "../../types";
import { RootState } from "../store";

export const getCompaniesCountryDistributionAction = createAsyncThunk(
    'dashboard/getCompaniesCountryDistribution',
    async () => {
        return dashboardApi.getCompaniesCountryDistribution();
    }
)

export const getCurrentPrecedingStatisticsAction = createAsyncThunk(
    'dashboard/getCurrentPrecedingStatistics',
    async ({ days }: { days?: number }, thunk) => {
        days = days ?? (<RootState>thunk.getState()).dashboard.currentPreceding.window;
        return dashboardApi.getCurrentPrecedingStatistics(days);
    }
)

export const getPlanSubscriptionStatisticsAction = createAsyncThunk(
    'dashboard/getPlanSubscriptionStatistics',
    async ({ window }: { window?: TimeWindow }, thunk) => {
        window = window ?? (<RootState>thunk.getState()).dashboard.planSubscription.window;
        return dashboardApi.getPlanSubscriptionStatistics(window);
    }
)

export const getOpportunitiesStatisticsAction = createAsyncThunk(
    'dashboard/getOpportunitiesStatistics',
    async ({ days }: { days?: number }, thunk) => {
        days = days ?? (<RootState>thunk.getState()).dashboard.opportunities.window;
        return dashboardApi.getOpportunitiesStatistics(days);
    }
)

export const getFindOutWhyAction = createAsyncThunk(
    'dashboard/getFindOutWhy',
    async () => {
        return dashboardApi.getFindOutWhy();
    }
)

export const getTopImportersAction = createAsyncThunk(
    'dashboard/getTopImporters',
    async ({ window }: { window?: TimeWindow }, thunk) => {
        window = window ?? (<RootState>thunk.getState()).dashboard.topImporters.window;
        return dashboardApi.getTopCompanies(PurposeOfUse.Importer, window);
    }
)

export const getTopExportersAction = createAsyncThunk(
    'dashboard/getTopExporters',
    async ({ window }: { window?: TimeWindow }, thunk) => {
        window = window ?? (<RootState>thunk.getState()).dashboard.topExporters.window;
        return dashboardApi.getTopCompanies(PurposeOfUse.Exporter, window);
    }
)