import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { getCompaniesAction, getCompaniesStatusCountsAction } from "./companies.effects";

export const selectCompaniesState = (state: RootState) => state.companies;

export const selectCompanies = createSelector(
    selectCompaniesState,
    state => state.companies || []
)

export const selectIsGetCompaniesPending = createSelector(
    selectCompaniesState,
    state => state[getCompaniesAction.pending.type]
)

export const selectCompaniesPagination = createSelector(
    selectCompaniesState,
    state => state.pagination
)

export const selectCompaniesStatusCounts = createSelector(
    selectCompaniesState,
    state => state.statusCounts
)

export const selectCompaniesUnderReviewCount = createSelector(
    selectCompaniesStatusCounts,
    counts => counts?.UnderReview
)

export const selectIsGetCompaniesStatusCountsPending = createSelector(
    selectCompaniesState,
    state => state[getCompaniesStatusCountsAction.pending.type]
)

export const selectCompaniesFilters = createSelector(
    selectCompaniesState,
    state => state.filters
)

export const selectCompaniesStatusFilter = createSelector(
    selectCompaniesFilters,
    filters => filters.status
)

export const selectCompaniesTypeFilter = createSelector(
    selectCompaniesFilters,
    filter => filter.type || ""
)

export const selectCompaniesSearchFilter = createSelector(
    selectCompaniesFilters,
    filters => filters.search
)

export const selectCompaniesOrderByFilter = createSelector(
    selectCompaniesFilters,
    filters => filters.orderBy
)