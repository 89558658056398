import { Chip, Tooltip } from "@mui/material";
import { OpportunityState } from "../../types";
import {
  getOpportunityStateColor,
  OpportunityStateText,
} from "../../utils/coimex-utils";

interface IProps {
  status: OpportunityState;
  remarks?: string;
}

export default function OpportunityStateChip({ status, remarks }: IProps) {
  return (
    <Tooltip title={remarks ?? ""}>
      <Chip
        label={OpportunityStateText[status]}
        color={getOpportunityStateColor(status)}
        size="small"
        variant="outlined"
      />
    </Tooltip>
  );
}
