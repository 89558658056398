import { LoadingButton } from "@mui/lab";
import { Button, CircularProgress, TextField } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { authApi } from "../../http";
import { CloseModal } from "../../providers/ModalProvider";
import CopyToClipboard from "../CopyToClipboard/CopyToClipboard";

interface IProps {
  close: CloseModal<{ twoFactorEnabled: boolean }>;
}

export default function EnableTwoFactorForm({ close }: IProps) {
  const [twoFactor, setTwoFactor] = useState<{
    secret: string;
    qrCode: string;
  }>();
  const [cantScanQrCode, setCantScanQrCode] = useState(false);
  const [isGenerate2faPending, setIsGenerate2faPending] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const tokenRef = useRef<HTMLInputElement>();

  const generate2fa = async () => {
    try {
      setIsGenerate2faPending(true);
      setTwoFactor(await authApi.generate2fa());
      setIsGenerate2faPending(false);
    } catch {
      close();
    }
  };

  const onSubmit = async (data) => {
    try {
      setIsSubmitting(true);
      close(await authApi.enable2fa(true, data.token));
    } catch (error) {
    } finally {
      setIsSubmitting(false);
    }
  };

  useEffect(() => {
    !isGenerate2faPending && tokenRef.current?.focus();
  }, [isGenerate2faPending, cantScanQrCode]);

  useEffect(() => {
    generate2fa();
  }, []);

  return (
    <div className="w-[420px] max-w-full p-4 flex flex-col items-center">
      {isGenerate2faPending ? (
        <CircularProgress className="my-10" />
      ) : (
        <>
          <header className="modal-header w-full text-center">
            Enable two-factor authentication
          </header>
          {cantScanQrCode ? (
            <>
              <p className="px-1">
                When prompted in your authenticator app, enter the following
                key:
              </p>
              <div className="mt-4">
                {twoFactor.secret}
                <CopyToClipboard text={twoFactor.secret} />
              </div>
            </>
          ) : (
            <>
              <p className="text-center">
                Scan the QR code below with an authenticator app, such as Google
                Authenticator, on your phone.
              </p>
              <img className="w-[300px] h-[300px]" src={twoFactor.qrCode} />
              <p
                className="-mt-2 link text-sm"
                onClick={() => setCantScanQrCode(true)}
              >
                Can't scan QR code?
              </p>
            </>
          )}

          <form
            className="mt-2 w-3/4 flex flex-col gap-6"
            onSubmit={handleSubmit(onSubmit)}
          >
            <TextField
              inputRef={tokenRef}
              className="w-full mt-3"
              {...register("token", {
                required: true,
              })}
              placeholder="Verification Code"
              error={!!errors?.token}
              variant="outlined"
              size="small"
              inputProps={{
                autoComplete: "off",
              }}
            />
            <div className="w-full flex flex-row-reverse justify-center gap-2 mb-4">
              <LoadingButton
                type="submit"
                color="info"
                variant="contained"
                loading={isSubmitting}
              >
                Verify
              </LoadingButton>

              <Button
                variant="contained"
                color="white"
                disabled={isSubmitting}
                onClick={() => close()}
              >
                Cancel
              </Button>
            </div>
          </form>
        </>
      )}
    </div>
  );
}
