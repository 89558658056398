import api from ".";
import { Schedule } from "../types";

export const schedulesApi = {
    getSchedules: async (): Promise<Schedule[]> => {
        return (await api.get('/schedules')).data;
    },
    forceRunSchedule: async (id: string): Promise<Schedule> => {
        return (await api.post(`/schedules/force-run/${id}`)).data;
    },
    updateSchedule: async (id: string, update: Partial<Schedule>): Promise<Schedule> => {
        return (await api.patch(`/schedules/${id}`, update)).data;
    }
}