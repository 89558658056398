import { LinearProgress } from "@mui/material";
import { PropsWithChildren } from "react";
import styles from "./LoadingOverlay.module.scss";

interface IProps {
    loading: boolean;
}

export default function LoadingOverlay({
    loading,
    children,
}: PropsWithChildren<IProps>) {
    return (
        <div className="relative w-full h-full">
            {children}
            {loading && (
                <div className={styles.LoadingOverlay}>
                    <LinearProgress color="secondary" />
                </div>
            )}
        </div>
    );
}
