import { Provider, ZeroAddress, getAddress } from "ethers";
import { BlockchainEventType, BlockchainTx } from "../modules/blockchain/types";

export function toShortHash(hash: string, visible = 6): string {
  return hash
    ? `${hash.substring(0, visible)}...${hash.slice(-1 * visible)}`
    : "";
}

export function isValidBlockchainAddress(address: string): boolean {
  try {
    getAddress(address);
    return true;
  } catch {
    return false;
  }
}

export function isBlockchainAddressZero(address: string) {
  return address === ZeroAddress;
}

export function hasBlockChainTxEventOfType(
  tx: BlockchainTx,
  type: BlockchainEventType
): boolean {
  return tx?.events?.some((e) => e.type === type);
}

export function isInClosingState(provider: Provider) {
  return false;
}

export function toBlockchainTxUrl(explorerUrl: string, txHash: string): string {
  return explorerUrl.replace("{txHash}", txHash);
}

export function toBlockchainAddressUrl(
  explorerUrl: string,
  address: string
): string {
  return explorerUrl.replace("{address}", address);
}
