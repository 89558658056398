import { FirebaseApp, initializeApp } from "firebase/app";
import { getMessaging, getToken } from "firebase/messaging";
import { parseBadJson } from "./json-utils";
import { CONSTANTS } from "../common/constants";

let app: FirebaseApp;

const initializeFirebase = async (): Promise<FirebaseApp> => {
    return app
        ? Promise.resolve(app)
        : fetch("firebase-config.js")
            .then((r) => r.text())
            .then((txt) => {
                const start = txt.indexOf("{");
                const end = txt.indexOf("}");
                return parseBadJson(txt.substring(start, end + 1));
            })
            .then((firebaseConfig) => {
                app = initializeApp(firebaseConfig);
                return app;
            })
            .catch((err) => {
                console.error("Error fetching firebase config file", err);
                return null;
            });
};

export function requestForToken(): Promise<string> {
    return initializeFirebase().then((app) => {
        if (app) {
            const messaging = getMessaging(app);

            return getToken(messaging, {
                vapidKey: CONSTANTS.FirebaseVapid,
            })
                .then((token) => {
                    if (token) {
                        console.log("Firebase token:", token);
                        return token;
                    } else {
                        // Show permission request UI
                        console.error(
                            "No registration token available. Request permission to generate one."
                        );
                    }
                })
                .catch((err) => {
                    console.error("An error occurred while retrieving token. ", err);
                    return null;
                });
        } else {
            return null;
        }
    });
}
