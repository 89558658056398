import { LoadingButton } from "@mui/lab";
import { CircularProgress, Paper } from "@mui/material";
import { enqueueSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { investApi } from "../../../http";
import {
  InvestPool,
  InvestPoolInvests as InvestPoolInvestsType,
  InvestPoolStatus,
} from "../../../types";
import InvestPoolInvestsTable from "./InvestPoolInvestsTable";
import { formatNumberWithSeparator } from "../../../utils/number-utils";

interface IProps {
  investPool: InvestPool;
  onUpdate: (update: Partial<InvestPool>) => void;
}

export default function InvestPoolInvests({ investPool, onUpdate }: IProps) {
  const [isGetInvestsPending, setIsGetInvestsPending] = useState(true);
  const [isPublishing, setIsPublishing] = useState(false);
  const [invests, setInvests] = useState<InvestPoolInvestsType>();

  const getInvestPoolInvests = async () => {
    try {
      setIsGetInvestsPending(true);
      setInvests(await investApi.getInvestPoolInvests(investPool.id));
    } finally {
      setIsGetInvestsPending(false);
    }
  };

  const publishToBlockchain = async () => {
    try {
      setIsPublishing(true);
      await investApi.publishInvestPool(investPool.id);
      enqueueSnackbar(`Invest Pool published successfully.`, {
        variant: "info",
      });
      onUpdate({ status: InvestPoolStatus.Published });
      enqueueSnackbar("Invest pool published to blockchain successfully.", {
        variant: "info",
      });
    } finally {
      setIsPublishing(false);
    }
  };

  useEffect(() => {
    investPool?.status != InvestPoolStatus.Draft && getInvestPoolInvests();
  }, [investPool]);

  if (investPool.status == InvestPoolStatus.Draft) {
    return (
      <div className="mt-8 w-full max-w-lg mx-auto flex flex-col gap-6 items-center text-text-1 italic">
        <p>You need to publish invest pool to blockchain</p>
        <LoadingButton
          variant="contained"
          color="secondary"
          size="small"
          loading={isPublishing}
          onClick={publishToBlockchain}
        >
          Publish to Blockchain
        </LoadingButton>
      </div>
    );
  }

  return !isGetInvestsPending ? (
    <div
      className="max-w-3xl mx-auto flex flex-col overflow-auto"
      style={{ maxHeight: "calc(100vh - 200px)" }}
    >
      <Paper className="flex flex-col gap-y-6 p-8 card-border" elevation={0}>
        <section className="invest-pool-info-section">
          <div className="p-3 flex flex-col gap-2">
            <div className="info-section-item">
              <span>Total Investment</span>
              <span className="info-section-item__value">
                {formatNumberWithSeparator(invests?.totalInvest)}{" "}
                {investPool.investToken.symbol}
              </span>
            </div>

            <div className="info-section-item">
              <span> Pool Balance</span>
              <span className="info-section-item__value">
                {formatNumberWithSeparator(invests?.balanceFormatted)}{" "}
                {investPool.investToken.symbol}
              </span>
            </div>

            <InvestPoolInvestsTable className="mt-2" txs={invests?.txs || []} />
          </div>
        </section>
      </Paper>
    </div>
  ) : (
    <div className="absolute left-0 right-0 top-48 flex justify-center">
      <CircularProgress color="primary" size="32px" />
    </div>
  );
}
