import { Business, Person } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { Avatar, Button, Chip, Paper, TextField } from "@mui/material";
import { useSnackbar } from "notistack";
import { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import CompanyStatusIndicator from "../../../components/CompanyStatusIndicator/CompanyStatusIndicator";
import CountryChip from "../../../components/CountryChip/CountryChip";
import ImageSlider from "../../../components/ImageSlider/ImageSlider";
import PlanSubscriptionChip from "../../../components/PlanSubscriptionChip/PlanSubscriptionChip";
import PurposeOfUseChip from "../../../components/PurposeOfUseChip/PurposeOfUseChip";
import VerificationStatusChip from "../../../components/VerificationStatusChip/VerificationStatusChip";
import { companiesApi } from "../../../http";
import { Company, CompanyAccountState } from "../../../types";
import { AppPage } from "../../../types/app-page.type";
import { AppRefreshStatus } from "../../../types/app-refresh-status.type";
import { CompanyChangeStatus } from "../../../types/company-change-status.type";
import {
  canApproveCompany,
  canRejectCompany,
  getExpiredPlanSubscriptions,
  isEmailVerified,
  toPurposeOfUse,
} from "../../../utils/coimex-utils";
import { appActions } from "../../app/app.state";
import "./CompanyInfo.scss";
import { fromNow } from "../../../utils/dt-utils";

interface IProps {
  company: Company;
  onClose: () => void;
}

export default function CompanyInfo({ company, onClose }: IProps) {
  const remarksRef = useRef<HTMLInputElement>();
  const [isSubmitting, setIsSubmitting] =
    useState<CompanyChangeStatus | null>();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  const expiredPlans = getExpiredPlanSubscriptions(
    company.planSubscriptions,
    company.activePlan
  );

  const handleChangeStatus = async (status: CompanyChangeStatus) => {
    setIsSubmitting(status);
    try {
      await companiesApi.changeCompanyStatus(
        company.id,
        status,
        remarksRef.current?.value!
      );
      onClose();
      dispatch(
        appActions.changeRefresh({
          page: AppPage.Companies,
          status: AppRefreshStatus.Invalidated,
        })
      );
      enqueueSnackbar(
        `Company ${company.name} ${
          status === CompanyChangeStatus.Approve ? "approved" : "rejected"
        } successfully.`,
        {
          variant: "info",
        }
      );
    } catch (error) {
      setIsSubmitting(null);
    }
  };

  return (
    <div className="max-w-3xl mx-auto flex flex-col">
      <div
        className="flex-1 overflow-auto"
        style={{ maxHeight: "calc(100vh - 240px)" }}
      >
        <Paper className="flex flex-col gap-y-6 p-12 card-border" elevation={0}>
          {/* company information */}
          <section className="company-info-section">
            <div className="flex justify-between items-center mb-2">
              <h2 className="text-lg font-medium">Company Information</h2>
              <CompanyStatusIndicator status={company.accountState} />
            </div>

            <div className="p-3 flex flex-col gap-4">
              <div className="info-section-item">
                <span>Company Name</span>
                <span className="info-section-item__value">
                  <div className="flex items-center gap-x-2">
                    <Avatar
                      src={company.avatarUrl}
                      sx={{ width: 28, height: 28 }}
                    >
                      <Business fontSize="small" />
                    </Avatar>
                    <span>{company.name}</span>
                  </div>
                </span>
              </div>

              <div className="info-section-item">
                <span>Purpose of Use</span>
                <span className="info-section-item__value">
                  {company.purposeOfUse ? (
                    <PurposeOfUseChip purposeOfUse={company.purposeOfUse} />
                  ) : (
                    <></>
                  )}
                </span>
              </div>

              <div className="info-section-item">
                <span>Owner</span>
                <span className="info-section-item__value">
                  <div className="flex items-center gap-x-2">
                    <Avatar
                      src={company.owner.avatarUrl}
                      sx={{ width: 28, height: 28 }}
                    >
                      <Person fontSize="small" />
                    </Avatar>
                    <span>{company.owner.fullName}</span>
                  </div>
                </span>
              </div>

              <div className="info-section-item">
                <span>Email</span>
                <div className="info-section-item__value">
                  <a
                    className="link mr-2"
                    href={`mailto:${company.owner?.email}`}
                  >
                    {company?.owner.email}
                  </a>
                  <VerificationStatusChip
                    isVerified={isEmailVerified(
                      company?.owner.accountStateOrder
                    )}
                  />
                </div>
              </div>

              <div className="info-section-item">
                <span>Joined At</span>
                <span className="info-section-item__value">
                  {fromNow(company.createdAt)}
                </span>
              </div>

              <div className="info-section-item">
                <span>Summary</span>
                <span className="info-section-item__value">
                  {company.summary}
                </span>
              </div>
            </div>
          </section>

          {/* address information */}
          <section className="company-info-section">
            <div className="flex justify-between items-center mb-2">
              <h2 className="text-lg font-medium">Address Information</h2>
            </div>

            <div className="p-3 flex flex-col gap-4">
              <div className="info-section-item">
                <span>Country</span>
                <span className="info-section-item__value">
                  <CountryChip countryId={company.country?.id} />
                </span>
              </div>
              <div className="info-section-item">
                <span>City</span>
                <span className="info-section-item__value">{company.city}</span>
              </div>
              <div className="info-section-item">
                <span>Address</span>
                <span className="info-section-item__value">
                  {company.address}
                </span>
              </div>
            </div>
          </section>

          {/* business contact information */}
          <section className="company-info-section">
            <div className="flex justify-between items-center mb-2">
              <h2 className="text-lg font-medium">
                Business Contact Information
              </h2>
            </div>

            <div className="p-3 flex flex-col gap-4">
              <div className="info-section-item">
                <span>Phone</span>
                <span className="info-section-item__value">
                  {company.phone}
                </span>
              </div>
              <div className="info-section-item">
                <span>Website</span>
                <a
                  className="link info-section-item__value"
                  href={company.socialAccounts?.website}
                  target="_blank"
                >
                  {company.socialAccounts?.website}
                </a>
              </div>
              <div className="info-section-item">
                <span>LikedIn</span>
                <a
                  className="link info-section-item__value"
                  href={company.socialAccounts?.linkedIn}
                  target="_blank"
                >
                  {company.socialAccounts?.linkedIn}
                </a>
              </div>
            </div>
          </section>

          {/* business fundamental information */}
          <section className="company-info-section">
            <div className="flex justify-between items-center mb-2">
              <h2 className="text-lg font-medium">
                Business Fundamental Information
              </h2>
            </div>

            <div className="p-3 flex flex-col gap-4">
              <div className="info-section-item">
                <span>Company Official Name</span>
                <span className="info-section-item__value">
                  {company.officialName}
                </span>
              </div>
              <div className="info-section-item">
                <span>User Role</span>
                <span className="info-section-item__value">
                  {company.owner.position}
                </span>
              </div>
              <div className="info-section-item">
                <span>Address</span>
                <span className="info-section-item__value">
                  {company.address}
                </span>
              </div>
              <div className="info-section-item">
                <span>Establish</span>
                <span className="info-section-item__value">
                  {company.establishYear}
                </span>
              </div>
            </div>
          </section>

          {/* Plans */}
          <section className="company-info-section">
            <div className="flex justify-between items-center mb-2">
              <h2 className="text-lg font-medium">Plan Subscriptions</h2>
            </div>

            <div className="p-3 flex flex-col gap-4">
              <div className="info-section-item">
                <span>Active Plan</span>
                <div className="info-section-item__value flex flex-wrap gap-2">
                  {company.activePlan && (
                    <PlanSubscriptionChip
                      key={company.activePlan.id}
                      type={company.activePlan.type}
                      durationType={company.activePlan.duration?.type}
                      purposeOfUse={toPurposeOfUse(company.activePlan.roles)}
                    />
                  )}
                </div>
              </div>

              {expiredPlans?.length ? (
                <div className="info-section-item">
                  <span>Expired Plans</span>
                  <div className="info-section-item__value flex flex-wrap gap-2">
                    {expiredPlans.map((plan) => (
                      <PlanSubscriptionChip
                        key={plan.id}
                        type={plan?.type}
                        durationType={plan?.duration?.type}
                        purposeOfUse={toPurposeOfUse(plan?.roles)}
                      />
                    ))}
                  </div>
                </div>
              ) : (
                <></>
              )}
            </div>
          </section>

          {/* import categories */}
          {company.importCategories?.length ? (
            <section className="company-info-section">
              <div className="flex justify-between items-center mb-2">
                <h2 className="text-lg font-medium">Import Categories</h2>
              </div>

              <div className="p-3 flex flex-col gap-4">
                {company.importCategories.map((category) => (
                  <div key={category.id} className="info-section-item">
                    <span>{category.name}</span>
                    <div className="info-section-item__value flex flex-wrap gap-2">
                      {category.subCategories.map((subCategory) => (
                        <Chip
                          key={subCategory.id}
                          label={subCategory.name}
                          variant="outlined"
                          size="small"
                        />
                      ))}
                    </div>
                  </div>
                ))}
              </div>
            </section>
          ) : (
            <></>
          )}

          {/* export categories */}
          {company.exportCategories?.length ? (
            <section className="company-info-section">
              <div className="flex justify-between items-center mb-2">
                <h2 className="text-lg font-medium">Export Categories</h2>
              </div>

              <div className="p-3 flex flex-col gap-4">
                {company.exportCategories.map((category) => (
                  <div key={category.id} className="info-section-item">
                    <span>{category.name}</span>
                    <div className="info-section-item__value flex flex-wrap gap-2">
                      {category.subCategories.map((subCategory) => (
                        <Chip
                          key={subCategory.id}
                          label={subCategory.name}
                          variant="outlined"
                          size="small"
                        />
                      ))}
                    </div>
                  </div>
                ))}
              </div>
            </section>
          ) : (
            <></>
          )}

          {/* import countries */}
          {company.importRegions?.length ? (
            <section className="company-info-section">
              <div className="flex justify-between items-center mb-2">
                <h2 className="text-lg font-medium">Import Countries</h2>
              </div>

              <div className="p-3 flex flex-col gap-4">
                {company.importRegions.map((region) => (
                  <div key={region.name} className="info-section-item">
                    <span>{region.name}</span>
                    <div className="info-section-item__value flex flex-wrap gap-2">
                      {region.countries.map((country) => (
                        <CountryChip key={country.id} countryId={country.id} />
                      ))}
                    </div>
                  </div>
                ))}
              </div>
            </section>
          ) : (
            <></>
          )}

          {/* export countries */}
          {company.exportRegions?.length ? (
            <section className="company-info-section">
              <div className="flex justify-between items-center mb-2">
                <h2 className="text-lg font-medium">Export Countries</h2>
              </div>

              <div className="p-3 flex flex-col gap-4">
                {company.exportRegions.map((region) => (
                  <div key={region.name} className="info-section-item">
                    <span>{region.name}</span>
                    <div className="info-section-item__value flex flex-wrap gap-2">
                      {region.countries.map((country) => (
                        <CountryChip key={country.id} countryId={country.id} />
                      ))}
                    </div>
                  </div>
                ))}
              </div>
            </section>
          ) : (
            <></>
          )}

          {/* documents */}
          <section className="company-info-section">
            <div className="flex justify-between items-center mb-2">
              <h2 className="text-lg font-medium">Documents</h2>
            </div>
            <div className="p-2">
              <ImageSlider urls={company.documents} />
            </div>
          </section>

          {/* certificates */}
          <section className="company-info-section">
            <div className="flex justify-between items-center mb-2">
              <h2 className="text-lg font-medium">Certificates</h2>
            </div>
            <div className="p-2">
              <ImageSlider urls={company.certificates} />
            </div>
          </section>

          {/* remarks */}
          <section className="company-info-section">
            <TextField
              className="w-full"
              inputRef={remarksRef}
              defaultValue={company.remarks}
              label="Remarks"
              multiline
              rows={2}
            />
          </section>
        </Paper>
      </div>

      <div className="px-4 mt-4 flex justify-between">
        {company.accountState === CompanyAccountState.UnderReview ? (
          <Button variant="text" size="small" onClick={onClose}>
            Review Later
          </Button>
        ) : (
          <div></div>
        )}
        <div className="flex gap-3">
          <LoadingButton
            variant="outlined"
            color="error"
            size="small"
            disabled={
              !canRejectCompany(company.accountState) ||
              isSubmitting === CompanyChangeStatus.Approve
            }
            loading={isSubmitting === CompanyChangeStatus.Reject}
            onClick={() => handleChangeStatus(CompanyChangeStatus.Reject)}
          >
            {company.accountState === CompanyAccountState.Active
              ? "Disapprove"
              : "Reject"}
          </LoadingButton>
          <LoadingButton
            variant="contained"
            color="secondary"
            size="small"
            disabled={
              !canApproveCompany(company.accountState) ||
              isSubmitting === CompanyChangeStatus.Reject
            }
            loading={isSubmitting === CompanyChangeStatus.Approve}
            onClick={() => handleChangeStatus(CompanyChangeStatus.Approve)}
          >
            Approve
          </LoadingButton>
        </div>
      </div>
    </div>
  );
}
