import api from ".";
import { OpportunityStatusFilter } from "../modules/offers/types/opportunity-status-filter.type";
import { Opportunity, OpportunityChangeStatus, OpportunityType, Paginated } from "../types";
import { PaginationQuery } from "../types/pagination-query.type";

export const opportunitiesApi = {
    getOpportunity: async (id: string): Promise<Opportunity> => {
        return (await api.get(`/opportunities/${id}`)).data;
    },
    getOpportunities: async (params?: PaginationQuery): Promise<Paginated<Opportunity>> => {
        return (await api.get('/opportunities', { params }));
    },
    getOpportunitiesStatusCounts: async (type: OpportunityType): Promise<Record<OpportunityStatusFilter, number>> => {
        return (await api.get('/opportunities/statuses', {
            params: { type }
        })).data;
    },
    changeOpportunityStatus: async (id: string, status: OpportunityChangeStatus, remarks: string): Promise<void> => {
        return api.put(`/opportunities/${id}/status`, {
            status,
            remarks
        });
    },
    deleteOpportunity: (id: string): Promise<void> => {
        return api.delete(`/opportunities/${id}`);
    }
}