import { yupResolver } from "@hookform/resolvers/yup";
import { LoadingButton } from "@mui/lab";
import {
  Autocomplete,
  Checkbox,
  InputAdornment,
  LinearProgress,
  TextField,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useEffectOnce } from "usehooks-ts";
import * as Yup from "yup";
import InvestPoolStateChip from "../../../components/InvestPoolStateChip/InvestPoolStateChip";
import { investApi } from "../../../http";
import { blockchainApi } from "../../../http/blockchain.api";
import { CloseModal } from "../../../providers/ModalProvider";
import {
  AppPage,
  AppRefreshStatus,
  InvestPoolMeta,
  InvestPoolState,
  InvestPoolStatus,
  InvestPoolType,
  SmartDealPromptPay,
  SmartDealPromptPayChangeStatus,
} from "../../../types";
import { formatNumberWithSeparator } from "../../../utils/number-utils";
import { appActions } from "../../app/app.state";
import { SmartDeal } from "../types";

interface IProps {
  smartDeal: SmartDeal;
  close: CloseModal<SmartDealPromptPay>;
}

interface FormProps {
  pool: InvestPoolMeta;
  allocatedAmount: number;
  remarks: string;
}

const validationSchema = Yup.object().shape({
  pool: Yup.object().required(),
  allocatedAmount: Yup.number().required().positive(),
});

export default function SmartDealPromptPayRequestReview({
  smartDeal,
  close,
}: IProps) {
  const [investPools, setInvestPools] = useState<InvestPoolMeta[]>([]);
  const [isGetInvestPoolsPending, setIsGetInvestPoolsPending] = useState(true);
  const [showSettledPools, setShowSettledPools] = useState(false);
  const [isSubmitting, setIsSubmitting] =
    useState<SmartDealPromptPayChangeStatus | null>();
  const formOptions = {
    resolver: yupResolver(validationSchema),
    mode: "onChange",
  };
  const {
    register,
    watch,
    setValue,
    getValues,
    formState: { isValid, errors },
  } = useForm<FormProps>(formOptions as any);
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const selectedPool: InvestPoolMeta = watch("pool") || null;
  const totalAllocatedPercent = selectedPool
    ? (selectedPool.allocatedAmount * 100) / (+selectedPool.totalInvest || 1)
    : 0;

  const getInvestPools = async () => {
    try {
      setIsGetInvestPoolsPending(true);
      const result = await investApi.getInvestPools({
        filters: JSON.stringify({ type: InvestPoolType.PromptPay }),
      });
      setInvestPools(result.data);
      setIsGetInvestPoolsPending(false);
    } catch {
      close();
    }
  };

  const handleChangeStatus = async (status: SmartDealPromptPayChangeStatus) => {
    try {
      setIsSubmitting(status);
      const { pool, allocatedAmount, remarks } = getValues();
      const promptPay = await (status == SmartDealPromptPayChangeStatus.Approve
        ? blockchainApi.approveSmartDealPromptPay(smartDeal.id, {
            investPoolId: pool.poolId,
            allocatedAmount,
            remarks,
          })
        : blockchainApi.rejectSmartDealPromptPay(smartDeal.id, { remarks }));

      close(promptPay);
      dispatch(
        appActions.changeRefresh({
          page: AppPage.SmartDeals,
          status: AppRefreshStatus.Invalidated,
        })
      );
      enqueueSnackbar(
        `Prompt Pay Request ${
          status === SmartDealPromptPayChangeStatus.Approve
            ? "approved"
            : "rejected"
        } successfully.`,
        {
          variant: "info",
        }
      );
    } catch (error) {
      setIsSubmitting(null);
    }
  };

  const patchFormValue = (key, value) => {
    setValue(key, value, {
      shouldDirty: true,
      shouldTouch: true,
      shouldValidate: true,
    });
  };

  useEffectOnce(() => {
    getInvestPools();
  });

  return (
    <div className="max-w-full p-4" style={{ width: "420px" }}>
      <header className="modal-header flex justify-between items-center pr-4">
        Prompt Pay Request
      </header>

      <form className="flex flex-col gap-6">
        <p className="mb-4">
          {smartDeal.exporterCompany.name} has requested Prompt Pay for Smart
          Deal {smartDeal.refId}
        </p>

        <div className="relative mb-4">
          <label className="absolute -top-8 right-0 flex items-center text-smm">
            <Checkbox
              className="-mr-1.5 scale-90"
              color="primary"
              size="small"
              checked={showSettledPools}
              onChange={(_, checked) => setShowSettledPools(checked)}
            />
            Show Settled Pools
          </label>

          <Autocomplete
            value={selectedPool}
            onChange={(_, value) => patchFormValue("pool", value)}
            disablePortal
            options={investPools.filter((p) =>
              p.pool.status != InvestPoolStatus.Draft && showSettledPools
                ? true
                : p.pool.state != InvestPoolState.Settled
            )}
            getOptionLabel={(option) => option.pool.name}
            renderInput={(params) => (
              <div className="relative">
                <TextField
                  {...params}
                  label="Assign Prompt Pay Pool"
                  error={!!errors?.pool}
                  variant="filled"
                />
                {isGetInvestPoolsPending && (
                  <LinearProgress className="absolute top-0 left-0 right-0 h-0.5" />
                )}
              </div>
            )}
            renderOption={(props, option) => {
              const { key, ...optionProps } = props;
              return (
                <li
                  key={key}
                  {...optionProps}
                  className="p-2 flex justify-between"
                >
                  {option.pool.name}
                  <InvestPoolStateChip pool={option.pool} />
                </li>
              );
            }}
          />

          {selectedPool && (
            <div className="absolute left-0 right-0 -bottom-7 flex justify-between text-smm">
              <div className="absolute -top-2 inset-x-0 h-1 bg-green-400">
                <div
                  className="absolute left-0 h-full bg-red-400"
                  style={{
                    right: `${Math.max(0, 100 - totalAllocatedPercent)}`,
                  }}
                />
              </div>

              <span>
                Total Allocated:{" "}
                {formatNumberWithSeparator(selectedPool.allocatedAmount)}{" "}
                {selectedPool.pool.investToken.symbol}
              </span>

              <span>
                Total Invested:{" "}
                {formatNumberWithSeparator(selectedPool.totalInvest)}{" "}
                {selectedPool.pool.investToken.symbol}
              </span>
            </div>
          )}
        </div>

        <TextField
          className="w-full"
          {...register("allocatedAmount")}
          label="Amount to Allocate"
          inputMode="numeric"
          variant="filled"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {selectedPool?.pool.investToken.symbol}
              </InputAdornment>
            ),
          }}
          error={!!errors?.allocatedAmount}
        />

        <TextField
          {...register("remarks")}
          label="Remarks"
          multiline
          rows={2}
        />
      </form>

      <footer className="modal-footer flex justify-end gap-3">
        <LoadingButton
          variant="outlined"
          color="error"
          size="small"
          loading={isSubmitting === SmartDealPromptPayChangeStatus.Reject}
          disabled={isSubmitting === SmartDealPromptPayChangeStatus.Approve}
          onClick={() =>
            handleChangeStatus(SmartDealPromptPayChangeStatus.Reject)
          }
        >
          Reject
        </LoadingButton>
        <LoadingButton
          variant="contained"
          color="secondary"
          size="small"
          loading={isSubmitting === SmartDealPromptPayChangeStatus.Approve}
          disabled={
            !isValid || isSubmitting === SmartDealPromptPayChangeStatus.Reject
          }
          onClick={() =>
            handleChangeStatus(SmartDealPromptPayChangeStatus.Approve)
          }
        >
          Approve
        </LoadingButton>
      </footer>
    </div>
  );
}
