import { PictureAsPdfOutlined } from "@mui/icons-material";
import { MouseEventHandler } from "react";
import useImagePreview from "../../hooks/use-image-preview";
import { isImage } from "../../utils/file-util";
import PdfSvg from "../Svg/PdfSvg";

interface IProps {
  url: string;
}

export default function FileThumbnail({ url }: IProps) {
  const openImagePreviewer = useImagePreview();

  const handleClick: MouseEventHandler = (e) => {
    e.stopPropagation();
    openImagePreviewer([url], 0);
  };

  return (
    <div className="w-8 h-8 rounded-sm overflow-hidden cursor-pointer">
      {isImage(url) ? (
        <img
          className="w-full h-full object-cover border border-border"
          src={url}
          onClick={handleClick}
        />
      ) : (
        <a href={url} target="blank" onClick={(e) => e.stopPropagation()}>
          <PdfSvg />
        </a>
      )}
    </div>
  );
}
