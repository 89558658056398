import { Notifications as NotificationsIcon } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  CircularProgress,
  ClickAwayListener,
  IconButton,
  Paper,
  Popper,
} from "@mui/material";
import { MouseEvent, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getNotificationsAction,
  updateLastSeenNotificationAction,
} from "../notifications.effects";
import {
  selectHasMoreNotifications,
  selectIsGetNotificationsPending,
  selectNotSeenNotifications,
  selectNotifications,
} from "../notifications.selectors";
import Notifications from "./Notifications";

export default function NotificationsButton() {
  const notSeen = useSelector(selectNotSeenNotifications);
  const notifications = useSelector(selectNotifications);
  const isGetNotificationsPending = useSelector(
    selectIsGetNotificationsPending
  );
  const hasMore = useSelector(selectHasMoreNotifications);
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const causedByActionButton = useRef(false);

  const getNotifications = () => {
    dispatch(getNotificationsAction());
  };

  const openMenu = (event: MouseEvent<HTMLElement>) => {
    if (open) {
      handleClose();
    } else {
      causedByActionButton.current = true;
      setAnchorEl(event.currentTarget);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
    if (notSeen && notifications?.length) {
      dispatch(updateLastSeenNotificationAction(notifications[0]));
    }
  };

  const handleClickOutside = () => {
    if (!causedByActionButton.current) {
      handleClose();
    } else {
      causedByActionButton.current = false;
    }
  };

  useEffect(() => {
    getNotifications();
  }, []);

  return (
    <div className="relative">
      <IconButton size="large" color="inherit" onClick={openMenu}>
        <NotificationsIcon />
        {notSeen ? (
          <div className="absolute top-[11px] right-[13px] w-3.5 h-3.5 rounded-full flex items-center justify-center bg-red-600 text-xxs">
            {notSeen}
          </div>
        ) : null}
      </IconButton>
      <ClickAwayListener onClickAway={handleClickOutside}>
        <Popper
          className="absolute top-full -right-[68px] left-[initial] w-[360px] z-50"
          open={open}
          anchorEl={anchorEl}
          transition
          disablePortal
        >
          <Paper className="min-h-[100px] max-h-[600px] h-full overflow-auto p-4 pb-2 flex items-center justify-center border border-border rounded-md">
            {!notifications.length && isGetNotificationsPending ? (
              <CircularProgress size={24} />
            ) : !notifications.length ? (
              <span className="opacity-50 animate-fade-in">
                No notifications!
              </span>
            ) : (
              <div className="flex-1 self-stretch flex flex-col gap-1.5">
                <Notifications notifications={notifications} />
                {hasMore && (
                  <LoadingButton
                    className="normal-case"
                    variant="outlined"
                    size="small"
                    loading={isGetNotificationsPending}
                    onClick={getNotifications}
                  >
                    Load more
                  </LoadingButton>
                )}
                <div className="min-h-[1px]" />
              </div>
            )}
          </Paper>
        </Popper>
      </ClickAwayListener>
    </div>
  );
}
