import { yupResolver } from "@hookform/resolvers/yup";
import { FormControlLabel, Switch } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import * as Yup from "yup";
import { useSmartDealForm } from "../../providers/SmartDealFormProvider";
import { blockchainApi } from "../../../../http/blockchain.api";

interface FormProps {
  publishToBlockchain: boolean;
}

const validationSchema = Yup.object().shape({});

export default function SmartDealFormPublish({ children }) {
  const { value, setNexting, close } = useSmartDealForm();
  const formOptions = {
    resolver: yupResolver(validationSchema),
    mode: "onSubmit",
    defaultValues: {
      publishToBlockchain: true,
    },
  };
  const { control, handleSubmit } = useForm(formOptions as any);

  const onSubmit = async (formValue: FormProps) => {
    try {
      let smartDeal = value.smartDeal;
      if (formValue.publishToBlockchain) {
        setNexting(true);
        smartDeal = await blockchainApi.publishSmartDealToBlockchain(
          value.smartDeal.id
        );
      }
      close(smartDeal);
    } finally {
      setNexting(false);
    }
  };

  return (
    <form
      className="flex-1 w-full flex flex-col gap-4 mt-2"
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className="flex-1 flex flex-col gap-6 px-1">
        <Controller
          name="publishToBlockchain"
          control={control}
          render={({ field: { onChange, value } }) => (
            <FormControlLabel
              control={<Switch checked={value} onChange={onChange} />}
              label="Publish to Blockchain"
            />
          )}
        />
      </div>
      {children}
    </form>
  );
}
