import { createSlice } from "@reduxjs/toolkit";
import { DEFAULTS } from "../../common/constants";
import { Opportunity, Pagination } from "../../types";
import { addCommonReducers } from "../../utils/store-utils";
import { getOffersAction, getOffersStatusCountsAction } from "./offers.effects";
import { OffersFilters } from "./types/offers-filters.type";
import { OpportunityStatusFilter } from "./types/opportunity-status-filter.type";

export const OFFERS_STATE_NAME = 'offers';

export interface OffersState {
    offers: Opportunity[]
    statusCounts: Record<OpportunityStatusFilter, number>
    filters: OffersFilters
    pagination: Pagination
}

export const offersState = createSlice({
    name: OFFERS_STATE_NAME,
    initialState: {
        offers: [],
        statusCounts: {
            All: 0,
            UnderReview: 0,
            Rejected: 0,
            Active: 0,
            Expired: 0,
            Canceled: 0
        },
        filters: {
            status: OpportunityStatusFilter.All,
            orderBy: {
                field: 'createdAt',
                sort: 'desc'
            }
        },
        pagination: {
            page: 0,
            pageSize: DEFAULTS.PageSize,
            total: 0
        }
    } as OffersState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getOffersAction.fulfilled, (state, action) => {
                const { paginatedOffers, page, pageSize, filters } = action.payload;
                state.offers = paginatedOffers.data;
                state.pagination.total = paginatedOffers.total;
                state.pagination.page = page;
                state.pagination.pageSize = pageSize;
                state.filters = filters;
            })
            .addCase(getOffersStatusCountsAction.fulfilled, (state, action) => {
                state.statusCounts = action.payload;
            });
        addCommonReducers(builder, OFFERS_STATE_NAME);
    }
});

export const offersActions = offersState.actions;
export const offersReducer = offersState.reducer;