import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useEffectOnce } from 'usehooks-ts';
import { AppPage } from '../../../types/app-page.type';
import { AppRefreshStatus } from '../../../types/app-refresh-status.type';
import { selectRefreshPage, selectRefreshStatus } from '../../app/app.selectors';
import { appActions } from '../../app/app.state';
import { getCompaniesCountryDistributionAction, getCurrentPrecedingStatisticsAction, getFindOutWhyAction, getOpportunitiesStatisticsAction, getPlanSubscriptionStatisticsAction, getTopExportersAction, getTopImportersAction } from '../dashboard.effects';
import { selectIsGetCompaniesCountriesDistributionPending, selectIsGetCurrentPrecedingStatisticsPending, selectIsGetFindOutWhyPending, selectIsGetOpportunitiesStatisticsPending, selectIsGetPlanSubscriptionStatisticsPending, selectIsGetTopExportersPending, selectIsGetTopImportersPending } from '../dashboard.selectors';
import CompaniesCountryDistributionList from './CompaniesCountryDistributionList';
import CompaniesCountryDistributionMap from './CompaniesCountryDistributionMap';
import CurrentPreceding from './CurrentPreceding';
import './Dashboard.scss';
import FindOutWhy from './FindOutWhy';
import OffersAndRequests from './OffersAndRequests';
import PlanSubscriptions from './PlanSubscriptions';
import TopExporters from './TopExporters';
import TopImporters from './TopImporters';

export default function Dashboard() {
    const refreshPage = useSelector(selectRefreshPage);
    const refreshStatus = useSelector(selectRefreshStatus);
    const isGetCompaniesCountriesDistributionPending = useSelector(selectIsGetCompaniesCountriesDistributionPending);
    const isGetCurrentPrecedingStatisticsPending = useSelector(selectIsGetCurrentPrecedingStatisticsPending);
    const isGetPlanSubscriptionStatisticsPending = useSelector(selectIsGetPlanSubscriptionStatisticsPending);
    const isGetOpportunitiesStatisticsPending = useSelector(selectIsGetOpportunitiesStatisticsPending);
    const isGetFindOutWhyPending = useSelector(selectIsGetFindOutWhyPending);
    const isGetTopImportersPending = useSelector(selectIsGetTopImportersPending);
    const isGetTopExportersPending = useSelector(selectIsGetTopExportersPending);
    const dispatch = useDispatch();

    const getDashboardData = () => {
        dispatch(getCompaniesCountryDistributionAction());
        dispatch(getFindOutWhyAction());
        dispatch(getCurrentPrecedingStatisticsAction({}));
        dispatch(getPlanSubscriptionStatisticsAction({}));
        dispatch(getOpportunitiesStatisticsAction({}));
        dispatch(getTopImportersAction({}));
        dispatch(getTopExportersAction({}));
    }

    useEffect(() => {
        if (refreshStatus === AppRefreshStatus.Invalidated && refreshPage === AppPage.Dashboard) {
            getDashboardData();
        }
    }, [refreshStatus]);

    useEffect(() => {
        let status = AppRefreshStatus.Idle;
        if (
            isGetCompaniesCountriesDistributionPending &&
            isGetFindOutWhyPending &&
            isGetCurrentPrecedingStatisticsPending &&
            isGetPlanSubscriptionStatisticsPending &&
            isGetOpportunitiesStatisticsPending &&
            isGetTopImportersPending &&
            isGetTopExportersPending
        ) {
            status = AppRefreshStatus.InProgress;
        }
        if (refreshStatus !== status) {
            dispatch(appActions.changeRefresh({ page: AppPage.Dashboard, status: status }));
        }
    }, [
        isGetCompaniesCountriesDistributionPending,
        isGetFindOutWhyPending,
        isGetCurrentPrecedingStatisticsPending,
        isGetPlanSubscriptionStatisticsPending,
        isGetOpportunitiesStatisticsPending,
        isGetTopImportersPending,
        isGetTopExportersPending
    ]);

    useEffectOnce(() => {
        getDashboardData();
    });

    return (
        <div className="Dashboard">
            <div className="Widget">
                <CompaniesCountryDistributionMap />
            </div>
            <div className="Widget">
                <CompaniesCountryDistributionList />
            </div>
            <div className="Widget">
                <FindOutWhy />
            </div>
            <div className="Widget">
                <CurrentPreceding />
            </div>
            <div className="Widget">
                <PlanSubscriptions />
            </div>
            <div className="Widget">
                <OffersAndRequests />
            </div>
            <div className="Widget">
            </div>
            <div className="Widget">
                <TopImporters />
            </div>
            <div className="Widget">
                <TopExporters />
            </div>
        </div>
    )
}