import { yupResolver } from "@hookform/resolvers/yup";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import * as Yup from "yup";
import useFocus from "../../../../hooks/use-focus";
import { toPurposeOfUse } from "../../../../utils/coimex-utils";
import { selectPlans } from "../../../plans/plans.selectors";
import { useCompanyForm } from "./CompanyFormProvider";

const validationSchema = Yup.object().shape({
  companyName: Yup.string().required(""),
  userFullName: Yup.string().required(""),
  email: Yup.string().email("Email is not valid!").required(""),
  password: Yup.string()
    .required("")
    .min(8, "Password must be at least 8 characters."),
  purposeOfUse: Yup.string().required(""),
});

export default function CompanyFormInfo({ children }) {
  const { company, next } = useCompanyForm();
  const [passwordVisible, setPasswordVisible] = useState(false);
  const plans = useSelector(selectPlans);
  const formOptions = {
    resolver: yupResolver(validationSchema),
    mode: "onSubmit",
  };
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm(formOptions as any);
  const companyNameRef = useRef();
  useFocus(companyNameRef);

  const onSubmit = (value) => {
    const plan =
      company.planId &&
      plans.find(
        (p) =>
          p.id == company.planId &&
          toPurposeOfUse(p.roles) === value.purposeOfUse
      );
    if (!plan) {
      value = { ...value, planId: null };
    }
    next(value);
  };

  useEffect(() => {
    reset(company);
  }, [company]);

  return (
    <form
      className="flex-1 w-full flex flex-col gap-4 mt-2"
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className="flex-1 flex flex-col gap-6 px-1">
        <TextField
          className="w-full"
          {...register("companyName")}
          defaultValue={company.companyName}
          label="Company Name"
          error={!!errors?.companyName}
          variant="filled"
          helperText={errors.companyName?.message}
          required
          inputRef={companyNameRef}
        />

        <TextField
          className="w-full"
          {...register("userFullName")}
          defaultValue={company.userFullName}
          label="User full Name"
          error={!!errors?.userFullName}
          variant="filled"
          helperText={errors.userFullName?.message}
          required
        />

        <TextField
          className="w-full"
          {...register("email")}
          defaultValue={company.email}
          label="Email"
          error={!!errors?.email}
          variant="filled"
          helperText={errors.email?.message}
          required
          InputProps={{
            autoComplete: "off",
          }}
        />

        <TextField
          className="w-full"
          {...register("password")}
          defaultValue={company.password}
          label="Password"
          error={!!errors?.password}
          variant="filled"
          helperText={errors.password?.message}
          required
          type={passwordVisible ? "text" : "password"}
          InputProps={{
            endAdornment: (
              <IconButton
                onClick={() => setPasswordVisible((visible) => !visible)}
              >
                {passwordVisible ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            ),
            autoComplete: "new-password",
          }}
        />

        <FormControl fullWidth size="small" variant="filled" required>
          <InputLabel>Purpose of use</InputLabel>
          <Select
            label="Purpose of use"
            {...register("purposeOfUse")}
            defaultValue={company.purposeOfUse}
            error={!!errors?.purposeOfUse}
          >
            <MenuItem value="Importer">Importer</MenuItem>
            <MenuItem value="Exporter">Exporter</MenuItem>
            <MenuItem value="Both">Importer & Exporter</MenuItem>
          </Select>
        </FormControl>
      </div>
      {children}
    </form>
  );
}
