import {
  Add,
  DeleteForeverOutlined,
  MoreVert,
  PlayArrowOutlined,
} from "@mui/icons-material";
import { Button, Divider, IconButton, Tooltip } from "@mui/material";
import { DataGrid, GridColDef, GridFooter } from "@mui/x-data-grid";
import classNames from "classnames";
import moment from "moment";
import { useSnackbar } from "notistack";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import InvestPoolStateChip from "../../../components/InvestPoolStateChip/InvestPoolStateChip";
import MoreMenu from "../../../components/MenuMore/MoreMenu";
import NoRowsOverlay from "../../../components/NoRowsOverlay/NoRowsOverlay";
import useDeleteConfirm from "../../../hooks/use-delete-confirm";
import { investApi } from "../../../http";
import { useModal } from "../../../providers/ModalProvider";
import {
  AppPage,
  AppRefreshStatus,
  InvestPoolMeta,
  InvestPoolStatus,
  InvestPoolType,
  Pagination,
} from "../../../types";
import {
  selectRefreshPage,
  selectRefreshStatus,
} from "../../app/app.selectors";
import { getInvestPoolsAction } from "../invest.effects";
import {
  selectInvestPools,
  selectInvestPoolsPagination,
  selectIsGetInvestPoolsPending,
} from "../invest.selectors";
import PromptPayPoolFormProvider from "../providers/PromptPayPoolFormProvider";
import PromptPayPoolForm from "./PromptPayPoolForm";

export default function PromptPayPools() {
  const investPools = useSelector(selectInvestPools(InvestPoolType.PromptPay));
  const pagination = useSelector(
    selectInvestPoolsPagination(InvestPoolType.PromptPay)
  );
  const isGetInvestPoolsPending = useSelector(selectIsGetInvestPoolsPending);
  const [rows, setRows] = useState([]);
  const refreshPage = useSelector(selectRefreshPage);
  const refreshStatus = useSelector(selectRefreshStatus);
  const [selectedMenuInvestPool, setSelectedMenuInvestPool] =
    useState<InvestPoolMeta | null>(null);
  const [menuAnchorEl, setMenuAnchorEl] = useState<HTMLButtonElement | null>(
    null
  );
  const { enqueueSnackbar } = useSnackbar();
  const openModal = useModal();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const confirmDelete = useDeleteConfirm();

  const getInvestPools = (pg) => {
    dispatch(getInvestPoolsAction({ type: InvestPoolType.PromptPay, ...pg }));
  };

  const handlePagination = (
    pg: Partial<Pick<Pagination, "page" | "pageSize">>
  ) => {
    getInvestPools(pg);
  };

  const handleRowClick = (investPool: InvestPoolMeta) => {
    navigate(investPool.poolId);
  };

  const showMenu = (
    e: React.MouseEvent<HTMLButtonElement>,
    pool: InvestPoolMeta
  ) => {
    e.stopPropagation();
    setSelectedMenuInvestPool(pool);
    setMenuAnchorEl(e.currentTarget);
  };

  const closeMenu = () => {
    setMenuAnchorEl(null);
  };

  const handleAddNewPool = async () => {
    if (
      await openModal(
        (props) => (
          <PromptPayPoolFormProvider {...props}>
            <PromptPayPoolForm />
          </PromptPayPoolFormProvider>
        ),
        {
          closeOnClickOutside: false,
          hideCloseButton: true,
        }
      )
    ) {
      getInvestPools({});
      enqueueSnackbar("Prompt Pay pool added successfully.", {
        variant: "info",
      });
    }
  };

  const handlePublish = async () => {
    closeMenu();
    await investApi.publishInvestPool(selectedMenuInvestPool.poolId);
    enqueueSnackbar("Prompt Pay Pool published to blockchain successfully.", {
      variant: "info",
    });
    getInvestPools({});
  };

  const handleDelete = async () => {
    closeMenu();
    if (
      await confirmDelete({
        title: "Invest Pool",
        onConfirm: () =>
          investApi.deleteInvestPool(selectedMenuInvestPool.poolId),
      })
    ) {
      enqueueSnackbar(`Prompt Pay Pool deleted successfully.`, {
        variant: "info",
      });
      getInvestPools({});
    }
  };

  const cols: GridColDef[] = useMemo(
    () =>
      [
        {
          field: "rowNo",
          headerName: "",
          width: 50,
          sortable: false,
        },
        {
          field: "name",
          headerName: "Name",
          renderCell: ({ row }: { row: InvestPoolMeta }) => (
            <Tooltip title={row.pool.name}>
              <span>{row.pool.name}</span>
            </Tooltip>
          ),
          flex: 1,
          sortable: false,
        },
        {
          field: "tenureInDays",
          headerName: "Duration",
          renderCell: ({ row }: { row: InvestPoolMeta }) => (
            <span>{row.pool.tenureInDays} Days</span>
          ),
          headerAlign: "center",
          align: "center",
          width: 90,
          sortable: false,
        },
        {
          field: "openAt",
          headerName: "Investment Time",
          renderCell: ({ row }: { row: InvestPoolMeta }) => {
            const Component = (
              <div className="flex flex-col">
                <span>
                  {moment(row.pool.investmentOpenAt).format(
                    "YYYY-MM-DD hh:mm A"
                  )}
                </span>
                <span>
                  For{" "}
                  {moment(row.pool.investmentCloseAt).from(
                    row.pool.investmentOpenAt,
                    true
                  )}
                </span>
              </div>
            );
            return <Tooltip title={Component}>{Component}</Tooltip>;
          },
          flex: 1,
          sortable: false,
        },
        {
          field: "stakingAt",
          headerName: "Staking Time",
          renderCell: ({ row }: { row: InvestPoolMeta }) => {
            const Component = (
              <div className="flex flex-col">
                <span>
                  {moment(row.pool.stakingStartAt).format("YYYY-MM-DD hh:mm A")}
                </span>
                <span>
                  {moment(row.pool.stakingEndAt).format("YYYY-MM-DD hh:mm A")}
                </span>
              </div>
            );
            return <Tooltip title={Component}>{Component}</Tooltip>;
          },
          flex: 1,
          sortable: false,
        },
        {
          field: "state",
          headerName: "Pool State",
          renderCell: ({ row }: { row: InvestPoolMeta }) => (
            <InvestPoolStateChip pool={row.pool} />
          ),
          headerAlign: "center",
          align: "center",
          width: 180,
          sortable: false,
        },
        {
          field: "totalInvest",
          headerName: "Total Invest",
          renderCell: ({ row }: { row: InvestPoolMeta }) =>
            `${row.totalInvest} ${row.pool.investToken.symbol}`,
          headerAlign: "right",
          align: "right",
          width: 100,
          sortable: false,
        },
        {
          field: "interestRatePercentage",
          headerName: "Min Return",
          renderCell: ({ row }: { row: InvestPoolMeta }) =>
            `${row.pool.interestRatePercentage}%`,
          headerAlign: "right",
          align: "right",
          width: 120,
          sortable: false,
        },
        {
          field: "",
          renderCell: (params) => (
            <IconButton onClick={(e) => showMenu(e, params.row)}>
              <MoreVert />
            </IconButton>
          ),
          width: 60,
          sortable: false,
        },
      ] as GridColDef[],
    []
  );

  useEffect(() => {
    setRows(
      (investPools?.map((investPool, index) => ({
        ...investPool,
        rowNo: pagination.page * pagination.pageSize + index + 1,
      })) as any) || []
    );
  }, [investPools]);

  useEffect(() => {
    if (
      refreshStatus === AppRefreshStatus.Invalidated &&
      refreshPage === AppPage[`${InvestPoolType.PromptPay}Pools`]
    ) {
      getInvestPools({});
    }
  }, [refreshStatus]);

  return (
    <>
      <Button
        className="mt-3 float-right rounded-3xl"
        variant="contained"
        color="secondary"
        size="small"
        onClick={handleAddNewPool}
      >
        <Add /> Add Prompt Pay Pool
      </Button>

      <div className="w-full mt-4">
        <DataGrid
          columns={cols}
          rows={rows}
          getRowId={(row) => row.poolId}
          disableColumnMenu
          disableSelectionOnClick
          style={{ minHeight: "300px", height: "calc(100vh - 200px)" }}
          loading={isGetInvestPoolsPending}
          pagination
          paginationMode="server"
          autoPageSize
          rowCount={pagination.total}
          page={pagination.page}
          onPageChange={(page) => handlePagination({ page })}
          onPageSizeChange={(pageSize) => handlePagination({ pageSize })}
          components={{
            NoRowsOverlay: () => (
              <NoRowsOverlay emptyMessage="No prompt pay pools" />
            ),
            Footer: (props) => (
              <div className="relative">
                <GridFooter {...props} />
                <div className="absolute left-0 top-0 bottom-0 px-6 flex gap-6">
                  <div className="flex items-center gap-2">
                    <div className="w-4 h-4 rounded bg-yellow-300" />
                    <small>Draft</small>
                  </div>
                </div>
              </div>
            ),
          }}
          getRowClassName={({ row }: { row: InvestPoolMeta }) =>
            classNames(
              "cursor-pointer",
              row.pool.status == InvestPoolStatus.Draft && "bg-yellow-200"
            )
          }
          onRowClick={({ row }) => handleRowClick(row)}
        />
        <MoreMenu anchorEl={menuAnchorEl} onClose={closeMenu}>
          <div className="flex flex-col px-1">
            <Button
              className="justify-start"
              variant="text"
              color="info"
              startIcon={<PlayArrowOutlined />}
              disabled={
                selectedMenuInvestPool?.pool.status != InvestPoolStatus.Draft
              }
              onClick={handlePublish}
            >
              Publish
            </Button>
            <Divider />
            <Button
              color="error"
              variant="text"
              startIcon={<DeleteForeverOutlined />}
              disabled={
                selectedMenuInvestPool?.pool.status != InvestPoolStatus.Draft
              }
              onClick={handleDelete}
            >
              Delete
            </Button>
          </div>
        </MoreMenu>
      </div>
    </>
  );
}
