/// <reference path="./array-utils.d.ts" />

Array.prototype.distinct = function (expression) {
    return this.reduce((acc, val) => {
        const element = acc.find((x: any) => expression(x) === expression(val));
        if (!element) {
            acc.push(val);
        }
        return acc;
    }, []);
};

Array.prototype.sumOf = function (expression, base = 0) {
    return this.reduce((acc, val) => {
        acc += parseFloat(expression(val));
        return acc;
    }, base);
};

Array.prototype.sortBy = function <K>(prop: K, dir: 'asc' | 'des' = 'des') {
    return this.slice().sort((a, b) => (dir === 'des' ? -1 : 1) * a[prop]?.toString()?.toLowerCase().localeCompare(b[prop]?.toString()?.toLowerCase()))
}

Array.prototype.search = function (str: string, ...props: string[]) {
    return this.filter(item => str ? isSearchHit(item, str.replace(/\s/g, ""), ...props) : true)
}

Array.prototype.max = function (prop?: string, defaultValue: any = 0): any {
    return this.reduce((acc, item) => {
        const itemVal = prop ? item[prop] : item;
        return itemVal > acc ? itemVal : acc;
    }, defaultValue)
}

Array.prototype.min = function (prop?: string, defaultValue: any = 0): any {
    return this.reduce((acc, item) => {
        const itemVal = prop ? item[prop] : item;
        return itemVal < acc ? itemVal : acc;
    }, defaultValue)
}

function isSearchHit(obj: any, str: string, ...props: string[]): boolean {
    return typeof obj === 'object' ? Object.entries(obj).some(([key, value]) => {
        if (!value) return false;
        if (props?.length && !props.includes(key)) return false;
        if (Array.isArray(value)) {
            return value.some(val => isSearchHit(val, str));
        }
        if (typeof value === 'object') {
            return isSearchHit(value, str)
        }
        return includesStr(value, str);
    }) :
        includesStr(obj, str);
}

function includesStr(obj, str): boolean {
    return obj?.toString()?.toLowerCase()?.replace(/\s/g, "")?.includes(str?.toLowerCase())
}