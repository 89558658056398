import {
  createContext,
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useCallback,
  useContext,
  useState,
} from "react";
import { CloseModal } from "../../../../providers/ModalProvider";
import { Company } from "../../../../types";

interface CompanyFormContextProps {
  company: any;
  setCompany: Dispatch<SetStateAction<Company>>;
  step: number;
  next: (update: any) => void;
  back: () => void;
  close: CloseModal;
  nexting: boolean;
  setNexting: Dispatch<SetStateAction<boolean>>;
}

const CompanyFormContext = createContext<CompanyFormContextProps>(null);

interface IProps {
  close: CloseModal;
}

export default function CompanyFormProvider({
  close,
  children,
}: PropsWithChildren<IProps>) {
  const [company, setCompany] = useState({} as any);
  const [step, setStep] = useState(0);
  const [nexting, setNexting] = useState(false);

  const next = useCallback((update) => {
    setCompany((company) => ({
      ...company,
      ...update,
    }));
    setStep((step) => step + 1);
  }, []);

  const back = useCallback(() => {
    setStep((step) => Math.max(0, step - 1));
  }, []);

  return (
    <CompanyFormContext.Provider
      value={{
        company,
        setCompany,
        step,
        next,
        back,
        close,
        nexting,
        setNexting,
      }}
    >
      {children}
    </CompanyFormContext.Provider>
  );
}

export const useCompanyForm = () => {
  return useContext(CompanyFormContext);
};
