import classNames from "classnames";
import { EChartsOption } from "echarts";
import ReactEcharts from "echarts-for-react";
import { useMemo } from "react";
import { TimeWindow } from "../../../types/time-window.type";
import { getTimeWindowText } from "../../../utils/coimex-utils";

interface IProps {
  current: number[];
  preceding: number[];
  window: TimeWindow;
}

const style = {
  height: "100%",
  width: "100%",
  padding: "24px 0 46px",
};

export default function CurrentPrecedingChart({
  current,
  preceding,
  window,
  ...rest
}: IProps &
  React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  >) {
  const option = useMemo(
    () =>
      ({
        xAxis: {
          data: [],
          axisLine: {
            show: true,
            lineStyle: {
              color: "rgba(100, 100, 100, 0.15)",
            },
          },
        },
        yAxis: {
          type: "value",
          axisLabel: {
            show: false,
          },
          axisLine: {
            show: false,
          },
          splitLine: {
            lineStyle: {
              color: "rgba(100, 100, 100, 0.15)",
            },
          },
        },
        series: [
          {
            data: current,
            type: "line",
            animationDelay: (idx) => idx * 10,
            itemStyle: {
              color: "#17a2b8",
            },
            showSymbol: false,
          },
          {
            data: preceding,
            type: "line",
            animationDelay: (idx) => idx * 10,
            itemStyle: {
              color: "#17a2b8",
            },
            lineStyle: {
              type: "dashed",
            },
            showSymbol: false,
          },
        ],
        animationEasing: "elasticOut",
        animationDelayUpdate: (idx) => idx * 5,
        grid: {
          top: 0,
          bottom: 0,
        },
      } as EChartsOption),
    [current, preceding]
  );

  return (
    <div {...rest} className={classNames(rest?.className, "relative")}>
      <ReactEcharts option={option} style={style} />
      <div className="absolute left-10 bottom-3 text-xxs flex items-center gap-2">
        <span className="border-t border-solid border-secondary w-4"></span>
        <span>{getTimeWindowText(window)}</span>
        <span className="text-secondary font-bold">-- --</span>
        <span>Preceding period</span>
      </div>
    </div>
  );
}
