import api from ".";
import { NotificationsStatus } from "../modules/notifications/types/notifications-status.type";
import { Notification, Paginated, PaginationQuery } from "../types";

export const meApi = {
    addNotificationToken: (token: string): Promise<any> => {
        return api.post("/me/notifications/tokens", { token });
    },
    getNotifications: (
        params?: PaginationQuery
    ): Promise<Paginated<Notification>> => {
        return api.get("/me/notifications", { params });
    },
    getNotificationsStatus: async (): Promise<NotificationsStatus> => {
        return (await api.get("/me/notifications/status")).data;
    },
    updateLastSeenNotificationId: (id: Notification["id"]): Promise<void> => {
        return api.put("/me/notifications/last-seen", null, {
            params: { id },
        });
    },
};
