import { CloudOffOutlined } from "@mui/icons-material";
import { useWs } from "../../providers/WsProvider";

export default function ConnectionStatus() {
  const { isConnected } = useWs();

  return (
    !isConnected && (
      <div className="flex items-center gap-1">
        <CloudOffOutlined fontSize="small" />
        Trying to connect to server...
      </div>
    )
  );
}
