export function isImage(url: string) {
  return /\.(jpg|jpeg|png|webp|avif|gif|svg)$/.test(url);
}

export function humanFileSize(size: number, dp = 1): string {
  var i = size == 0 ? 0 : Math.floor(Math.log(size) / Math.log(1024));
  return (
    +(size / Math.pow(1024, i)).toFixed(dp) * 1 +
    " " +
    ["B", "KB", "MB", "GB", "TB"][i]
  );
}
