import { yupResolver } from "@hookform/resolvers/yup";
import { Add, ArrowBackIos } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Button,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import useFocus from "../../../hooks/use-focus";
import { blockchainApi } from "../../../http/blockchain.api";
import { commodityTokenApi } from "../../../http/commodity-tokens.api";
import { CommodityToken, Erc20TokenInfo } from "../../../types";
import { isValidBlockchainAddress } from "../../../utils/blockchain-utils";
import { formatNumberWithSeparator } from "../../../utils/number-utils";
import { getConfigsAction } from "../../settings/settings.effects";
import { selectCommodityExchangeTokens } from "../../settings/settings.selectors";

interface IProps {
  token: CommodityToken;
  close?: (modified?: boolean) => void;
}

export default function SetCommodityTokenPriceForm({ token, close }: IProps) {
  const tokens = useSelector(selectCommodityExchangeTokens);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isNewToken, setIsNewToken] = useState(false);
  const [newToken, setNewToken] = useState<Erc20TokenInfo["symbol"]>();
  const [isGetNewTokenPending, setIsGetNewTokenPending] = useState(false);
  const validationSchema = Yup.object().shape({
    address: Yup.string()
      .required()
      .test("valid", function (value) {
        return isValidBlockchainAddress(value);
      }),
    buyPrice: Yup.number()
      .required()
      .test("min", function (value) {
        const { symbol } = this.parent;
        return value >= (token.prices[symbol]?.minAmount || 0);
      })
      .test("max", function (value) {
        const { symbol } = this.parent;
        return token.prices[symbol]?.maxAmount
          ? value <= token.prices[symbol].maxAmount
          : true;
      }),
    sellPrice: Yup.number()
      .required()
      .test("min", function (value) {
        const { symbol } = this.parent;
        return value >= (token.prices[symbol]?.minAmount || 0);
      })
      .test("max", function (value) {
        const { symbol } = this.parent;
        return token.prices[symbol]?.maxAmount
          ? value <= token.prices[symbol].maxAmount
          : true;
      }),
  });
  const form = useForm({
    resolver: yupResolver(validationSchema),
    mode: "onSubmit",
    defaultValues: {
      address: Object.values(token.prices)?.[0]?.address,
      buyPrice: null,
      sellPrice: null,
    },
  });
  const inputRef = useRef<any>();
  useFocus(inputRef);
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const address = form.watch("address");
  const buyPrice = +(form.watch("buyPrice") || undefined);
  const sellPrice = +(form.watch("sellPrice") || undefined);
  const selectedToken = Object.values(token.prices).find(
    (t) => t.address == address
  );

  const onSubmit = async ({ address, buyPrice, sellPrice }) => {
    try {
      setIsSubmitting(true);
      await commodityTokenApi.setTokenPrice(
        token.address,
        address,
        buyPrice,
        sellPrice
      );
      isNewToken && dispatch(getConfigsAction());
      close(true);
      enqueueSnackbar("Token price was set successfully", {
        variant: "info",
      });
    } catch {
      setIsSubmitting(false);
    }
  };

  const renderChange = (oldPrice: number, newPrice: number) => {
    const change = ((newPrice - oldPrice) * 100) / oldPrice;
    return (
      <div className="mt-1 ml-3.5 flex flex-col text-[13px] opacity-70">
        <div className="grid grid-cols-[70px_1fr]">
          Old price:
          <span>{formatNumberWithSeparator(oldPrice)}</span>
        </div>

        <div className="grid grid-cols-[70px_1fr]">
          <span>Change:</span>
          <span>
            {change > 0 ? "+" : change < 0 ? "-" : ""}
            {isNaN(newPrice) ? "" : (newPrice - oldPrice).toFixed(3)}
          </span>
        </div>
      </div>
    );
  };

  const getNewToken = async () => {
    try {
      setIsGetNewTokenPending(true);
      const address = form.getValues("address");
      const cacheToken = tokens.find((t) => t.address == address);
      if (cacheToken) {
        setNewToken(cacheToken.symbol);
      } else {
        setNewToken((await blockchainApi.getErc20TokenInfo(address)).symbol);
      }
    } finally {
      setIsGetNewTokenPending(false);
    }
  };

  useEffect(() => {
    setNewToken(null);
    setIsGetNewTokenPending(false);
    if (isNewToken && isValidBlockchainAddress(form.getValues("address"))) {
      getNewToken();
    }
  }, [isNewToken, form.watch("address")]);

  useEffect(() => {
    if (selectedToken) {
      form.setValue("buyPrice", selectedToken.buyPrice);
      form.setValue("sellPrice", selectedToken.sellPrice);
    }
  }, [selectedToken]);

  return (
    <form className="p-4 w-[490px]" onSubmit={form.handleSubmit(onSubmit)}>
      <header className="modal-header text-lg">
        Set {token.symbol}-{token.name} Price
      </header>

      <div className="flex flex-col gap-8">
        {isNewToken ? (
          <div className="relative mb-2">
            <TextField
              className="w-full"
              {...form.register("address")}
              inputMode="decimal"
              label="Token Address"
              variant="filled"
              size="small"
              helperText={form.formState.errors.address?.message}
              error={!!form.formState.errors?.address}
              InputProps={{
                endAdornment: isGetNewTokenPending ? (
                  <CircularProgress size={18} />
                ) : (
                  <span className="text-sm">{newToken}</span>
                ),
              }}
            />
            <Button
              className="absolute right-0 -bottom-[30px] text-xs"
              size="small"
              color="info"
              onClick={() => setIsNewToken(false)}
            >
              <ArrowBackIos className="text-sm" />
              Choose from list
            </Button>
          </div>
        ) : (
          <FormControl
            className="mb-2"
            fullWidth
            size="small"
            variant="filled"
            required
          >
            <InputLabel>Token</InputLabel>
            <Select
              label="Token"
              {...form.register("address")}
              defaultValue={
                form.getValues("address") ||
                Object.values(token.prices)?.[0]?.address
              }
              error={!!form.formState.errors?.address}
            >
              {Object.values(token.prices).map((t) => (
                <MenuItem key={t.symbol} value={t.address}>
                  {t.symbol}
                </MenuItem>
              ))}
            </Select>
            <Button
              className="absolute right-0 -bottom-[30px] text-xs"
              size="small"
              color="info"
              onClick={() => setIsNewToken(true)}
            >
              <Add fontSize="small" />
              New Token
            </Button>
          </FormControl>
        )}

        <div className="w-full flex gap-4">
          <div className="flex-1">
            <TextField
              inputRef={inputRef}
              className="w-full"
              {...form.register("buyPrice")}
              inputMode="decimal"
              label="Buy Price"
              variant="filled"
              size="small"
              helperText={
                selectedToken ? (
                  <div className="flex justify-between">
                    <span>Min: {selectedToken?.minAmount}</span>{" "}
                    <span>
                      {selectedToken?.maxAmount
                        ? `Max: ${selectedToken.maxAmount}`
                        : null}
                    </span>
                  </div>
                ) : null
              }
              error={!!form.formState.errors?.buyPrice}
            />

            {selectedToken
              ? renderChange(selectedToken.buyPrice, buyPrice)
              : null}
          </div>

          <div className="flex-1">
            <TextField
              className="w-full"
              {...form.register("sellPrice")}
              inputMode="decimal"
              label="Sell Price"
              variant="filled"
              size="small"
              helperText={
                selectedToken ? (
                  <div className="flex justify-between">
                    <span>Min: {selectedToken?.minAmount}</span>{" "}
                    <span>
                      {selectedToken?.maxAmount
                        ? `Max: ${selectedToken.maxAmount}`
                        : null}
                    </span>
                  </div>
                ) : null
              }
              error={!!form.formState.errors?.sellPrice}
            />

            {selectedToken
              ? renderChange(selectedToken.sellPrice, sellPrice)
              : null}
          </div>
        </div>
      </div>

      <footer className="modal-footer flex flex-row-reverse gap-4">
        <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
          Save
        </LoadingButton>
        <Button type="button" variant="text" onClick={() => close(false)}>
          Cancel
        </Button>
      </footer>
    </form>
  );
}
