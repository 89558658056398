import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import { rootReducer } from './root.reducer';
import listenerMiddleware from './middlewares/listener.middleware';
import discardStaleActionsMiddleware from './middlewares/discard-stale-actions.middleware';

export * from './root.reducer';

const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware()
            .prepend(listenerMiddleware.middleware)
            .concat(discardStaleActionsMiddleware),
});

export default store;