import { yupResolver } from "@hookform/resolvers/yup";
import { Autocomplete, Box, TextField } from "@mui/material";
import { useEffect, useRef } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import * as Yup from "yup";
import Loading from "../../../../components/Loading/Loading";
import useFocus from "../../../../hooks/use-focus";
import { toAbsoluteUrl } from "../../../../utils/coimex-utils";
import {
  selectCountries,
  selectIsGetConfigsPending,
} from "../../../settings/settings.selectors";
import { useCompanyForm } from "./CompanyFormProvider";

const validationSchema = Yup.object().shape({});

export default function CompanyFormAddress({ children }) {
  const { company, next } = useCompanyForm();
  const countries = useSelector(selectCountries);
  const isLoading = useSelector(selectIsGetConfigsPending);
  const formOptions = {
    resolver: yupResolver(validationSchema),
    mode: "onSubmit",
  };
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm(formOptions as any);
  const companyNameRef = useRef();
  useFocus(companyNameRef);

  const onSubmit = (value) => {
    const { countryId = null } = value;
    if (countryId) {
      const country = countries.find((c) => c.name === countryId);
      value = { ...value, countryId: country.id };
    }
    next(value);
  };

  useEffect(() => {
    if (company && countries.length) {
      reset({
        ...company,
        countryId: countries?.find((c) => c.id == company?.countryId)?.name,
      });
    }
  }, [company, countries]);

  return (
    <div className="flex-1 w-full">
      {isLoading ? (
        <Loading size={40} />
      ) : (
        <form
          className="h-full flex flex-col gap-4 mt-2"
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className="flex-1 flex flex-col gap-6 px-1">
            <Autocomplete
              options={countries}
              defaultValue={countries?.find((c) => c.id == company?.countryId)}
              autoHighlight
              getOptionLabel={(country) => country.name}
              renderOption={(props, country) => (
                <Box
                  component="li"
                  sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                  {...props}
                >
                  <img
                    loading="lazy"
                    width="20"
                    src={country.flagUrl}
                    alt={country.name}
                  />
                  {country.name} ({country.cca3}) +{country.dialingCode}
                </Box>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  {...register("countryId")}
                  label="Country"
                  variant="filled"
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: "new-password",
                  }}
                />
              )}
            />

            <TextField
              className="w-full"
              {...register("city")}
              defaultValue={company.city}
              label="City"
              error={!!errors?.city}
              variant="filled"
              helperText={errors.city?.message}
            />

            <TextField
              className="w-full"
              {...register("address")}
              defaultValue={company.address}
              label="Address"
              error={!!errors?.address}
              variant="filled"
              helperText={errors.address?.message}
              multiline={true}
              rows={3}
            />
          </div>
          {children}
        </form>
      )}
    </div>
  );
}
