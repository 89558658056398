import { Business } from "@mui/icons-material";
import { Avatar, MenuItem, Select } from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import moment from "moment";
import { useMemo, useState } from "react";
import { useEffectOnce } from "usehooks-ts";
import NoRowsOverlay from "../../../components/NoRowsOverlay/NoRowsOverlay";
import { dashboardApi } from "../../../http/dashboard.api";
import { CloseModal } from "../../../providers/ModalProvider";
import { getFindOutWhyText } from "../../../utils/coimex-utils";
import { FindOutWhy, FindOutWhyType } from "../types/find-out-why.type";

interface IProps {
  findOutWhy: FindOutWhy;
  close: CloseModal;
}

export default function FindOutWhyDetails({ findOutWhy, close }: IProps) {
  const [selectedType, setSelectedType] = useState(
    FindOutWhyType.RegisteredButNotVerifiedEmail
  );
  const [rows, setRows] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const cols: GridColDef[] = useMemo(
    () => [
      {
        field: "rowNo",
        headerName: "",
        renderCell: (params) => <div className="pl-2">{params.value}</div>,
        width: 50,
        sortable: false,
      },
      {
        field: "email",
        headerName: "Email",
        flex: 1,
      },
      {
        field: "name",
        headerName: "Company",
        renderCell: (params) => (
          <div className="flex items-center gap-x-2">
            <Avatar src={params.row.avatarUrl} sx={{ width: 28, height: 28 }}>
              <Business fontSize="small" />
            </Avatar>
            <span>{params.value}</span>
          </div>
        ),
        flex: 1,
      },
      {
        field: "joinedAt",
        headerName: "Joined At",
        valueFormatter: (params) => moment(params.value).fromNow(),
        width: 150,
      },
      {
        field: "verifiedAt",
        headerName: "Verified At",
        valueFormatter: (params) => moment(params.value).fromNow(),
        width: 150,
      },
      {
        field: "subscribedAt",
        headerName: "Subscribed At",
        valueFormatter: (params) => moment(params.value).fromNow(),
        width: 150,
      },
      {
        field: "approvedAt",
        headerName: "Approved At",
        valueFormatter: (params) => moment(params.value).fromNow(),
        width: 150,
      },
    ],
    []
  );

  const handleTypeChange = async (type: FindOutWhyType) => {
    try {
      setIsLoading(true);
      const items = await dashboardApi.getFindOutWhyDetail(type);
      setRows(
        items.map((item, index) => ({
          rowNo: index + 1,
          ...item,
        }))
      );
      setSelectedType(type);
    } finally {
      setIsLoading(false);
    }
  };

  useEffectOnce(() => {
    handleTypeChange(selectedType);
  });

  return (
    <div className="max-w-full overflow-hidden" style={{ width: "600px" }}>
      <header className="modal-header mb-0 p-4 flex justify-between items-center pr-12">
        <Select
          className="text-sm"
          size="small"
          value={selectedType}
          MenuProps={{ MenuListProps: { className: "child-modal" } }}
          onChange={(e) => handleTypeChange(e.target.value as FindOutWhyType)}
        >
          {Object.entries(findOutWhy).map(([type, count]) => (
            <MenuItem key={type} value={type}>
              {getFindOutWhyText(type as FindOutWhyType)}
            </MenuItem>
          ))}
        </Select>
      </header>
      <div>
        <DataGrid
          columns={cols}
          rows={rows}
          disableColumnMenu
          disableSelectionOnClick
          style={{
            minHeight: "500px",
            maxHeight: "calc(100vh - 200px)",
            border: "none",
            overflow: "auto",
          }}
          loading={isLoading}
          pagination
          paginationMode="client"
          autoPageSize
          sortingMode="client"
          columnVisibilityModel={{
            name: selectedType !== FindOutWhyType.RegisteredButNotVerifiedEmail,
            joinedAt:
              selectedType === FindOutWhyType.RegisteredButNotVerifiedEmail,
            verifiedAt: selectedType === FindOutWhyType.VerifiedEmailButNoPlan,
            subscribedAt:
              selectedType === FindOutWhyType.HavePlanButNotCompletedProfile,
            approvedAt:
              selectedType === FindOutWhyType.ApprovedButNoOpportunities,
          }}
          components={{
            NoRowsOverlay: () => <NoRowsOverlay emptyMessage="No companies!" />,
          }}
        />
      </div>
    </div>
  );
}
