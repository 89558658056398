import { OpenInNew } from "@mui/icons-material";
import CopyToClipboard from "../../../components/CopyToClipboard/CopyToClipboard";
import { toShortHash } from "../../../utils/blockchain-utils";
import { BlockchainTx } from "../types";
import { useBlockchainEventsModal } from "../providers/BlockchainEventsModalProvider";
import BlockchainTxReceivedEventsBtn from "../../../components/BlockchainTxReceivedEventsBtn/BlockchainReceivedEvents";

interface IProps {
  tx: BlockchainTx;
  targetHash?: string;
}

export default function BlockchainFlowTx({ tx, targetHash }: IProps) {
  const showEvents = useBlockchainEventsModal();

  return (
    tx && (
      <div className="flex flex-col gap-1">
        <div>
          <a
            className="link"
            href={tx.txUrl}
            target="_blank"
            onClick={(e) => e.stopPropagation()}
          >
            {toShortHash(tx.txHash)}
          </a>
          <CopyToClipboard text={tx.txHash} defaultTooltip="Copy Tx hash" />
        </div>
        <div
          className="flex items-center gap-2 link"
          onClick={() => showEvents(tx, targetHash)}
        >
          Received Events
          <BlockchainTxReceivedEventsBtn tx={tx} targetHash={targetHash} />
        </div>
      </div>
    )
  );
}
