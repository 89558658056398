import classNames from "classnames";
import { BlockchainTxStatus } from "../../modules/blockchain/types";
import { getBlockchainTxStatusIndicatorColor } from "../../utils/coimex-utils";
import { CircularProgress } from "@mui/material";

interface IProps {
  status: BlockchainTxStatus | null;
  hideTitle?: boolean;
  isLoading?: boolean;
}

export default function BlockchainTxStatusIndicator({
  status,
  hideTitle,
}: IProps) {
  const color = getBlockchainTxStatusIndicatorColor(status);
  return (
    <div className="flex items-center gap-1 text-xs">
      {!hideTitle && <span className="mr-1">Status</span>}
      <div
        className={classNames(
          "relative rounded-full",
          "w-2",
          "h-2",
          `bg-${color}`
        )}
      >
        {status === BlockchainTxStatus.Pending && (
          <CircularProgress
            className={`absolute -inset-0.5 text-${color}`}
            size={12}
          />
        )}
      </div>
      <span className={classNames(`text-${color}`)}>{status}</span>
    </div>
  );
}
