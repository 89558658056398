import { EventEmitter } from "events";
import {
  createContext,
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useCallback,
  useContext,
  useRef,
  useState,
} from "react";
import { CloseModal } from "../../../providers/ModalProvider";
import { InvestPool } from "../../../types";

export interface PromptPayPoolFormProps {
  pool?: Partial<InvestPool>;
}

interface PromptPayPoolFormContextProps {
  value: PromptPayPoolFormProps;
  setValue: Dispatch<SetStateAction<PromptPayPoolFormProps>>;
  step: number;
  next: (update: Partial<PromptPayPoolFormProps>) => void;
  back: () => void;
  onBack: EventEmitter;
  close: CloseModal;
  nexting: boolean;
  setNexting: Dispatch<SetStateAction<boolean>>;
}

const PromptPayPoolFormContext =
  createContext<PromptPayPoolFormContextProps>(null);

interface IProps {
  close: CloseModal<InvestPool>;
  opts?: { pool?: InvestPool };
}

export default function PromptPayPoolFormProvider({
  close,
  children,
  opts,
}: PropsWithChildren<IProps>) {
  const [value, setValue] = useState<PromptPayPoolFormProps>({
    pool: opts?.pool,
  });
  const [step, setStep] = useState(0);
  const [nexting, setNexting] = useState(false);
  const onBack = useRef(new EventEmitter());

  const next = useCallback((update: Partial<PromptPayPoolFormProps>) => {
    setValue((value) => ({
      ...value,
      ...update,
    }));
    setStep((step) => +step + 1);
  }, []);

  const back = useCallback(() => {
    onBack.current.emit("back");
    setStep((step) => Math.max(0, +step - 1));
  }, []);

  return (
    <PromptPayPoolFormContext.Provider
      value={{
        value,
        setValue,
        step: +step,
        next,
        back,
        onBack: onBack.current,
        close,
        nexting,
        setNexting,
      }}
    >
      {children}
    </PromptPayPoolFormContext.Provider>
  );
}

export const usePromptPayPoolForm = () => {
  return useContext(PromptPayPoolFormContext);
};
