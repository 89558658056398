import { yupResolver } from "@hookform/resolvers/yup";
import { TextField } from "@mui/material";
import { useEffect, useRef } from "react";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import useFocus from "../../../../hooks/use-focus";
import { companiesApi } from "../../../../http";
import { useCompanyForm } from "./CompanyFormProvider";

const validationSchema = Yup.object().shape({
  website: Yup.string().url("Not a valid url format."),
});

export default function CompanyFormContact({ children }) {
  const { company, close, setNexting } = useCompanyForm();
  const formOptions = {
    resolver: yupResolver(validationSchema),
    mode: "onSubmit",
  };
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm(formOptions as any);
  const phoneRef = useRef();
  useFocus(phoneRef);

  const onSubmit = async (value) => {
    try {
      setNexting(true);
      const company = await companiesApi.addCompany(value);
      close(company);
    } catch {
      setNexting(false);
    }
  };

  useEffect(() => {
    reset(company);
  }, [company]);

  return (
    <form
      className="flex-1 w-full flex flex-col gap-4 mt-2"
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className="flex-1 flex flex-col gap-6 px-1">
        <TextField
          className="w-full"
          {...register("phone")}
          defaultValue={company.phone}
          label="Phone"
          error={!!errors?.phone}
          variant="filled"
          helperText={errors.phone?.message}
          inputRef={phoneRef}
        />

        <TextField
          className="w-full"
          {...register("website")}
          defaultValue={company.website}
          label="Website"
          error={!!errors?.website}
          variant="filled"
          helperText={errors.website?.message}
        />

        <TextField
          className="w-full"
          {...register("summary")}
          defaultValue={company.summary}
          label="Summary"
          error={!!errors?.summary}
          variant="filled"
          helperText={errors.summary?.message}
          multiline={true}
          rows={3}
        />
      </div>
      {children}
    </form>
  );
}
