import { useState } from "react";
import { AppPage } from "../../../types";
import CommodityTokensFilters from "./CommodityTokensFilters";
import CommodityTokensSettings from "./CommodityTokensSettings";
import CommodityTokensTable from "./CommodityTokensTable";

export default function CommodityTokens() {
  const [selectedTab, setSelectedTab] = useState<
    AppPage.CommodityTokens | AppPage.CommodityTokensSettings
  >(AppPage.CommodityTokens);

  return (
    <div className="w-constraint">
      <CommodityTokensFilters
        tab={selectedTab}
        onSelectedTabChange={setSelectedTab}
      />
      {selectedTab == AppPage.CommodityTokens ? (
        <CommodityTokensTable />
      ) : (
        <CommodityTokensSettings />
      )}
    </div>
  );
}
