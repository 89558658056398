import { createAsyncThunk } from "@reduxjs/toolkit";
import { investApi } from "../../http";
import { AppPage, AppRefreshStatus, InvestPoolType } from "../../types";
import { appActions } from "../app/app.state";
import { RootState } from "../store";
import { InvestState } from "./invest.state";

export const getInvestPoolsAction = createAsyncThunk(
    'invest/getInvestPools',
    async ({ type, page, pageSize }: { type: InvestPoolType, page?: number, pageSize?: number }, thunk) => {
        try {
            thunk.dispatch(appActions.changeRefresh({
                status: AppRefreshStatus.InProgress,
                page: AppPage[`${type}Pools`]
            }));

            const investState: InvestState = (<RootState>thunk.getState()).invest;
            page = page ?? investState[type].pagination.page;
            pageSize = pageSize ?? investState[type].pagination.pageSize;

            const paginatedInvestPools = await investApi.getInvestPools({
                filters: JSON.stringify({ type }),
                from: page * pageSize,
                take: pageSize,
            });

            return { type, paginatedInvestPools, page, pageSize };
        } finally {
            thunk.dispatch(appActions.changeRefresh({
                status: AppRefreshStatus.Idle,
                page: AppPage[`${type}Pools`]
            }));
        }
    }
)