import { createSlice } from "@reduxjs/toolkit";
import { DEFAULTS } from "../../common/constants";
import { Opportunity, Pagination } from "../../types";
import { addCommonReducers } from "../../utils/store-utils";
import { OpportunityStatusFilter } from "../offers/types/opportunity-status-filter.type";
import { getRequestsAction, getRequestsStatusCountsAction } from "./requets.effects";
import { RequestsFilters } from "./types/requests-filters.type";

export const REQUESTS_STATE_NAME = 'requests';

export interface RequestsState {
    requests: Opportunity[]
    statusCounts: Record<OpportunityStatusFilter, number>
    filters: RequestsFilters
    pagination: Pagination
}

export const requestsState = createSlice({
    name: REQUESTS_STATE_NAME,
    initialState: {
        requests: [],
        statusCounts: {
            All: 0,
            UnderReview: 0,
            Rejected: 0,
            Active: 0,
            Expired: 0,
            Canceled: 0
        },
        filters: {
            status: OpportunityStatusFilter.All,
            orderBy: {
                field: 'createdAt',
                sort: 'desc'
            }
        },
        pagination: {
            page: 0,
            pageSize: DEFAULTS.PageSize,
            total: 0
        }
    } as RequestsState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getRequestsAction.fulfilled, (state, action) => {
                const { paginatedRequests, page, pageSize, filters } = action.payload;
                state.requests = paginatedRequests.data;
                state.pagination.total = paginatedRequests.total;
                state.pagination.page = page;
                state.pagination.pageSize = pageSize;
                state.filters = filters;
            })
            .addCase(getRequestsStatusCountsAction.fulfilled, (state, action) => {
                state.statusCounts = action.payload;
            });
        addCommonReducers(builder, REQUESTS_STATE_NAME);
    }
});

export const requestsActions = requestsState.actions;
export const requestsReducer = requestsState.reducer;