import classNames from "classnames";
import {
  getSmartDealPaymentStatusIndicatorColor,
  getSmartDealPaymentStatusIndicatorText,
} from "../../utils/coimex-utils";

interface IProps {
  isClosed: boolean;
}

export default function SmartDealPaymentStatusIndicator({ isClosed }: IProps) {
  const color = getSmartDealPaymentStatusIndicatorColor(isClosed);
  return (
    <div className="flex items-center gap-1 text-xs">
      <span className="mr-1">Status</span>
      <span
        className={classNames("rounded-full", "w-2", "h-2", `bg-${color}`)}
      ></span>
      <span className={classNames(`text-${color}`)}>
        {getSmartDealPaymentStatusIndicatorText(isClosed)}
      </span>
    </div>
  );
}
