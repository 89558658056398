import { Button } from "@mui/material";
import classNames from "classnames";
import { Plan } from "../../types";

export default function PlanCard({
  plan,
  selected,
  onSelect,
}: {
  plan: Plan;
  selected: boolean;
  onSelect: (plan: Plan) => void;
}) {
  return (
    <div
      className={classNames(
        "w-full flex flex-col gap-1 rounded-lg py-2.5 px-3 text-sm",
        {
          "bg-bg-1": !selected,
          "bg-blue-200": selected,
        }
      )}
    >
      <div className="flex items-center gap-2">
        <span className="font-bold">{plan.type}</span>
        <span className="text-xs">${plan.price}</span>
      </div>
      <div className="flex justify-between items-end">
        <span className="text-text-1">
          {plan.quotaText.posts}, {plan.quotaText.categories}
        </span>
        <Button
          className="rounded-3xl"
          size="small"
          variant="contained"
          disableElevation
          onClick={() => onSelect(plan)}
        >
          Select
        </Button>
      </div>
    </div>
  );
}
