import {
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator,
  TimelineDot,
  TimelineContent,
} from "@mui/lab";

interface IProps {
  timestamp: string;
}

export default function SmartDealPaymentFlowClose({ timestamp }: IProps) {
  return (
    <TimelineItem>
      <TimelineOppositeContent>{timestamp}</TimelineOppositeContent>
      <TimelineSeparator>
        <TimelineDot />
      </TimelineSeparator>
      <TimelineContent className="text-text">Payment Closed</TimelineContent>
    </TimelineItem>
  );
}
