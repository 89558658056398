import {
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  createContext,
  useCallback,
  useContext,
  useState,
} from "react";
import { useEffectOnce } from "usehooks-ts";
import { companiesApi } from "../../../http";
import { CloseModal } from "../../../providers/ModalProvider";
import { Company } from "../../../types";
import { ProformaInvoice, SmartDeal, SmartDealState } from "../types";
import { STEPS } from "../components/SmartDealForm/SmartDealForm";

export interface SmartDealFormProps {
  smartDeal: SmartDeal;
  proformaInvoice: ProformaInvoice;
}

interface SmartDealFormContextProps {
  value: SmartDealFormProps;
  setValue: Dispatch<SetStateAction<SmartDealFormProps>>;
  step: number;
  next: (update: Partial<SmartDealFormProps>) => void;
  back: () => void;
  close: CloseModal;
  nexting: boolean;
  setNexting: Dispatch<SetStateAction<boolean>>;
  companies: Company[];
  isGetCompaniesPending: boolean;
}

const SmartDealFormContext = createContext<SmartDealFormContextProps>(null);

interface IProps {
  close: CloseModal<SmartDeal>;
  opts?: {
    smartDeal?: SmartDeal;
    disableGetCompanies: boolean;
  };
}

export default function SmartDealFormProvider({
  close,
  children,
  opts,
}: PropsWithChildren<IProps>) {
  const [value, setValue] = useState<SmartDealFormProps>(
    opts?.smartDeal
      ? {
          smartDeal: opts.smartDeal,
          proformaInvoice: opts.smartDeal.proformaInvoice,
        }
      : ({} as any)
  );
  const getInitialStep = (): number | void => {
    if (opts?.smartDeal?.state === SmartDealState.Blockchain) {
      close(opts?.smartDeal);
      return STEPS;
    }
    return opts?.smartDeal?.state === SmartDealState.Draft ? 1 : 0;
  };
  const [step, setStep] = useState(getInitialStep());
  const [nexting, setNexting] = useState(false);
  const [companies, setCompanies] = useState<Company[]>([]);
  const [isGetCompaniesPending, setIsGetCompaniesPending] = useState(true);

  const next = useCallback((update: Partial<SmartDealFormProps>) => {
    setValue((value) => ({
      ...value,
      ...update,
    }));
    setStep((step) => +step + 1);
  }, []);

  const back = useCallback(() => {
    setStep((step) => Math.max(0, +step - 1));
  }, []);

  useEffectOnce(() => {
    const getCompanies = async () => {
      setCompanies(
        (await companiesApi.getCompanies()).data.filter((c) => c.name?.trim())
      );
      setIsGetCompaniesPending(false);
    };

    !opts?.disableGetCompanies && getCompanies();
  });

  return (
    <SmartDealFormContext.Provider
      value={{
        value,
        setValue,
        step: +step,
        next,
        back,
        close,
        nexting,
        setNexting,
        companies,
        isGetCompaniesPending,
      }}
    >
      {children}
    </SmartDealFormContext.Provider>
  );
}

export const useSmartDealForm = () => {
  return useContext(SmartDealFormContext);
};
