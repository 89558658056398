import { isValidCron } from "cron-validator";
import * as yup from "yup";
import { CONSTANTS } from "../common/constants";
import { isValidBlockchainAddress } from "../utils/blockchain-utils";

yup.addMethod<yup.StringSchema>(
  yup.string,
  "croneExpression",
  function (msg = "Invalid crone expression.") {
    return this.test("croneExpression", msg, (value) => {
      return value ? isValidCron(value, CONSTANTS.CroneValidation) : false;
    });
  }
);

yup.addMethod<yup.StringSchema>(
  yup.string,
  "blockchainAddress",
  function (msg = "Invalid address.") {
    return this.test("blockchainAddress", msg, (value) => {
      return value ? isValidBlockchainAddress(value) : false;
    });
  }
);

declare module "yup" {
  interface StringSchema {
    croneExpression(msg?: string): this;
    blockchainAddress(msg?: string): this;
  }
}

export default yup;
