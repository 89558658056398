import { createAsyncThunk } from "@reduxjs/toolkit";
import { paymentsApi } from "../../http";
import { AppPage } from "../../types/app-page.type";
import { AppRefreshStatus } from "../../types/app-refresh-status.type";
import { appActions } from "../app/app.state";
import { RootState } from "../store";
import { PaymentsState } from "./payments.state";
import { PaymentsFilters } from "./types/payments-filters.type";

export const getPaymentsAction = createAsyncThunk(
    'payments/getPayments',
    async ({ page, pageSize, filters }: { page?: number, pageSize?: number, filters?: Partial<PaymentsFilters> }, thunk) => {
        const paymentsState: PaymentsState = (<RootState>thunk.getState()).payments;
        page = page ?? paymentsState.pagination.page;
        pageSize = pageSize ?? paymentsState.pagination.pageSize;
        filters = {
            ...paymentsState.filters,
            ...(filters || {})
        };
        const paginatedPayments = await paymentsApi.getPayments({
            from: page * pageSize,
            take: pageSize,
            filters: JSON.stringify(filters)
        });
        return { paginatedPayments, page, pageSize, filters: <PaymentsFilters>filters };
    }
)

export const getPaymentsStatusCountsAction = createAsyncThunk(
    'payments/getPaymentsStatusCounts',
    async (_, thunk) => {
        try {
            thunk.dispatch(appActions.changeRefresh({
                status: AppRefreshStatus.InProgress,
                page: AppPage.Payments
            }));
            const response = await paymentsApi.getPaymentsStatusCounts();
            return response;
        } finally {
            thunk.dispatch(appActions.changeRefresh({
                status: AppRefreshStatus.Idle,
                page: AppPage.Payments
            }));
        }
    }
)