import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { useEffectOnce } from "usehooks-ts";
import { useModal } from "../../../providers/ModalProvider";
import { AppPage } from "../../../types/app-page.type";
import { AppRefreshStatus } from "../../../types/app-refresh-status.type";
import {
  selectRefreshPage,
  selectRefreshStatus,
} from "../../app/app.selectors";
import {
  getOffersAction,
  getOffersStatusCountsAction,
} from "../offers.effects";
import OfferPage from "./OfferPage";
import OffersFilters from "./OffersFilters";
import OffersTable from "./OffersTable";

export default function Offers() {
  const refreshPage = useSelector(selectRefreshPage);
  const refreshStatus = useSelector(selectRefreshStatus);
  const params = useParams();
  const navigate = useNavigate();
  const openModal = useModal();
  const dispatch = useDispatch();

  const getOffers = () => {
    dispatch(getOffersStatusCountsAction({ changeRefresh: true }));
    dispatch(getOffersAction({ page: 0 }));
  };

  const showOfferPage = async (id) => {
    const cancelNavigate = await openModal((props) => (
      <OfferPage id={id} {...props} />
    ));
    if (!cancelNavigate) {
      handleOfferPageClose();
    }
  };

  const handleOfferPageClose = () => {
    navigate("/offers");
  };

  useEffectOnce(() => {
    dispatch(getOffersStatusCountsAction({ changeRefresh: true }));
  });

  useEffect(() => {
    const { id } = params;
    id && showOfferPage(id);
  }, [params?.id]);

  useEffect(() => {
    if (
      refreshStatus === AppRefreshStatus.Invalidated &&
      refreshPage === AppPage.Offers
    ) {
      getOffers();
    }
  }, [refreshStatus]);

  return (
    <div className="w-constraint">
      <OffersFilters />
      <OffersTable />
    </div>
  );
}
