import {
  Chip,
  ChipTypeMap,
  LinearProgress,
  LinearProgressProps,
} from "@mui/material";

interface IProps {
  label: string;
  progress: number;
  color: ChipTypeMap["props"]["color"] & LinearProgressProps["color"];
}

export default function ChipWithProgress({ label, progress, color }: IProps) {
  return (
    <div className="relative">
      <LinearProgress
        className="absolute inset-0 h-full rounded-2xl"
        value={progress}
        variant="determinate"
        color={color}
      />
      <Chip
        className="relative text-white"
        label={label}
        color={color}
        size="small"
        variant="outlined"
      />
    </div>
  );
}
