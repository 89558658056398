import { useCallback } from "react";
import DeleteConfirm, {
  IDeleteConfirmProps,
} from "../components/DeleteConfirm/DeleteConfirm";
import { useModal } from "../providers/ModalProvider";

export default function useDeleteConfirm() {
  const openModal = useModal();

  const confirm = useCallback(
    ({ title, onConfirm }: Pick<IDeleteConfirmProps, "title" | "onConfirm">) =>
      openModal((props) => (
        <DeleteConfirm title={title} onConfirm={onConfirm} {...props} />
      )),
    []
  );

  return confirm;
}
