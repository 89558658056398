import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { getSchedulesAction } from "./schedules.effects";

export const selectSchedulesState = (state: RootState) => state.schedules;

export const selectSchedules = createSelector(
    selectSchedulesState,
    state => state.schedules.sortBy('id')
)

export const selectIsGetSchedulesPending = createSelector(
    selectSchedulesState,
    state => state[getSchedulesAction.pending.type]
)

export const selectPendingForceRunScheduleId = createSelector(
    selectSchedulesState,
    state => state.pendingForceRunScheduleId
)

export const selectPendingUpdateScheduleId = createSelector(
    selectSchedulesState,
    state => state.pendingUpdateScheduleId
)