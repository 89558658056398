import { KeyboardArrowRight } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { Button, MobileStepper } from "@mui/material";
import { useMemo } from "react";
import { usePromptPayPoolForm } from "../providers/PromptPayPoolFormProvider";
import PromptPayPoolFormInfo from "./PromptPayPoolFormInfo";
import PromptPayPoolFormPublish from "./PromptPayPoolFormPublish";
import PromptPayPoolFormTenure from "./PromptPayPoolFormTenure";

export const STEPS = 3;

export default function PromptPayPoolForm() {
  const { value, step, nexting, back, close } = usePromptPayPoolForm();

  const stepContent: { Component; title } = useMemo(() => {
    switch (step) {
      case 0:
        return {
          Component: PromptPayPoolFormInfo,
          title: "Prompt Pay Pool Info",
        };

      case 1:
        return {
          Component: PromptPayPoolFormTenure,
          title: "Prompt Pay Pool Tenure",
        };

      case 2:
        return {
          Component: PromptPayPoolFormPublish,
          title: "Publish Prompt Pay Pool",
        };
    }
  }, [step]);

  const handleBackClick = () => {
    switch (step) {
      case 0:
        close();
        break;

      case 1:
        back();
        break;

      case 2:
        close(value?.pool);
        break;
    }
  };

  return (
    <div
      className="InvestPoolForm w-96 mt-2 p-4 flex flex-col gap-2"
      style={{ minHeight: "200px" }}
    >
      <div className="border-b border-border pb-2 mb-2">
        {stepContent.title}
      </div>
      <stepContent.Component>
        <MobileStepper
          variant="progress"
          color="secondary"
          steps={STEPS}
          position="static"
          activeStep={step}
          nextButton={
            <LoadingButton
              size="small"
              type="submit"
              loading={nexting}
              tabIndex={0}
            >
              {step < STEPS - 1 ? "Next" : "Save"}
              {step < STEPS - 1 && <KeyboardArrowRight />}
            </LoadingButton>
          }
          backButton={
            <Button size="small" onClick={handleBackClick} tabIndex={1}>
              {step == 1 ? "Back" : "Close"}
            </Button>
          }
        />
      </stepContent.Component>
    </div>
  );
}
