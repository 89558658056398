import { Avatar, Chip } from "@mui/material";
import { useSelector } from "react-redux";
import { selectCountries } from "../../modules/settings/settings.selectors";

interface IProps {
  countryId?: string;
}

export default function CountryChip({ countryId }: IProps) {
  const countries = useSelector(selectCountries);
  const country = countries.find((c) => c.id === countryId);

  return country ? (
    <Chip
      avatar={
        <Avatar
          imgProps={{ className: "object-left" }}
          src={country?.flagUrl}
        />
      }
      label={country.name}
      variant="outlined"
      size="small"
    />
  ) : (
    <></>
  );
}
