import { ActionReducerMapBuilder } from "@reduxjs/toolkit";
import { AnyAction } from "redux";
import { RootState } from "../modules/store";

export function isPendingAction(stateName: string): (action: AnyAction) => boolean {
    return (action) => isActionForSlice(stateName, action) && action.type.endsWith('pending');
}

export function isCompleteAction(stateName: string): (action: AnyAction) => boolean {
    return (action) => isActionForSlice(stateName, action) && (action.type.endsWith('fulfilled') || action.type.endsWith('rejected'));
}

function isActionForSlice(stateName: string, action: AnyAction): boolean {
    return action.type.startsWith(stateName);
}

export function pendingType(type: string): string {
    return type.replace('fulfilled', 'pending').replace('rejected', 'pending');
}

export function addCommonReducers(builder: ActionReducerMapBuilder<any>, stateName: string) {
    builder
        .addMatcher(isPendingAction(stateName), (state, action) => {
            state[action.type] = true;
        })
        .addMatcher(isCompleteAction(stateName), (state, action) => {
            state[pendingType(action.type)] = false;
        })
        .addDefaultCase(() => { })
}

export function getRootState(thunk): RootState {
    return thunk.getState();
}