import { Add, Close } from "@mui/icons-material";
import { Button, CircularProgress, Paper } from "@mui/material";
import classNames from "classnames";
import { useRef, useState } from "react";

interface IProps {
  url?: string;
  className?: string;
  onImageRemove?: () => void;
  onFileUpload?: (file: File) => Promise<any>;
  onPreviewClicked?: () => void;
}

export default function ImageUploader({
  url,
  className,
  onImageRemove = () => {},
  onFileUpload = () => Promise.resolve(true),
  onPreviewClicked = () => {},
}: IProps) {
  const [isUploading, setIsUploading] = useState(false);
  const inputRef = useRef<any>();

  const handleFileUpload = async (file?: File) => {
    if (file) {
      try {
        setIsUploading(true);
        await onFileUpload(file);
      } finally {
        clearFileInput();
        setIsUploading(false);
      }
    }
  };

  const clearFileInput = () => {
    if (inputRef.current) {
      inputRef.current.value = null;
      inputRef.current.files = null;
    }
  };

  return url ? (
    <div
      className={classNames("relative w-40 h-40 select-none group", className)}
    >
      <Paper className="w-full h-full overflow-hidden" elevation={3}>
        <img
          src={url}
          className="w-full h-full object-fill"
          draggable={false}
        />
        <div
          className="absolute inset-0 flex justify-center items-center rounded-md"
          style={{
            background: "rgba(0, 0, 0, .2)",
          }}
        >
          <Button
            color="white"
            variant="outlined"
            size="small"
            onClick={onPreviewClicked}
          >
            Preview
          </Button>
        </div>
      </Paper>
      <Close
        className="absolute -right-2 -top-2 bg-white border border-gray-300 rounded-full text-gray-500 cursor-pointer p-1 hover:bg-gray-100 opacity-0 group-hover:opacity-100 transition-opacity duration-300"
        fontSize="medium"
        onClick={onImageRemove}
      />
    </div>
  ) : (
    <div
      className={classNames(
        "relative w-40 h-40 border-2 border-gray-300 border-dashed  rounded-md flex justify-center items-center cursor-pointer hover:bg-gray-100",
        className
      )}
    >
      {isUploading ? (
        <CircularProgress color="primary" size="32px" />
      ) : (
        <>
          <Add fontSize="large" />
          <input
            ref={inputRef}
            className="absolute inset-0 opacity-0 cursor-pointer"
            type="file"
            accept="image/*"
            onChange={(e) => handleFileUpload(e.target.files?.[0])}
          />
        </>
      )}
    </div>
  );
}
