import { ExpandMore } from "@mui/icons-material";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import moment from "moment";
import { useEffect, useMemo, useState } from "react";
import NoRowsOverlay from "../../../components/NoRowsOverlay/NoRowsOverlay";
import { commodityTokenApi } from "../../../http/commodity-tokens.api";
import { CommodityToken, CommodityTokenPrice } from "../../../types";
import { toShortHash } from "../../../utils/blockchain-utils";
import { formatNumberWithSeparator } from "../../../utils/number-utils";

interface IProps {
  token: CommodityToken;
  tokenPrice: CommodityTokenPrice;
}

export default function CommodityTokenMinMaxAmountInfo({
  token,
  tokenPrice,
}: IProps) {
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(4);
  const [expanded, setExpanded] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [rows, setRows] = useState([]);
  const cols = useMemo<GridColDef[]>(
    () => [
      {
        field: "rowNo",
        headerName: "",
        sortable: false,
        width: 50,
      },
      {
        field: "minAmount",
        headerName: "Min Amount",
        renderCell: ({ value }) => formatNumberWithSeparator(value),
        sortable: false,
        flex: 1,
      },
      {
        field: "maxAmount",
        headerName: "Max Amount",
        renderCell: ({ value }) => formatNumberWithSeparator(value),
        sortable: false,
        flex: 1,
      },
      {
        field: "tx",
        headerName: "Tx Hash",
        renderCell: ({ value }) =>
          value ? (
            <a
              className="link"
              href={value.txUrl}
              target="_blank"
              onClick={(e) => e.stopPropagation()}
            >
              {toShortHash(value.txHash)}
            </a>
          ) : null,
        sortable: false,
        width: 130,
      },
      {
        field: "createdAt",
        headerName: "Sent At",
        valueFormatter: (params) => moment(params.value).fromNow(),
        sortable: false,
        width: 120,
      },
    ],
    []
  );

  const getSetMinMaxAmountTxs = async (
    pg: { page: number; pageSize: number } = null
  ) => {
    try {
      setIsLoading(true);
      const { data, total } = await commodityTokenApi.getSetMinMaxAmountTxs(
        token.address,
        tokenPrice.address,
        {
          from: pg?.page * pg?.pageSize,
          take: pg?.pageSize,
        }
      );
      setRows(
        data?.map((setMinMaxAmountTx, index) => ({
          ...setMinMaxAmountTx,
          rowNo: (pg?.page || 0) * (pg?.pageSize || 0) + index + 1,
        })) || []
      );
      setTotal(total);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (expanded) {
      getSetMinMaxAmountTxs({ page, pageSize });
    }
  }, [expanded, page, pageSize]);

  return (
    <Accordion
      className="-mx-1 -mt-2"
      onChange={(_, expanded) => setExpanded(expanded)}
    >
      <AccordionSummary expandIcon={<ExpandMore />}>
        <div className="info-section-item grid-cols-[180px_1fr]">
          <div className="flex gap-2">
            Min Amount:
            <span className="info-section-item__value">
              {tokenPrice.minAmount}
            </span>
          </div>

          <div className="flex gap-2">
            Max Amount:
            <span className="info-section-item__value">
              {tokenPrice.maxAmount}
            </span>
          </div>
        </div>
      </AccordionSummary>
      <AccordionDetails>
        <DataGrid
          columns={cols}
          rows={rows}
          disableColumnMenu
          disableSelectionOnClick
          style={{ minHeight: "100px" }}
          loading={isLoading}
          pagination
          paginationMode="server"
          autoHeight
          pageSize={pageSize}
          rowCount={total}
          onPageChange={(page) => setPage(page)}
          onPageSizeChange={(pageSize) => setPageSize(pageSize)}
          components={{
            NoRowsOverlay: () => <NoRowsOverlay emptyMessage="No record" />,
          }}
        />
      </AccordionDetails>
    </Accordion>
  );
}
