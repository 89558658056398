import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { getCommodityTokensAction } from "./commodity-tokens.effects";

export const selectCommodityTokensState = (state: RootState) => state.commodityTokens;

export const selectCommodityTokens = createSelector(
    selectCommodityTokensState,
    state => state.commodityTokens || []
)

export const selectIsGetCommodityTokensPending = createSelector(
    selectCommodityTokensState,
    state => state[getCommodityTokensAction.pending.type]
)