import { createAsyncThunk } from "@reduxjs/toolkit";
import { configsApi } from "../../http";
import { Configs } from "../../types";
import { RootState } from "../store";
import { settingsActions } from "./settings.state";

export const getConfigsAction = createAsyncThunk(
    'settings/getConfigs',
    async () => {
        return configsApi.getConfigs();
    }
)

export const updateConfigsAction = createAsyncThunk(
    'settings/updateConfigs',
    async (update: Partial<Configs>, thunk) => {
        const preConfigs = JSON.stringify((<RootState>thunk.getState()).settings.configs || {});
        thunk.dispatch(settingsActions.updateConfigs(update));
        try {
            await configsApi.updateConfigs(update);
        } catch (error) {
            thunk.dispatch(settingsActions.updateConfigs(JSON.parse(preConfigs)));
        }
    }
)