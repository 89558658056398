import api from ".";
import { EligibleUpgradePlansResponse } from "../modules/plans/types/eligible-upgrade-plans-response.type";
import { PlanUpgradeRequest } from "../modules/plans/types/plan-upgrade-request.type";
import { Paginated, PaginationQuery, Plan, PlanSubscription } from "../types";

export const plansApi = {
    getPlans: async (): Promise<Plan[]> => {
        return (await api.get('/plans')).data;
    },
    getPlanSubscriptions: (params?: PaginationQuery): Promise<Paginated<PlanSubscription>> => {
        return api.get('/plans/subscriptions', { params });
    },
    getPlanUpgradeRequests: (params?: PaginationQuery): Promise<Paginated<PlanUpgradeRequest>> => {
        return api.get('/plans/upgrade/requests', { params });
    },
    getUpgradeRequestEligiblePlans: async (id: PlanUpgradeRequest['id']): Promise<EligibleUpgradePlansResponse> => {
        return (await api.get(`/plans/upgrade/requests/${id}/eligible-plans`)).data;
    },
    approveUpgradePlanRequest: async (id: PlanUpgradeRequest['id'], planId: Plan['id']): Promise<PlanSubscription> => {
        return (await api.post(`/plans/upgrade/requests/${id}/approve`, { planId })).data;
    },
    rejectUpgradePlanRequest: async (id: PlanUpgradeRequest['id'], remarks: string): Promise<void> => {
        return api.post(`/plans/upgrade/requests/${id}/reject`, { remarks });
    }
}