import { Link } from "react-router-dom";
import { Company } from "../../../../types";
import { NotificationTemplateProps } from "../../types/notification-template.type";

export default function PlanUpgradeRequestUnderReviewNotification({
  payload,
}: NotificationTemplateProps<{ company: Partial<Company> }>) {
  return (
    <div>
      <Link
        className="link"
        to={`/companies/${payload?.company?.id}`}
        onClick={(e) => e.stopPropagation()}
      >
        {payload?.company?.name}
      </Link>{" "}
      requested for plan upgrade.
    </div>
  );
}
