import { createAsyncThunk } from "@reduxjs/toolkit";
import { meApi } from "../../http";
import { DEFAULTS } from "../../common/constants";
import { getRootState } from "../../utils/store-utils";
import { Notification } from "../../types";

export const getNotificationsAction = createAsyncThunk(
    'notifications/getNotifications',
    (_, thunk) => {
        const { notifications, total } = getRootState(thunk).notifications;
        if (!total || notifications?.length < total) {
            return meApi.getNotifications({ from: notifications?.length || 0, take: DEFAULTS.NotificationsPageSize });
        }
    }
)

export const getNotificationsStatusAction = createAsyncThunk(
    'notifications/getNotificationsStatus',
    () => {
        return meApi.getNotificationsStatus();
    }
)

export const updateLastSeenNotificationAction = createAsyncThunk(
    'notifications/updateLastSeenNotification',
    (notification: Notification) => {
        return meApi.updateLastSeenNotificationId(notification.id);
    }
)