import { Inbox } from "@mui/icons-material";
import { Stack } from "@mui/material";

export default function NoRowsOverlay({ emptyMessage }) {
  return (
    <Stack
      className="opacity-70"
      height="100%"
      alignItems="center"
      justifyContent="center"
    >
      <span>{emptyMessage}</span>
      <Inbox fontSize="medium" />
    </Stack>
  );
}
