export enum CompanyProfileStep {
    AddBasicInfo = 'AddBasicInfo',
    AddExportCategories = 'AddExportCategories',
    AddExportCountries = 'AddExportCountries',
    AddImportCategories = 'AddImportCategories',
    AddImportCountries = 'AddImportCountries',
    AddAddressInformation = 'AddAddressInformation',
    AddBusinessContactInformation = 'AddBusinessContactInformation',
    AddBusinessFundamentalInformation = 'AddBusinessFundamentalInformation',
    AddPicturesAndDocuments = 'AddPicturesAndDocuments'
}