export enum FindOutWhyType {
  RegisteredButNotVerifiedEmail = "registeredButNotVerifiedEmail",
  VerifiedEmailButNoPlan = "verifiedEmailButNoPlan",
  HavePlanButNotCompletedProfile = "havePlanButNotCompletedProfile",
  ApprovedButNoOpportunities = "approvedButNoOpportunities",
}

export class FindOutWhy implements Record<FindOutWhyType, number> {
  registeredButNotVerifiedEmail = 0;
  verifiedEmailButNoPlan = 0;
  havePlanButNotCompletedProfile = 0;
  approvedButNoOpportunities = 0;
}
