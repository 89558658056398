import CopyToClipboard from "../../../components/CopyToClipboard/CopyToClipboard";
import { CommodityToken } from "../../../types";
import { formatNumberWithSeparator } from "../../../utils/number-utils";
import CommodityTokenPauseInfo from "./CommodityTokenPauseInfo";
import CommodityTokenTotalSupplyInfo from "./CommodityTokenTotalSupplyInfo";
import "./CommodityTokens.scss";

interface IProps {
  token: CommodityToken;
}

export default function CommodityTokenInfo({ token }: IProps) {
  return (
    <div className="max-w-3xl mx-auto flex flex-col gap-y-6 ">
      <section className="commodity-token-info-section">
        <div className="info-section-item">
          <span>Token Name</span>
          <span className="info-section-item__value">{token.name}</span>
        </div>

        <div className="info-section-item">
          <span>Token Symbol</span>
          <span className="info-section-item__value">{token.symbol}</span>
        </div>

        <div className="info-section-item">
          <span>Token Address</span>
          <span className="info-section-item__value">
            <a
              className="link"
              href={token.addressUrl}
              target="_blank"
              onClick={(e) => e.stopPropagation()}
            >
              {token.address}
            </a>
            <CopyToClipboard
              text={token.address}
              defaultTooltip="Copy Address"
            />
          </span>
        </div>

        <CommodityTokenTotalSupplyInfo token={token} />

        <div className="info-section-item">
          <span>Available Amount</span>
          <span className="info-section-item__value">
            {formatNumberWithSeparator(token.amountAvailableToBuy)}
          </span>
        </div>

        <CommodityTokenPauseInfo token={token} />
      </section>
    </div>
  );
}
