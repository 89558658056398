import { Close, Search } from "@mui/icons-material";
import { InputAdornment, TextField } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import useSkipFirstRenderEffect from "../../../hooks/use-skip-first-render-effect";

interface IProps {
  title?: string;
  onSearch: (search: string) => void;
}

export default function WidgetSearch({ title, onSearch }: IProps) {
  const [isSearchMode, setIsSearchMode] = useState(false);
  const [search, setSearch] = useState("");
  const inputRef = useRef<any>();

  const enterSearchMode = () => {
    setIsSearchMode(true);
    setTimeout(() => inputRef?.current?.focus?.());
  };

  const exitSearchMode = () => {
    setIsSearchMode(false);
    onSearch("");
  };

  const handleKeyDown = (e: KeyboardEvent) => {
    if (e.key === "Escape") {
      exitSearchMode();
    }
  };

  useSkipFirstRenderEffect(() => {
    onSearch(search);
  }, [search]);

  useEffect(() => {
    if (isSearchMode) {
      inputRef.current?.addEventListener("keydown", handleKeyDown);
    }
    return () =>
      inputRef.current?.removeEventListener("keydown", handleKeyDown);
  }, [isSearchMode]);

  return (
    <div className="widget-header">
      {!isSearchMode ? (
        <div className="w-full flex justify-between items-center">
          <span>{title}</span>
          <Search className="cursor-pointer" onClick={enterSearchMode} />
        </div>
      ) : (
        <TextField
          fullWidth
          placeholder="Search"
          variant="outlined"
          size="small"
          autoComplete="off"
          inputProps={{
            className: "py-1 text-sm",
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment
                className="cursor-pointer -mr-2"
                position="end"
                onClick={exitSearchMode}
              >
                <Close fontSize="small" />
              </InputAdornment>
            ),
          }}
          inputRef={inputRef}
          onChange={(e) => setSearch(e.target.value)}
        />
      )}
    </div>
  );
}
