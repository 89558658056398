import classNames from "classnames";
import {
  getPlanUpgradeRequestColor,
  getPlanUpgradeRequestStatusIndicatorText,
} from "../../../utils/coimex-utils";
import { PlanUpgradeRequestStatus } from "../types/plan-upgrade-request-status.type";

interface IProps {
  status: PlanUpgradeRequestStatus;
}

export default function PlanUpgradeRequestStatusIndicator({ status }: IProps) {
  const color = getPlanUpgradeRequestColor(status);
  return (
    <div className="flex items-center gap-1 text-xs">
      <span className="mr-1">Status</span>
      <span
        className={classNames("rounded-full", "w-2", "h-2", `bg-${color}`)}
      ></span>
      <span className={classNames(`text-${color}`)}>
        {getPlanUpgradeRequestStatusIndicatorText(status)}
      </span>
    </div>
  );
}
