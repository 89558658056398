import { createSlice } from "@reduxjs/toolkit";
import ls from "../../common/ls";
import { Admin } from "../../types";
import { addCommonReducers } from "../../utils/store-utils";
import { updateLastSeenNotificationAction } from "../notifications/notifications.effects";
import { getMeAction } from "./auth.effects";


export const AUTH_STATE_NAME = 'auth';

export interface AuthState {
    me: Admin | null
    credentialsTemp: { username: string, password: string } | null
}

export const authState = createSlice({
    name: AUTH_STATE_NAME,
    initialState: {
        me: null,
        credentialsTemp: null,
        [getMeAction.pending.type]: true
    } as AuthState,
    reducers: {
        cancelGetMe(state) {
            state[getMeAction.pending.type] = false;
        },
        signIn2fa(state, { payload }) {
            state.credentialsTemp = payload;
        },
        signInSuccess(state, { payload }) {
            state.me = payload.user;
            ls.token = payload.accessToken;
            state.credentialsTemp = null;
        },
        twoFactorEnabledChanged(state, { payload }) {
            state.me.twoFactorEnabled = payload.twoFactorEnabled;
        },
        logout(state) {
            state.me = null;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getMeAction.fulfilled, (state, action) => {
                state.me = action.payload;
            })
            .addCase(updateLastSeenNotificationAction.fulfilled, (state, action) => {
                state.me.lastSeenNotificationId = action.meta.arg.id;
            })
        addCommonReducers(builder, AUTH_STATE_NAME);
    }
});

export const authActions = authState.actions;
export const authReducer = authState.reducer;

