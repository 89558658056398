import Carousel from "react-multi-carousel";
import useImagePreview from "../../hooks/use-image-preview";
import { UrlWithTitle } from "../../types";
import ImageUploaderWithTitle from "../ImagesUploaderWithTitle/ImageUploaderWithTitle";
import ImageUploader from "./ImageUploader";

const responsive = {
  doesntmatter: {
    breakpoint: { max: 3000, min: 0 },
    items: 3,
    partialVisibilityGutter: 18,
  },
};

interface IProps {
  images: (string | UrlWithTitle)[];
  hasTitle?: boolean;
  onImageRemove?: (index: number) => void;
  onFileUpload?: (file: File, params?: Record<string, any>) => Promise<any>;
}

export default function ImagesUploader({
  images,
  hasTitle,
  onFileUpload = () => Promise.resolve(true),
  onImageRemove = () => {},
}: IProps) {
  const openImagePreviewer = useImagePreview();

  return (
    <div className="w-full flex items-center">
      {images?.length ? (
        <Carousel
          partialVisbile
          responsive={responsive}
          partialVisible
          autoPlay={false}
          swipeable={false}
          draggable={false}
          containerClass="flex-1 mr-6"
          keyBoardControl={false}
          itemClass="p-2"
        >
          {images.map((image, index) => {
            const url = hasTitle
              ? (image as UrlWithTitle).url
              : (image as string);
            return (
              <ImageUploader
                key={url}
                url={url}
                onImageRemove={() => onImageRemove(index)}
                onPreviewClicked={() =>
                  openImagePreviewer(
                    hasTitle
                      ? (images as UrlWithTitle[]).map(({ url }) => url)
                      : (images as string[]),
                    index
                  )
                }
              />
            );
          })}
        </Carousel>
      ) : null}
      {hasTitle ? (
        <ImageUploaderWithTitle className="ml-2" onFileUpload={onFileUpload} />
      ) : (
        <ImageUploader className="ml-2" onFileUpload={onFileUpload} />
      )}
    </div>
  );
}
