import { yupResolver } from "@hookform/resolvers/yup";
import { AttachMoney, Percent } from "@mui/icons-material";
import { CircularProgress, InputAdornment, TextField } from "@mui/material";
import { useEffect, useRef } from "react";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import useFocus from "../../../../hooks/use-focus";
import { blockchainApi } from "../../../../http/blockchain.api";
import { useSmartDealForm } from "../../providers/SmartDealFormProvider";
import { ProformaInvoice } from "../../types";
import ContractCurrency from "../../../../components/ContractCurrency/ContractCurrency";
import { useSelector } from "react-redux";
import { selectBlockchainConfigs } from "../../../settings/settings.selectors";

interface FormProps {
  proformaInvoiceDate?: string;
  exporterCompanyName?: string;
  importerCompanyName?: string;
  exporterWalletAddress?: string;
  importerWalletAddress?: string;
  paymentTerms?: string;
  paymentTotal?: number;
  paymentPercentage?: number;
}

const validationSchema = Yup.object().shape({
  proformaInvoiceDate: Yup.string().required(),
  exporterCompanyName: Yup.string().required(),
  importerCompanyName: Yup.string().required(),
  fixedFee: Yup.number().min(0, "Can't be negative").required(),
  commissionFee: Yup.number().min(0, "Can't be negative").required(),
  paymentTotal: Yup.number().min(0, "Can't be negative").required(),
  paymentPercentage: Yup.number()
    .required()
    .min(0, "Can't be less than 0")
    .max(100, "Can't be more than 100"),
});

export default function SmartDealFormProformaInvoice({ children }) {
  const { value, next, setNexting } = useSmartDealForm();
  const configs = useSelector(selectBlockchainConfigs);
  const formOptions = {
    resolver: yupResolver(validationSchema),
    mode: "onSubmit",
  };
  const {
    register,
    watch,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm(formOptions as any);
  const proformaInvoiceDateRef = useRef();
  useFocus(proformaInvoiceDateRef);

  const onSubmit = async (formValue: FormProps) => {
    try {
      setNexting(true);
      const proformaInvoice =
        await blockchainApi.updateSmartDealProformaInvoice(
          value.smartDeal.id,
          formValue
        );
      next({ proformaInvoice });
    } finally {
      setNexting(false);
    }
  };

  const fromDto = (proformaInvoice: ProformaInvoice): FormProps => {
    return { ...proformaInvoice };
  };

  useEffect(() => {
    const setFormValue = async () => {
      reset(
        fromDto(
          value.proformaInvoice ??
            (await blockchainApi.getSmartDealProformaInvoice(
              value.smartDeal.id
            ))
        )
      );
    };

    setFormValue();
  }, [value]);

  return watch("id") ? (
    <form
      className="flex-1 w-full flex flex-col gap-4 mt-2"
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className="flex-1 flex flex-col gap-6 px-1">
        <span>RefID: {value.smartDeal.refId}</span>

        <TextField
          className="w-full"
          {...register("proformaInvoiceDate")}
          label="Proforma Invoice Date(YYYY-MM-DD)"
          error={!!errors?.proformaInvoiceDate}
          variant="filled"
          helperText={errors.proformaInvoiceDate?.message}
          required
          inputRef={proformaInvoiceDateRef}
        />

        <TextField
          className="w-full"
          {...register("exporterCompanyName")}
          label="Export Company Name"
          error={!!errors?.exporterCompanyName}
          variant="filled"
          helperText={errors.exporterCompanyName?.message}
          required
        />

        <TextField
          className="w-full"
          {...register("importerCompanyName")}
          label="Importer Company Name"
          error={!!errors?.importerCompanyName}
          variant="filled"
          helperText={errors.importerCompanyName?.message}
          required
        />

        <TextField
          className="w-full"
          {...register("fixedFee")}
          label="Fixed Fee"
          error={!!errors?.fixedFee}
          variant="filled"
          helperText={errors.fixedFee?.message}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <ContractCurrency
                  icon={
                    value?.proformaInvoice?.feeTokenIcon ??
                    configs?.feeTokenIcon
                  }
                />
              </InputAdornment>
            ),
          }}
          required
        />

        <TextField
          className="w-full"
          {...register("commissionFee")}
          label="Commission Fee"
          error={!!errors?.commissionFee}
          variant="filled"
          helperText={errors.commissionFee?.message}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <ContractCurrency
                  icon={
                    value?.proformaInvoice?.feeTokenIcon ??
                    configs?.feeTokenIcon
                  }
                />
              </InputAdornment>
            ),
          }}
          required
        />

        <TextField
          className="w-full"
          {...register("paymentTotal")}
          label="Total Amount"
          error={!!errors?.paymentTotal}
          variant="filled"
          helperText={errors.paymentTotal?.message}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <ContractCurrency
                  icon={
                    value?.proformaInvoice?.paymentTokenIcon ??
                    configs?.paymentTokenIcon
                  }
                />
              </InputAdornment>
            ),
          }}
          required
        />

        <TextField
          className="w-full"
          {...register("paymentPercentage")}
          label="Terms of Payment(percentage)"
          error={!!errors?.paymentPercentage}
          variant="filled"
          helperText={errors.paymentPercentage?.message}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Percent />
              </InputAdornment>
            ),
          }}
          required
        />

        <TextField
          className="w-full"
          {...register("paymentTerms")}
          label="Terms of Payment"
          error={!!errors?.paymentTerms}
          variant="filled"
          helperText={errors.paymentTerms?.message}
        />
      </div>
      {children}
    </form>
  ) : (
    <div className="mt-4 flex items-center justify-center">
      <CircularProgress />
    </div>
  );
}
