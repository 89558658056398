import { createAsyncThunk } from "@reduxjs/toolkit";
import { plansApi } from "../../http/plans.api";
import { AppPage, AppRefreshStatus, Notification, NotificationType } from "../../types";
import { appActions } from "../app/app.state";
import { notificationsActions } from "../notifications/notifications.state";
import { RootState } from "../store";
import listenerMiddleware from "../store/middlewares/listener.middleware";
import { PlansState } from "./plans.state";
import { PlanSubscriptionsFilters } from "./types/plan-subscriptions-filters.type";
import { PlanUpgradeRequestStatus } from "./types/plan-upgrade-request-status.type";

export const getPlansAction = createAsyncThunk(
    'plans/getPlans',
    () => {
        return plansApi.getPlans();
    }
)

export const getPlanSubscriptionsAction = createAsyncThunk(
    'plans/getSubscriptions',
    async ({ page, pageSize, filters }: { page?: number, pageSize?: number, filters?: Partial<PlanSubscriptionsFilters> }, thunk) => {
        try {
            thunk.dispatch(appActions.changeRefresh({
                status: AppRefreshStatus.InProgress,
                page: AppPage.Plans
            }));

            const plansState: PlansState = (<RootState>thunk.getState()).plans;
            page = page ?? plansState.pagination.page;
            pageSize = pageSize ?? plansState.pagination.pageSize;
            filters = {
                ...plansState.filters,
                ...(filters || {})
            };

            const paginatedPlanSubscriptions = await plansApi.getPlanSubscriptions({
                from: page * pageSize,
                take: pageSize,
                filters: JSON.stringify(filters)
            });
            return { paginatedPlanSubscriptions, page, pageSize, filters: <PlanSubscriptionsFilters>filters };
        } finally {
            thunk.dispatch(appActions.changeRefresh({
                status: AppRefreshStatus.Idle,
                page: AppPage.Plans
            }));
        }
    }
)

export const getPlanUpgradeRequestsAction = createAsyncThunk(
    'plans/getUpgradeRequests',
    () => {
        const filters = { status: PlanUpgradeRequestStatus.UnderReview };
        return plansApi.getPlanUpgradeRequests({
            filters: JSON.stringify(filters)
        });
    }
)

listenerMiddleware.startListening({
    actionCreator: notificationsActions.notificationReceived,
    effect: (action, listenerApi) => {
        if ((action.payload as Notification)?.type === NotificationType.PlanUpgradeRequestUnderReview) {
            listenerApi.dispatch(getPlanUpgradeRequestsAction());
        }
    }
})