import { useSelector } from "react-redux";
import { Notification as NotificationType } from "../../../types";
import NotificationWrapper from "./NotificationWrapper";
import { selectLastSeenNotification } from "../notifications.selectors";

interface Props {
  notifications: NotificationType[];
}

export default function Notifications({ notifications }: Props) {
  const lastSeenNotification = useSelector(selectLastSeenNotification);

  return (
    <div className="flex-1 mb-1 flex flex-col gap-2">
      {notifications.map((n) => (
        <NotificationWrapper
          key={n.id}
          notification={n}
          notSeen={n.createdAt > (lastSeenNotification?.createdAt || 0)}
        />
      ))}
    </div>
  );
}
