import {
  CircularProgress,
  Divider,
  Input,
  InputAdornment,
  MenuItem,
  Paper,
  Select,
  Switch,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { useLayoutEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useDebounce, useEffectOnce } from "usehooks-ts";
import * as Yup from "yup";
import ImagesUploader from "../../../components/ImagesUploader/ImagesUploader";
import NoRowsOverlay from "../../../components/NoRowsOverlay/NoRowsOverlay";
import useSkipFirstRenderEffect from "../../../hooks/use-skip-first-render-effect";
import { configsApi } from "../../../http/configs.api";
import {
  Configs,
  ConfigsKey,
  ImageResizeMode,
  ImageSize,
} from "../../../types";
import { AppPage } from "../../../types/app-page.type";
import { ConfigFileUploadAs } from "../../../types/config-file-upload-as.type";
import {
  ImageResizeModeDescription,
  ImageResizeModes,
} from "../../../utils/coimex-utils";
import { appActions } from "../../app/app.state";
import { updateConfigsAction } from "../settings.effects";
import {
  selectConfigs,
  selectIsGetConfigsPending,
} from "../settings.selectors";
import { settingsActions } from "../settings.state";
import "./Settings.scss";

const configsSchema = Yup.object({
  emailTokenExpiration: Yup.number()
    .required()
    .integer("The value is not a valid minutes.")
    .min(60, "Email token expiration time can't be less than 1 minute."),
  emailTokenResend: Yup.number()
    .required()
    .integer("The value is not a valid minutes.")
    .min(60, "Email token resend interval can't be less than 1 minute."),
  forgetPasswordTokenExpiration: Yup.number()
    .required()
    .integer("The value is not a valid minutes.")
    .min(
      60,
      "Forgot password token expiration time can't be less than 1 minute."
    ),
  forgetPasswordTokenResend: Yup.number()
    .required()
    .integer("The value is not a valid minutes.")
    .min(
      60,
      "Forgot password token resend interval can't be less than 1 minute."
    ),
  documentsMaxCount: Yup.number()
    .integer("Only integer is allowed")
    .min(1, "Documents max count can't be less than 1.")
    .max(20, "Documents max count can't be more than 20."),
  opportunityPhotosMaxCount: Yup.number()
    .integer("Only integer is allowed")
    .min(1, "Opportunity photos max count can't be less than 1.")
    .max(20, "Opportunity photos max count can't be more than 20."),
  opportunityExpiration: Yup.number()
    .required()
    .integer("The value is not a valid number of days.")
    .min(0, "Opportunity expiration can't be less than 0."),
  avatarMaxSize: Yup.number()
    .min(1e3, "Avatar image min file size can't be less than 1MB.")
    .max(10e3, "Avatar image max file size can't be larger than 10MB."),
  documentMaxSize: Yup.number()
    .min(1e3, "Document max file size can't be less than 1MB.")
    .max(10e3, "Document max file size can't be larger than 10MB."),
  sliderImageMaxSize: Yup.number()
    .min(1e3, "Slider image max file size can't be less than 1MB.")
    .max(10e3, "Slider image max file size can't be larger than 10MB."),
  opportunityPhotoMaxSize: Yup.number()
    .min(1e3, "Opportunity photo max file size can't be less than 1MB.")
    .max(10e3, "Opportunity photo max file size can't be larger than 10MB."),
});

export default function Settings() {
  const configs = useSelector(selectConfigs);
  const [tempConfigs, setTempConfigs] = useState(configs);
  const debouncedConfigs = useDebounce(tempConfigs, 2000);
  const isGetConfigsPending = useSelector(selectIsGetConfigsPending);
  const [errors, setErrors] = useState<any>(null);
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  const handleFileUpload = async (
    file: File,
    as: ConfigFileUploadAs,
    params?: Record<string, any>
  ) => {
    const response = await configsApi.upload(file, as, params);
    let configKey: keyof Configs;
    switch (as) {
      case ConfigFileUploadAs.SignUpPageImage:
        configKey = "signUpPageImageUrls";
        break;

      case ConfigFileUploadAs.HomePageImage:
        configKey = "homePageImages";
        break;
    }
    updateConfigs({
      [configKey]: [...(configs?.[configKey] || []), response],
    });
  };

  const updateConfigs = (update: Partial<Configs>) => {
    dispatch(settingsActions.updateConfigs(update));
  };

  const handleConfigsChange = async (name: string, value: any) => {
    patchTempConfigs({ [name]: value }, true);
  };

  useEffectOnce(() => {
    dispatch(appActions.changeRefresh({ page: AppPage.Settings }));
  });

  useSkipFirstRenderEffect(() => {
    if (debouncedConfigs) {
      if (
        Object.keys(debouncedConfigs).some(
          (key) => configs?.[key] !== debouncedConfigs[key]
        )
      ) {
        validateAndSubmit();
      } else {
        setErrors(null);
      }
    }
  }, [debouncedConfigs]);

  const validateAndSubmit = async () => {
    try {
      await configsSchema.validate(debouncedConfigs);
      setErrors(null);
      dispatch(updateConfigsAction(debouncedConfigs!));
    } catch (error: any) {
      setErrors({ [error.path]: true });
      if (error.errors?.[0]) {
        enqueueSnackbar(error.errors[0], { variant: "error" });
      }
    }
  };

  const patchTempConfigs = (
    value: Partial<Configs> | null,
    factor: boolean = false
  ) => {
    setTempConfigs((configs) =>
      value ? ({ ...(configs || {}), ...value } as Configs) : null
    );
  };

  useLayoutEffect(() => {
    patchTempConfigs(configs);
  }, [configs]);

  return !isGetConfigsPending ? (
    <div className="max-w-4xl mx-auto flex flex-col gap-4 p-3">
      {tempConfigs ? (
        <>
          <h2 className="settings__title">Authentication Token Settings</h2>
          <Paper elevation={3}>
            <div className="settings-item">
              <div className="settings-item__header">
                <h4 className="settings-item__title">
                  Email token expiration time
                </h4>
                <Input
                  className="text-sm"
                  name="emailTokenExpiration"
                  type="number"
                  endAdornment={
                    <InputAdornment position="end">min</InputAdornment>
                  }
                  style={{ width: "120px" }}
                  value={tempConfigs.emailTokenExpiration / 60 || 0}
                  error={errors?.emailTokenExpiration}
                  onChange={(e) =>
                    handleConfigsChange(
                      e.target.name,
                      Number(e.target.value) * 60
                    )
                  }
                />
              </div>
              <div className="settings-item__content">
                After the user signs-up a verification token is sent to his/her
                email to verify his/her email. This time specifies the duration
                in which the token is considered valid and after that it is
                expired and user should ask for another token.
              </div>
            </div>

            <Divider />

            <div className="settings-item">
              <div className="settings-item__header">
                <h4 className="settings-item__title">
                  Forgot password token expiration time
                </h4>
                <Input
                  className="text-sm"
                  name="forgetPasswordTokenExpiration"
                  type="number"
                  endAdornment={
                    <InputAdornment position="end">min</InputAdornment>
                  }
                  style={{ width: "120px" }}
                  value={tempConfigs.forgetPasswordTokenExpiration / 60 || 0}
                  error={errors?.forgetPasswordTokenExpiration}
                  onChange={(e) =>
                    handleConfigsChange(
                      e.target.name,
                      Number(e.target.value) * 60
                    )
                  }
                />
              </div>
              <div className="settings-item__content">
                If the user forgot his/her password and asks for password reset,
                a reset password token is sent to his/her email. This time
                specifies the duration in which the token is considered valid
                and after that it is expired and user should ask for another
                token.
              </div>
            </div>

            <Divider />

            <div className="settings-item">
              <div className="settings-item__header">
                <h4 className="settings-item__title">
                  Email token resend interval
                </h4>
                <Input
                  className="text-sm"
                  name="emailTokenResend"
                  type="number"
                  endAdornment={
                    <InputAdornment position="end">min</InputAdornment>
                  }
                  style={{ width: "120px" }}
                  value={tempConfigs.emailTokenResend / 60 || 0}
                  error={errors?.emailTokenResend}
                  onChange={(e) =>
                    handleConfigsChange(
                      e.target.name,
                      Number(e.target.value) * 60
                    )
                  }
                />
              </div>
              <div className="settings-item__content">
                The intervals in which the user can ask for a new email
                verification token.
              </div>
            </div>

            <Divider />

            <div className="settings-item">
              <div className="settings-item__header">
                <h4 className="settings-item__title">
                  Forgot password token resend interval
                </h4>
                <Input
                  className="text-sm"
                  name="forgetPasswordTokenResend"
                  type="number"
                  endAdornment={
                    <InputAdornment position="end">min</InputAdornment>
                  }
                  style={{ width: "120px" }}
                  value={tempConfigs.forgetPasswordTokenResend / 60 || 0}
                  error={errors?.forgetPasswordTokenResend}
                  onChange={(e) =>
                    handleConfigsChange(
                      e.target.name,
                      Number(e.target.value) * 60
                    )
                  }
                />
              </div>
              <div className="settings-item__content">
                The intervals in which the user can ask for a new reset password
                token.
              </div>
            </div>
          </Paper>

          <h2 className="settings__title mt-4">Opportunity Settings</h2>
          <Paper elevation={3}>
            <div className="settings-item">
              <div className="settings-item__header">
                <h4 className="settings-item__title">Opportunity expiration</h4>
                <Input
                  className="text-sm"
                  name="opportunityExpiration"
                  type="number"
                  endAdornment={
                    <InputAdornment position="end">day</InputAdornment>
                  }
                  style={{ width: "120px" }}
                  value={tempConfigs.opportunityExpiration || 0}
                  error={errors?.opportunityExpiration}
                  onChange={(e) =>
                    handleConfigsChange(e.target.name, Number(e.target.value))
                  }
                />
              </div>
              <div className="settings-item__content">
                This setting determines the number of days after the opportunity
                is published, it will be expired. The value of 0 means the
                opportunities don't get expired.
              </div>
            </div>

            <Divider />

            <div className="settings-item">
              <div className="settings-item__header">
                <h4 className="settings-item__title">
                  Filter opportunities base on interested countries
                </h4>
                <Switch
                  color="secondary"
                  size="small"
                  checked={
                    tempConfigs.filterOpportunitiesInInterestedCountries ||
                    false
                  }
                  onChange={(e) =>
                    handleConfigsChange(
                      "filterOpportunitiesInInterestedCountries",
                      e.target.checked
                    )
                  }
                ></Switch>
              </div>
              <div className="settings-item__content">
                If this setting is on, users can only see opportunities in their
                interested countries.
              </div>
            </div>

            <Divider />

            <div className="settings-item">
              <div className="settings-item__header">
                <h4 className="settings-item__title">
                  Filter opportunities base on interested categories
                </h4>
                <Switch
                  color="secondary"
                  size="small"
                  checked={
                    tempConfigs.filterOpportunitiesInInterestedCategories ||
                    false
                  }
                  onChange={(e) =>
                    handleConfigsChange(
                      "filterOpportunitiesInInterestedCategories",
                      e.target.checked
                    )
                  }
                ></Switch>
              </div>
              <div className="settings-item__content">
                If this setting is on, users can only see opportunities in their
                interested categories.
              </div>
            </div>
          </Paper>

          <h2 className="settings__title mt-4">Document Settings</h2>
          <Paper elevation={3}>
            <div className="settings-item">
              <div className="settings-item__header">
                <h4 className="settings-item__title">Documents max count</h4>
                <Input
                  className="text-sm"
                  name="documentsMaxCount"
                  type="number"
                  style={{ width: "120px" }}
                  value={tempConfigs.documentsMaxCount || 0}
                  error={errors?.documentsMaxCount}
                  onChange={(e) =>
                    handleConfigsChange(e.target.name, Number(e.target.value))
                  }
                />
              </div>
              <div className="settings-item__content">
                Maximum number of documents or certificates files a company can
                upload. the value of 0 bypass this check.
              </div>
            </div>

            <Divider />

            <div className="settings-item">
              <div className="settings-item__header">
                <h4 className="settings-item__title">
                  Opportunity photos max count
                </h4>
                <Input
                  className="text-sm"
                  name="opportunityPhotosMaxCount"
                  type="number"
                  style={{ width: "120px" }}
                  value={tempConfigs.opportunityPhotosMaxCount || 0}
                  error={errors?.opportunityPhotosMaxCount}
                  onChange={(e) =>
                    handleConfigsChange(e.target.name, Number(e.target.value))
                  }
                />
              </div>
              <div className="settings-item__content">
                Maximum number of uploaded photos for each opportunity. the
                value of 0 bypass this check.
              </div>
            </div>
          </Paper>

          <Paper elevation={3}>
            <div className="settings-item">
              <div className="settings-item__header">
                <h4 className="settings-item__title">
                  Avatar image max file size
                </h4>
                <Input
                  className="text-sm"
                  name="avatarMaxSize"
                  type="number"
                  endAdornment={
                    <InputAdornment position="end">MB</InputAdornment>
                  }
                  style={{ width: "120px" }}
                  value={tempConfigs.avatarMaxSize / 1000 || 0}
                  error={errors?.avatarMaxSize}
                  onChange={(e) =>
                    handleConfigsChange(
                      e.target.name,
                      Number(e.target.value) * 1000
                    )
                  }
                />
              </div>
              <div className="settings-item__content">
                Maximum allowed file size for company and user profile images.
                the value of 0 bypass this check.
              </div>
            </div>

            <Divider />

            <div className="settings-item">
              <div className="settings-item__header">
                <h4 className="settings-item__title">Document max file size</h4>
                <Input
                  className="text-sm"
                  name="documentMaxSize"
                  type="number"
                  endAdornment={
                    <InputAdornment position="end">MB</InputAdornment>
                  }
                  style={{ width: "120px" }}
                  value={tempConfigs.documentMaxSize / 1000 || 0}
                  error={errors?.documentMaxSize}
                  onChange={(e) =>
                    handleConfigsChange(
                      e.target.name,
                      Number(e.target.value) * 1000
                    )
                  }
                />
              </div>
              <div className="settings-item__content">
                Maximum allowed file size for documents and certificates images.
                the value of 0 bypass this check.
              </div>
            </div>

            <Divider />

            <div className="settings-item">
              <div className="settings-item__header">
                <h4 className="settings-item__title">
                  Opportunity photo max file size
                </h4>
                <Input
                  className="text-sm"
                  name="opportunityPhotoMaxSize"
                  type="number"
                  endAdornment={
                    <InputAdornment position="end">MB</InputAdornment>
                  }
                  style={{ width: "120px" }}
                  value={tempConfigs.opportunityPhotoMaxSize / 1000 || 0}
                  error={errors?.opportunityPhotoMaxSize}
                  onChange={(e) =>
                    handleConfigsChange(
                      e.target.name,
                      Number(e.target.value) * 1000
                    )
                  }
                />
              </div>
              <div className="settings-item__content">
                Maximum allowed file size for an opportunity photo. the value of
                0 bypass this check.
              </div>
            </div>

            <Divider />

            <div className="settings-item">
              <div className="settings-item__header">
                <h4 className="settings-item__title">
                  Slider image max file size
                </h4>
                <Input
                  className="text-sm"
                  name="sliderImageMaxSize"
                  type="number"
                  endAdornment={
                    <InputAdornment position="end">MB</InputAdornment>
                  }
                  style={{ width: "120px" }}
                  value={tempConfigs.sliderImageMaxSize / 1000 || 0}
                  error={errors?.sliderImageMaxSize}
                  onChange={(e) =>
                    handleConfigsChange(
                      e.target.name,
                      Number(e.target.value) * 1000
                    )
                  }
                />
              </div>
              <div className="settings-item__content">
                Maximum allowed file size for sign-up and home page slider
                images. the value of 0 bypass this check.
              </div>
            </div>

            <Divider />

            <div className="settings-item">
              <div className="settings-item__header">
                <h4 className="settings-item__title">
                  Message attachment max file size
                </h4>
                <Input
                  className="text-sm"
                  name="messageAttachmentMaxSize"
                  type="number"
                  endAdornment={
                    <InputAdornment position="end">MB</InputAdornment>
                  }
                  style={{ width: "120px" }}
                  value={tempConfigs.messageAttachmentMaxSize / 1000 || 0}
                  error={errors?.messageAttachmentMaxSize}
                  onChange={(e) =>
                    handleConfigsChange(
                      e.target.name,
                      Number(e.target.value) * 1000
                    )
                  }
                />
              </div>
              <div className="settings-item__content">
                Maximum allowed file size for message attachments. the value of
                0 bypass this check.
              </div>
            </div>
          </Paper>

          <h2 className="settings__title mt-4">Image Settings</h2>
          <Paper elevation={3}>
            <div className="settings-item">
              <div className="settings-item__header">
                <h4 className="settings-item__title">
                  Signup page slider images
                </h4>
              </div>
              <div className="settings-item__content mt-2 mr-2 max-w-full">
                <ImagesUploader
                  images={tempConfigs?.signUpPageImageUrls!}
                  onFileUpload={(file) =>
                    handleFileUpload(file, ConfigFileUploadAs.SignUpPageImage)
                  }
                  onImageRemove={(index: number) =>
                    handleConfigsChange(
                      "signUpPageImageUrls" as ConfigsKey,
                      configs?.signUpPageImageUrls?.filter(
                        (_, i) => i !== index
                      )
                    )
                  }
                />
              </div>
            </div>
          </Paper>

          <Paper elevation={3}>
            <div className="settings-item">
              <div className="settings-item__header">
                <h4 className="settings-item__title">
                  Home page slider images
                </h4>
              </div>
              <div className="settings-item__content mt-2 mr-2 max-w-full">
                <ImagesUploader
                  images={tempConfigs?.homePageImages!}
                  hasTitle
                  onFileUpload={(file, params) =>
                    handleFileUpload(
                      file,
                      ConfigFileUploadAs.HomePageImage,
                      params
                    )
                  }
                  onImageRemove={(index: number) =>
                    handleConfigsChange(
                      "homePageImages" as ConfigsKey,
                      configs?.homePageImages?.filter((_, i) => i !== index)
                    )
                  }
                />
              </div>
            </div>
          </Paper>

          <Paper elevation={3}>
            <div className="settings-item">
              <div className="settings-item__header">
                <h4 className="settings-item__title">Image resize mode</h4>
                <Select
                  name="imageResizeMode"
                  style={{ width: "120px" }}
                  variant="standard"
                  value={tempConfigs.imageResizeMode || ImageResizeMode.Fill}
                  error={errors?.imageResizeMode}
                  onChange={(e) =>
                    handleConfigsChange(e.target.name, e.target.value)
                  }
                >
                  {ImageResizeModes.map((mode) => (
                    <MenuItem key={mode} value={mode}>
                      {mode.toTitleCase()}
                    </MenuItem>
                  ))}
                </Select>
              </div>
              <div className="settings-item__content">
                {
                  ImageResizeModeDescription[
                    tempConfigs.imageResizeMode || ImageResizeMode.Fill
                  ]
                }
              </div>
            </div>

            <Divider />

            <div className="settings-item">
              <div className="settings-item__header">
                <h4 className="settings-item__title">
                  User profile image size
                </h4>
                <div className="flex items-end">
                  <Input
                    className="text-sm"
                    name="userAvatarSize"
                    type="number"
                    endAdornment={
                      <InputAdornment position="end">px</InputAdornment>
                    }
                    style={{ width: "80px" }}
                    value={tempConfigs?.userAvatarSize?.width || 0}
                    error={errors?.userAvatarSize}
                    onChange={(e) =>
                      handleConfigsChange(e.target.name, {
                        width: Number(e.target.value),
                        height: tempConfigs?.userAvatarSize?.height || 0,
                      } as ImageSize)
                    }
                  />
                  <span className="mx-4 -mb-2">x</span>
                  <Input
                    className="text-sm"
                    name="userAvatarSize"
                    type="number"
                    endAdornment={
                      <InputAdornment position="end">px</InputAdornment>
                    }
                    style={{ width: "80px" }}
                    value={tempConfigs?.userAvatarSize?.height || 0}
                    error={errors?.userAvatarSize}
                    onChange={(e) =>
                      handleConfigsChange(e.target.name, {
                        width: tempConfigs?.userAvatarSize?.width || 0,
                        height: Number(e.target.value),
                      } as ImageSize)
                    }
                  />
                </div>
              </div>
              <div className="settings-item__content">
                The final dimensions of user's profile image. the value of 0
                doesn't crop the image.
              </div>
            </div>

            <Divider />

            <div className="settings-item">
              <div className="settings-item__header">
                <h4 className="settings-item__title">
                  Company profile image size
                </h4>
                <div className="flex items-end">
                  <Input
                    className="text-sm"
                    name="companyAvatarSize"
                    type="number"
                    endAdornment={
                      <InputAdornment position="end">px</InputAdornment>
                    }
                    style={{ width: "80px" }}
                    value={tempConfigs?.companyAvatarSize?.width || 0}
                    error={errors?.companyAvatarSize}
                    onChange={(e) =>
                      handleConfigsChange(e.target.name, {
                        width: Number(e.target.value),
                        height: tempConfigs?.companyAvatarSize?.height || 0,
                      } as ImageSize)
                    }
                  />
                  <span className="mx-4 -mb-2">x</span>
                  <Input
                    className="text-sm"
                    name="companyAvatarSize"
                    type="number"
                    endAdornment={
                      <InputAdornment position="end">px</InputAdornment>
                    }
                    style={{ width: "80px" }}
                    value={tempConfigs?.companyAvatarSize?.height || 0}
                    error={errors?.companyAvatarSize}
                    onChange={(e) =>
                      handleConfigsChange(e.target.name, {
                        width: tempConfigs?.companyAvatarSize?.width || 0,
                        height: Number(e.target.value),
                      } as ImageSize)
                    }
                  />
                </div>
              </div>
              <div className="settings-item__content">
                The final dimensions of company's profile image. the value of 0
                doesn't crop the image.
              </div>
            </div>

            <Divider />

            <div className="settings-item">
              <div className="settings-item__header">
                <h4 className="settings-item__title">
                  Signup page slider image size
                </h4>
                <div className="flex items-end">
                  <Input
                    className="text-sm"
                    name="signUpSliderImageSize"
                    type="number"
                    endAdornment={
                      <InputAdornment position="end">px</InputAdornment>
                    }
                    style={{ width: "80px" }}
                    value={tempConfigs?.signUpSliderImageSize?.width || 0}
                    error={errors?.signUpSliderImageSize}
                    onChange={(e) =>
                      handleConfigsChange(e.target.name, {
                        width: Number(e.target.value),
                        height: tempConfigs?.signUpSliderImageSize?.height || 0,
                      } as ImageSize)
                    }
                  />
                  <span className="mx-4 -mb-2">x</span>
                  <Input
                    className="text-sm"
                    name="signUpSliderImageSize"
                    type="number"
                    endAdornment={
                      <InputAdornment position="end">px</InputAdornment>
                    }
                    style={{ width: "80px" }}
                    value={tempConfigs?.signUpSliderImageSize?.height || 0}
                    error={errors?.signUpSliderImageSize}
                    onChange={(e) =>
                      handleConfigsChange(e.target.name, {
                        width: tempConfigs?.signUpSliderImageSize?.width || 0,
                        height: Number(e.target.value),
                      } as ImageSize)
                    }
                  />
                </div>
              </div>
              <div className="settings-item__content">
                The final dimensions of signup page slider images. the value of
                0 doesn't crop the image.
              </div>
            </div>

            <Divider />

            <div className="settings-item">
              <div className="settings-item__header">
                <h4 className="settings-item__title">
                  Home page slider image size
                </h4>
                <div className="flex items-end">
                  <Input
                    className="text-sm"
                    name="homeSliderImageSize"
                    type="number"
                    endAdornment={
                      <InputAdornment position="end">px</InputAdornment>
                    }
                    style={{ width: "80px" }}
                    value={tempConfigs?.homeSliderImageSize?.width || 0}
                    error={errors?.homeSliderImageSize}
                    onChange={(e) =>
                      handleConfigsChange(e.target.name, {
                        width: Number(e.target.value),
                        height: tempConfigs?.homeSliderImageSize?.height || 0,
                      } as ImageSize)
                    }
                  />
                  <span className="mx-4 -mb-2">x</span>
                  <Input
                    className="text-sm"
                    name="homeSliderImageSize"
                    type="number"
                    endAdornment={
                      <InputAdornment position="end">px</InputAdornment>
                    }
                    style={{ width: "80px" }}
                    value={tempConfigs?.homeSliderImageSize?.height || 0}
                    error={errors?.homeSliderImageSize}
                    onChange={(e) =>
                      handleConfigsChange(e.target.name, {
                        width: tempConfigs?.homeSliderImageSize?.width || 0,
                        height: Number(e.target.value),
                      } as ImageSize)
                    }
                  />
                </div>
              </div>
              <div className="settings-item__content">
                The final dimensions of home page slider images. the value of 0
                doesn't crop the image.
              </div>
            </div>

            <Divider />

            <div className="settings-item">
              <div className="settings-item__header">
                <h4 className="settings-item__title">Opportunity Photo Size</h4>
                <div className="flex items-end">
                  <Input
                    className="text-sm"
                    name="opportunityPhotoSize"
                    type="number"
                    endAdornment={
                      <InputAdornment position="end">px</InputAdornment>
                    }
                    style={{ width: "80px" }}
                    value={tempConfigs?.opportunityPhotoSize?.width || 0}
                    error={errors?.opportunityPhotoSize}
                    onChange={(e) =>
                      handleConfigsChange(e.target.name, {
                        width: Number(e.target.value),
                        height: tempConfigs?.opportunityPhotoSize?.height || 0,
                      } as ImageSize)
                    }
                  />
                  <span className="mx-4 -mb-2">x</span>
                  <Input
                    className="text-sm"
                    name="opportunityPhotoSize"
                    type="number"
                    endAdornment={
                      <InputAdornment position="end">px</InputAdornment>
                    }
                    style={{ width: "80px" }}
                    value={tempConfigs?.opportunityPhotoSize?.height || 0}
                    error={errors?.opportunityPhotoSize}
                    onChange={(e) =>
                      handleConfigsChange(e.target.name, {
                        width: tempConfigs?.opportunityPhotoSize?.width || 0,
                        height: Number(e.target.value),
                      } as ImageSize)
                    }
                  />
                </div>
              </div>
              <div className="settings-item__content">
                The final dimensions of opportunity photo. the value of 0
                doesn't crop the photo.
              </div>
            </div>
          </Paper>
        </>
      ) : (
        <NoRowsOverlay emptyMessage="No Configs" />
      )}
    </div>
  ) : (
    <div className="absolute left-0 right-0 top-32 flex justify-center">
      <CircularProgress color="primary" size="32px" />
    </div>
  );
}
