import { Link } from "react-router-dom";
import { Company } from "../../../../types";
import { NotificationTemplateProps } from "../../types/notification-template.type";
import { SmartDeal } from "../../../blockchain/types";

export default function PromptPayRequestUnderReviewNotification({
  payload,
}: NotificationTemplateProps<{
  company: Partial<Company>;
  smartDeal: Partial<SmartDeal>;
}>) {
  return (
    <div>
      <Link
        className="link"
        to={`/companies/${payload?.company?.id}`}
        onClick={(e) => e.stopPropagation()}
      >
        {payload?.company?.name}
      </Link>{" "}
      requested for Prompt Pay service for smart deal{" "}
      <Link
        className="link"
        to={`/smart-deals/${payload?.smartDeal?.id}`}
        onClick={(e) => e.stopPropagation()}
      >
        {payload?.smartDeal?.refId}
      </Link>
      .
    </div>
  );
}
