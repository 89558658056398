import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import useBottomSheet from "../../../hooks/use-bottom-sheet";
import { selectIsAuthenticated } from "../../auth/auth.selectors";
import "./InvestPools.scss";
import InvestPoolsFilters, {
  InvestPoolsFiltersTab,
} from "./InvestPoolsFilters";
import PromptPayPools from "./PromptPayPools";
import PromptPayPoolPage from "./PromptPoolPage";

export default function InvestPools() {
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const setBottomSheet = useBottomSheet();
  const params = useParams();
  const navigate = useNavigate();
  const selectedTab = params.tab ?? InvestPoolsFiltersTab.PromptPayPools;

  const showInvestPoolPage = (tab: InvestPoolsFiltersTab, id: string) => {
    const Component =
      tab == InvestPoolsFiltersTab.PromptPayPools ? PromptPayPoolPage : null;
    setBottomSheet({
      component: <Component id={id} onClose={handleInvestPoolPageClose} />,
      onClose: handleInvestPoolPageClose,
    });
  };

  const handleInvestPoolPageClose = () => {
    setBottomSheet(null);
    navigate(-1);
  };

  useEffect(() => {
    const { tab, id } = params;
    id && isAuthenticated
      ? showInvestPoolPage(tab as any, id)
      : setBottomSheet(null);
  }, [params, isAuthenticated]);

  return (
    <div className="w-constraint">
      <InvestPoolsFilters />
      {selectedTab == InvestPoolsFiltersTab.PromptPayPools ? (
        <PromptPayPools />
      ) : null}
    </div>
  );
}
