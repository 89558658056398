import api from ".";
import {
  BlockchainTx,
  ProformaInvoice,
  SmartDeal,
  SmartDealDocument,
  SmartDealDocumentApproveTx,
  SmartDealPayment,
  SmartDealPaymentAction,
  SmartDealPaymentActionType,
  SmartDealPaymentBlockchainInfo,
} from "../modules/blockchain/types";
import SmartDealPromptPayApproveRequest from "../modules/blockchain/types/smart-deal-prompt-pay-approve-request.type";
import { SmartDealsStatusFilter } from "../modules/blockchain/types/smart-deals-status-filter.type";
import { Address, Erc20TokenInfo, Paginated, PaginationQuery, SmartDealPromptPay } from "../types";

export const blockchainApi = {
  getSmartDeal: async (id: SmartDeal["id"]): Promise<SmartDeal> => {
    return (await api.get(`/blockchain/smart-deals/${id}`)).data;
  },
  getSmartDeals: (params?: PaginationQuery): Promise<Paginated<SmartDeal>> => {
    return api.get("/blockchain/smart-deals", { params });
  },
  getSmartDealsStatusCounts: async (): Promise<
    Record<SmartDealsStatusFilter, number>
  > => {
    return (await api.get("/blockchain/smart-deals/statuses")).data;
  },
  createSmartDeal: async (dto): Promise<SmartDeal> => {
    return (await api.post("/blockchain/smart-deals", dto)).data;
  },
  getSmartDealProformaInvoice: async (
    id: SmartDeal["id"]
  ): Promise<ProformaInvoice> => {
    return (await api.get(`/blockchain/smart-deals/${id}/proforma-invoice`))
      .data;
  },
  updateSmartDealProformaInvoice: async (
    id: SmartDeal["id"],
    dto
  ): Promise<ProformaInvoice> => {
    return (
      await api.patch(`/blockchain/smart-deals/${id}/proforma-invoice`, dto)
    ).data;
  },
  publishSmartDealToBlockchain: async (
    id: SmartDeal["id"]
  ): Promise<SmartDeal> => {
    return (
      await api.put(`/blockchain/smart-deals/${id}/publish-to-blockchain`)
    ).data;
  },
  getSmartDealDocuments: async (
    id: SmartDeal["id"]
  ): Promise<SmartDealDocument[]> => {
    return (await api.get(`/blockchain/smart-deals/${id}/documents`)).data;
  },
  getSmartDealDocument: async (
    id: SmartDealDocument["id"]
  ): Promise<SmartDealDocument> => {
    return (await api.get(`/blockchain/smart-deal-documents/${id}`)).data;
  },
  getSmartDealDocumentApproveTxs: async (
    id: SmartDealDocument["id"]
  ): Promise<SmartDealDocumentApproveTx[]> => {
    return (
      await api.get(`/blockchain/smart-deal-documents/${id}/approve-doc-txs`)
    ).data;
  },
  enableSmartDealApproveDocuments: async (
    id: SmartDeal["id"]
  ): Promise<SmartDeal> => {
    return (
      await api.put(`/blockchain/smart-deals/${id}/documents/enable-approve`)
    ).data;
  },
  getSmartDealPayment: async (
    id: SmartDeal["id"]
  ): Promise<SmartDealPayment> => {
    return (await api.get(`/blockchain/smart-deals/${id}/payment`)).data;
  },
  createSmartDealPayment: async (
    id: SmartDeal["id"],
    dto
  ): Promise<SmartDealPayment> => {
    return (await api.post(`/blockchain/smart-deals/${id}/payment`, dto)).data;
  },
  getSmartDealPaymentBlockchainInfo: async (
    id: SmartDeal["id"]
  ): Promise<SmartDealPaymentBlockchainInfo> => {
    return (
      await api.get(`/blockchain/smart-deals/${id}/payment/blockchain-info`)
    ).data;
  },
  executeSmartDealPaymentAction: async (
    id: SmartDeal["id"],
    type: SmartDealPaymentActionType
  ): Promise<SmartDealPaymentAction> => {
    return (
      await api.post(`/blockchain/smart-deals/${id}/payment/actions/${type}`)
    ).data;
  },
  getBlockchainTx: async (
    txHash: BlockchainTx["txHash"]
  ): Promise<BlockchainTx> => {
    return (await api.get(`/blockchain/txs/${txHash}`)).data;
  },
  getErc20TokenInfo: async (token: Address): Promise<Erc20TokenInfo> => {
    return (await api.get(`/blockchain/tokens/${token}`)).data;
  },
  approveSmartDealPromptPay: async (id: SmartDeal['id'], model: SmartDealPromptPayApproveRequest): Promise<SmartDealPromptPay> => {
    return (await api.patch(`/blockchain/smart-deals/${id}/prompt-pay/approve`, model)).data;
  },
  rejectSmartDealPromptPay: async (id: SmartDeal['id'], model: { remarks?: string }): Promise<SmartDealPromptPay> => {
    return (await api.patch(`/blockchain/smart-deals/${id}/prompt-pay/reject`, model)).data;
  },
};
