import { createSlice } from "@reduxjs/toolkit";
import { CommodityToken } from "../../types";
import { addCommonReducers } from "../../utils/store-utils";
import { getCommodityTokensAction } from "./commodity-tokens.effects";

export const COMMODITY_TOKENS_STATE_NAME = "commodityTokens";

export interface CommodityTokensState {
    commodityTokens: CommodityToken[];
}

export const commodityTokensState = createSlice({
    name: COMMODITY_TOKENS_STATE_NAME,
    initialState: {
        commodityTokens: [],
        [getCommodityTokensAction.pending.type]: true,
    } as CommodityTokensState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getCommodityTokensAction.fulfilled, (state, action) => {
                state.commodityTokens = action.payload;
            })
        addCommonReducers(builder, COMMODITY_TOKENS_STATE_NAME);
    },
});

export const commodityTokensActions = commodityTokensState.actions;
export const commodityTokensReducer = commodityTokensState.reducer;
