import { createSelector } from "@reduxjs/toolkit";
import { InvestPoolType } from "../../types";
import { RootState } from "../store";
import { getInvestPoolsAction } from "./invest.effects";

export const selectInvestState = (state: RootState) => state.invest;

export const selectInvestPools = (type: InvestPoolType) => createSelector(
    selectInvestState,
    state => state[type].investPools || []
)

export const selectIsGetInvestPoolsPending = createSelector(
    selectInvestState,
    state => state[getInvestPoolsAction.pending.type]
)

export const selectInvestPoolsPagination = (type: InvestPoolType) => createSelector(
    selectInvestState,
    state => state[type].pagination
)