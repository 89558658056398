import { Business } from "@mui/icons-material";
import { Avatar } from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import NoRowsOverlay from "../../../components/NoRowsOverlay/NoRowsOverlay";
import { useModal } from "../../../providers/ModalProvider";
import {
  getPlanSubscriptionsAction,
  getPlanUpgradeRequestsAction,
} from "../plans.effects";
import {
  selectGetPlanUpgradeRequests,
  selectIsGetPlanUpgradeRequestsPending,
} from "../plans.selectors";
import { PlanUpgradeRequest } from "../types/plan-upgrade-request.type";
import PlanUpgradeForm from "./PlanUpgradeForm";
import PlanUpgradeRequestStatusChip from "./PlanUpgradeRequestStatusChip";

export default function PlanUpgradeRequests() {
  const requests = useSelector(selectGetPlanUpgradeRequests);
  const isGetRequestsPending = useSelector(
    selectIsGetPlanUpgradeRequestsPending
  );
  const [rows, setRows] = useState([]);
  const openModal = useModal();
  const dispatch = useDispatch();

  const handleRowClick = async (request: PlanUpgradeRequest) => {
    if (
      await openModal((props) => <PlanUpgradeForm id={request.id} {...props} />)
    ) {
      dispatch(getPlanSubscriptionsAction({ page: 0 }));
      dispatch(getPlanUpgradeRequestsAction());
    }
  };

  useEffect(() => {
    setRows(
      requests?.map((request, index) => ({
        ...request,
        rowNo: index + 1,
      }))
    );
  }, [requests]);

  return (
    <div className="w-full max-w-lg mt-4">
      <DataGrid
        columns={cols}
        rows={rows}
        disableColumnMenu
        disableSelectionOnClick
        style={{ minHeight: "300px", height: "calc(100vh - 200px)" }}
        loading={isGetRequestsPending}
        autoPageSize
        components={{
          NoRowsOverlay: () => (
            <NoRowsOverlay emptyMessage="No Upgrade Plan Requests" />
          ),
        }}
        getRowClassName={(params) => "cursor-pointer"}
        onRowClick={({ row }) => handleRowClick(row)}
      />
    </div>
  );
}

const cols: GridColDef[] = [
  {
    field: "rowNo",
    headerName: "",
    width: 50,
    sortable: false,
  },
  {
    field: "company",
    headerName: "Company",
    renderCell: ({ value }) => (
      <div className="flex items-center gap-x-2">
        <Avatar src={value.avatarUrl} sx={{ width: 28, height: 28 }}>
          <Business fontSize="small" />
        </Avatar>
        <span>{value.name}</span>
      </div>
    ),
    sortable: false,
    flex: 1,
  },
  {
    field: "status",
    headerName: "Request Status",
    renderCell: ({ value }) => <PlanUpgradeRequestStatusChip status={value} />,
    sortable: false,
    flex: 1,
  },
  {
    field: "createdAt",
    headerName: "Requested At",
    valueFormatter: (params) => moment(params.value).fromNow(),
    sortable: false,
    width: 120,
  },
];
