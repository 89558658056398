import { Chip } from "@mui/material";
import { getSmartDealStateColor } from "../../utils/coimex-utils";
import { SmartDealState } from "../../modules/blockchain/types";

interface IProps {
  state: SmartDealState;
}

export default function SmartDealStateChip({ state }: IProps) {
  return (
    <Chip label={state} color={getSmartDealStateColor(state)} size="small" />
  );
}
