import { ChevronRight } from "@mui/icons-material";
import { useSelector } from "react-redux";
import LoadingOverlay from "../../../components/LoadingOverlay/LoadingOverlay";
import { getFindOutWhyText } from "../../../utils/coimex-utils";
import {
  selectFindOutWhy,
  selectIsGetFindOutWhyPending,
} from "../dashboard.selectors";
import { FindOutWhyType } from "../types/find-out-why.type";
import FindOutWhyDetails from "./FindOutWhyDetails";
import { useModal } from "../../../providers/ModalProvider";

export default function FindOutWhy() {
  const findOutWhy = useSelector(selectFindOutWhy);
  const isPending = useSelector(selectIsGetFindOutWhyPending);
  const openModal = useModal();

  const handleSeeDetails = () => {
    openModal((props) => (
      <FindOutWhyDetails findOutWhy={findOutWhy} {...props} />
    ));
  };

  return (
    <LoadingOverlay loading={isPending}>
      <div className="w-full h-full flex flex-col">
        <div className="flex-1 flex flex-col p-widget">
          <div className="widget-header flex justify-between items-center">
            Find out why
          </div>
          <div className="mt-0.5 flex-1 flex flex-col gap-2">
            {findOutWhy &&
              Object.entries(findOutWhy).map(([type, count]) => (
                <div
                  key={type}
                  className="px-2 py-3 flex items-center gap-1 border border-solid rounded-md"
                >
                  <span className="flex-1 text-smm">
                    {getFindOutWhyText(type as FindOutWhyType)}
                  </span>
                  <span className="w-11 text-right text-3xl">{count}</span>
                </div>
              ))}
          </div>
        </div>
        <div className="widget-footer flex items-center justify-end">
          {findOutWhy && (
            <div
              className="flex items-center text-smm text-secondary cursor-pointer"
              onClick={handleSeeDetails}
            >
              See details
              <ChevronRight className="text-xl" />
            </div>
          )}
        </div>
      </div>
    </LoadingOverlay>
  );
}
