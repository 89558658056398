import { Check, Close, Star } from "@mui/icons-material";
import { CircularProgress, Paper } from "@mui/material";
import classNames from "classnames";
import moment from "moment";
import { useEffect, useState } from "react";
import NumberFormat from "react-number-format";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import CurrencyFormat from "../../../components/CurrencyFormat/CurrencyFormat";
import NoRowsOverlay from "../../../components/NoRowsOverlay/NoRowsOverlay";
import { paymentsApi } from "../../../http";
import { Payment, TransactionStatus } from "../../../types";
import {
  getCurrencySymbol,
  getPaymentsTotal,
} from "../../../utils/coimex-utils";

interface IProps {
  companyId: string;
  joinedAt: number;
}

export default function CompanyPayments({ companyId, joinedAt }: IProps) {
  const [payments, setPayments] = useState<Payment[]>([]);
  const [isGetPaymentsPending, setIsGetPaymentsPending] = useState(true);

  const getPayments = async () => {
    try {
      setIsGetPaymentsPending(true);
      setPayments(await paymentsApi.getCompanyPayments(companyId));
    } finally {
      setIsGetPaymentsPending(false);
    }
  };

  const renderSucceeded = (payment: Payment) => (
    <VerticalTimelineElement
      key={payment.id}
      className="vertical-timeline-element--work"
      contentStyle={{ background: "var(--color-bg-1)" }}
      contentArrowStyle={{ borderRight: "7px solid  var(--color-bg-1)" }}
      date={moment(payment.timestamp).format("D MMMM YYYY")}
      iconStyle={{
        background: "var(--color-green-light)",
        color: "#fff",
      }}
      icon={<Check />}
    >
      <h3 className="vertical-timeline-element-title">{payment.txId}</h3>
      <h4 className="vertical-timeline-element-subtitle text-link">
        <NumberFormat
          value={payment.total}
          thousandSeparator={true}
          prefix={getCurrencySymbol(payment.currency)}
          displayType="text"
        />
      </h4>
      <p>
        {payment.planType}, {payment.planDuration}, {payment.planPurposeOfUse}
      </p>
    </VerticalTimelineElement>
  );

  const renderFailed = (payment: Payment) => (
    <VerticalTimelineElement
      key={payment.id}
      className="vertical-timeline-element--work"
      contentStyle={{ background: "#c0c0c0" }}
      contentArrowStyle={{ borderRight: "7px solid  #c0c0c0" }}
      date={moment(payment.timestamp).format("D MMMM YYYY")}
      iconStyle={{
        background: "#c0c0c0",
        color: "#fff",
      }}
      icon={<Close />}
    >
      <h3 className="vertical-timeline-element-title">{payment.txId}</h3>
      <h4 className="vertical-timeline-element-subtitle text-gray-600">
        <NumberFormat
          value={payment.total}
          thousandSeparator={true}
          prefix={getCurrencySymbol(payment.currency)}
          displayType="text"
        />
      </h4>
      <p>
        {payment.planType}, {payment.planDuration}, {payment.planPurposeOfUse}
      </p>
      {payment.failedStage ? (
        <p className="text-sm font-normal">@{payment.failedStage}</p>
      ) : (
        <></>
      )}
      {payment.additionalLog ? (
        <p className="text-sm font-normal">{payment.additionalLog}</p>
      ) : (
        <></>
      )}
    </VerticalTimelineElement>
  );

  useEffect(() => {
    getPayments();
  }, [companyId]);

  return !isGetPaymentsPending ? (
    <div
      className="max-w-5xl mx-auto flex flex-col overflow-auto"
      style={{ maxHeight: "calc(100vh - 200px)" }}
    >
      <span>
        TOTAL: <CurrencyFormat amount={getPaymentsTotal(payments)} symbol="$" />
      </span>
      <Paper
        className={classNames(
          "flex flex-col gap-y-6 px-0 card-border overflow-x-hidden",
          { "py-12": !payments.length },
          { "py-2": payments.length }
        )}
        elevation={0}
      >
        {payments?.length ? (
          <VerticalTimeline lineColor="#fbfbfb">
            {payments.map((payment) =>
              payment.status === TransactionStatus.Succeeded
                ? renderSucceeded(payment)
                : renderFailed(payment)
            )}
            <VerticalTimelineElement
              date={`Joined at ${moment(joinedAt).format("D MMMM YYYY")}`}
              iconStyle={{
                background: "var(--color-bg-1)",
                color: "var(--color-primary)",
              }}
              icon={<Star />}
            />
          </VerticalTimeline>
        ) : (
          <NoRowsOverlay emptyMessage="No Payments" />
        )}
      </Paper>
    </div>
  ) : (
    <div className="absolute left-0 right-0 top-48 flex justify-center">
      <CircularProgress color="primary" size="32px" />
    </div>
  );
}
