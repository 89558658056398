import { CurrencyBitcoinOutlined } from "@mui/icons-material";

export default function InvestmentPoolSvg() {
  return (
    <div className="relative">
      <svg
        width="24"
        height="32"
        viewBox="0 0 1024 1024"
        fill="rgba(0,0,0,0.5)"
        stroke="rgba(0,0,0,0.5)"
      >
        <g>
          <path d="M878.15 394.64l-68.39 446.5h-595.5l-68.39-446.5-72.29 11.07 77.89 508.58h721.07l77.9-508.58z"></path>
          <path d="M348.2 637.96c45.07 0 118.89 42.52 142.91 58.46 10.54 7.33 105.08 71.58 181.4 71.58 79.89 0 150.18-67.98 157.93-75.73l-51.66-51.77c-15.27 15.11-64.11 54.36-106.27 54.36-45.38 0-116.18-42.21-140.21-58.88-10.93-7.29-108.91-71.16-184.09-71.16-79.57 0-147.64 68.45-155.14 76.25l52.75 50.68c13.4-13.95 60.99-53.79 102.38-53.79zM512 548.57c121.19 0 219.43-98.24"></path>
        </g>
      </svg>
      <CurrencyBitcoinOutlined
        className="absolute top-[3px] left-1/2 w-4 h-4 -translate-x-1/2"
        style={{ fill: "rgba(0,0,0,0.6)" }}
      />
    </div>
  );
}
