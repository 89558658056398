import { decorateProperty, getDecoratedProps } from "../../utils/decorator-utils";

const SEARCH_HIT_KEY = 'SEARCH_HIT';

export const SearchHit = (): any => (classType: any, propertyName: string) => {
    decorateProperty(classType, propertyName, SEARCH_HIT_KEY);
}

export const getSearchHitDecoratedProps = (classType: any): string[] => {
    return getDecoratedProps(classType, SEARCH_HIT_KEY);
}