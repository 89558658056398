import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { getCompaniesCountryDistributionAction, getCurrentPrecedingStatisticsAction, getFindOutWhyAction, getOpportunitiesStatisticsAction, getPlanSubscriptionStatisticsAction, getTopExportersAction, getTopImportersAction } from "./dashboard.effects";

export const selectDashboardState = (state: RootState) => state.dashboard;

export const selectCompaniesCountryDistribution = createSelector(
    selectDashboardState,
    state => (state.companiesCountryDistribution || []).sortBy('companiesCount', 'des')
)

export const selectIsGetCompaniesCountriesDistributionPending = createSelector(
    selectDashboardState,
    state => state[getCompaniesCountryDistributionAction.pending.type]
)

export const selectCurrentPreceding = createSelector(
    selectDashboardState,
    state => state.currentPreceding
)

export const selectIsGetCurrentPrecedingStatisticsPending = createSelector(
    selectDashboardState,
    state => state[getCurrentPrecedingStatisticsAction.pending.type]
)

export const selectCurrentPrecedingStatistics = createSelector(
    selectCurrentPreceding,
    cp => cp.statistics || []
)

export const selectCurrentPrecedingWindow = createSelector(
    selectCurrentPreceding,
    cp => cp.window
)

export const selectPlanSubscription = createSelector(
    selectDashboardState,
    state => state.planSubscription
)

export const selectIsGetPlanSubscriptionStatisticsPending = createSelector(
    selectDashboardState,
    state => state[getPlanSubscriptionStatisticsAction.pending.type]
)

export const selectPlanSubscriptionStatistics = createSelector(
    selectPlanSubscription,
    ps => ps.statistics || []
)

export const selectPlanSubscriptionWindow = createSelector(
    selectPlanSubscription,
    ps => ps.window
)

export const selectDashboardOpportunities = createSelector(
    selectDashboardState,
    state => state.opportunities
)

export const selectOpportunitiesStatistics = createSelector(
    selectDashboardOpportunities,
    opps => opps.statistics
)

export const selectIsGetOpportunitiesStatisticsPending = createSelector(
    selectDashboardState,
    state => state[getOpportunitiesStatisticsAction.pending.type]
)

export const selectOpportunitiesWindow = createSelector(
    selectDashboardOpportunities,
    opps => opps.window
)

export const selectFindOutWhy = createSelector(
    selectDashboardState,
    state => state.findOutWhy
)

export const selectIsGetFindOutWhyPending = createSelector(
    selectDashboardState,
    state => state[getFindOutWhyAction.pending.type]
)

export const selectTopImporters = createSelector(
    selectDashboardState,
    state => state.topImporters
)

export const selectIsGetTopImportersPending = createSelector(
    selectDashboardState,
    state => state[getTopImportersAction.pending.type]
)

export const selectTopImportersStatistics = createSelector(
    selectTopImporters,
    importers => importers.statistics || []
)

export const selectTopImportersWindow = createSelector(
    selectTopImporters,
    importers => importers.window
)

export const selectTopExporters = createSelector(
    selectDashboardState,
    state => state.topExporters
)

export const selectIsGetTopExportersPending = createSelector(
    selectDashboardState,
    state => state[getTopExportersAction.pending.type]
)

export const selectTopExportersStatistics = createSelector(
    selectTopExporters,
    exporters => exporters.statistics || []
)

export const selectTopExportersWindow = createSelector(
    selectTopExporters,
    exporters => exporters.window
)