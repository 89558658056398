import api from ".";
import { PaymentsStatusFilter } from "../modules/payments/types/payments-status.filter.type";
import { Paginated, Payment } from "../types";
import { PaginationQuery } from "../types/pagination-query.type";

export const paymentsApi = {
    getPayments: async (params?: PaginationQuery): Promise<Paginated<Payment>> => {
        return api.get('/payments', { params });
    },
    getPaymentsStatusCounts: async (): Promise<Record<PaymentsStatusFilter, number>> => {
        return (await api.get('/payments/statuses')).data;
    },
    getCompanyPayments: async (companyId: string): Promise<Payment[]> => {
        return (await api.get(`/payments/companies/${companyId}`)).data;
    }
}