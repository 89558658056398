import { OpenInNew } from "@mui/icons-material";
import { useBlockchainEventsModal } from "../../modules/blockchain/providers/BlockchainEventsModalProvider";
import { BlockchainTx } from "../../modules/blockchain/types";
import { getNumberOfBlockchainTxEvents } from "../../utils/coimex-utils";

interface IProps {
  tx: BlockchainTx;
  targetHash?: string;
}

export default function BlockchainTxReceivedEventsBtn({
  tx,
  targetHash,
}: IProps) {
  const showEvents = useBlockchainEventsModal();
  const { total, received } = getNumberOfBlockchainTxEvents(tx);

  return (
    <div
      className="flex items-center gap-1.5 link"
      onClick={() => showEvents(tx, targetHash)}
    >
      {received}/{total}
      <OpenInNew />
    </div>
  );
}
