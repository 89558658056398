import { Close } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { PropsWithChildren } from "react";

interface IProps {
  title?: string;
  onClose?: () => void;
}

export default function BottomSheetLayout({
  title,
  onClose = () => {},
  children,
}: PropsWithChildren<IProps>) {
  return (
    <div className="w-full h-full bg-bg-1">
      <div className="w-full py-2 px-6 flex justify-between items-center bg-secondary">
        <h1 className="text-xl font-lato font-semibold text-white">{title}</h1>
        <IconButton
          className="text-white font-semibold"
          size="medium"
          onClick={onClose}
        >
          <Close fontSize="inherit" />
        </IconButton>
      </div>
      {children}
    </div>
  );
}
