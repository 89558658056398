import { yupResolver } from "@hookform/resolvers/yup";
import { LoadingButton } from "@mui/lab";
import { Button, InputAdornment, TextField } from "@mui/material";
import { useSnackbar } from "notistack";
import { useRef, useState } from "react";
import { useForm } from "react-hook-form";
import InfoPopup from "../../../components/InfoPopup/InfoPopup";
import useFocus from "../../../hooks/use-focus";
import { blockchainApi } from "../../../http/blockchain.api";
import { CloseModal } from "../../../providers/ModalProvider";
import Yup from "../../../types/yup-extended.type";
import { SmartDeal, SmartDealPayment } from "../types";

interface IProps {
  smartDeal: SmartDeal;
  close: CloseModal<SmartDealPayment>;
}

const validationSchema = Yup.object().shape({
  exporterWalletAddress: Yup.string()
    .required("This field is required.")
    .blockchainAddress(),
  importerWalletAddress: Yup.string()
    .required("This field is required.")
    .blockchainAddress(),
});

export default function SmartDealPaymentForm({ smartDeal, close }: IProps) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const importerWalletAddressRef = useRef<any>();
  useFocus(importerWalletAddressRef);
  const { enqueueSnackbar } = useSnackbar();
  const formOptions = {
    resolver: yupResolver(validationSchema),
    mode: "onSubmit",
  };
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm(formOptions as any);

  const onSubmit = async (value) => {
    try {
      setIsSubmitting(true);
      const payment = await blockchainApi.createSmartDealPayment(
        smartDeal.id,
        value
      );
      close(payment);
      enqueueSnackbar("Payment created successfully", { variant: "info" });
    } catch {
      setIsSubmitting(false);
    }
  };

  return (
    <form
      className="max-w-full p-4"
      style={{ width: "500px" }}
      onSubmit={handleSubmit(onSubmit)}
    >
      <header className="modal-header text-xl pt-1.5 pb-3">
        Create Payment
      </header>

      <div className="flex-1 flex flex-col gap-6 px-1">
        <TextField
          className="w-full"
          {...register("importerWalletAddress")}
          label="Buyer Refund Wallet Address"
          error={!!errors?.importerWalletAddress}
          variant="filled"
          helperText={errors.importerWalletAddress?.message}
          required
          inputRef={importerWalletAddressRef}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <InfoPopup text="If the deal is canceled or refunded, this is where the buyer's funds will be returned" />
              </InputAdornment>
            ),
          }}
        />

        <TextField
          className="w-full"
          {...register("exporterWalletAddress")}
          label="Seller Funds Payout Wallet Address"
          error={!!errors?.exporterWalletAddress}
          variant="filled"
          helperText={errors.exporterWalletAddress?.message}
          required
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <InfoPopup text="Payments to the seller will be made to this address" />
              </InputAdornment>
            ),
          }}
        />
      </div>

      <footer className="modal-footer flex justify-end gap-4">
        <Button type="button" variant="text" onClick={() => close(null)}>
          Cancel
        </Button>
        <LoadingButton
          type="submit"
          variant="contained"
          color="secondary"
          loading={isSubmitting}
        >
          Save
        </LoadingButton>
      </footer>
    </form>
  );
}
