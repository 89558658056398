import { ArrowBack, Visibility, VisibilityOff } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  FormControl,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  Typography,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { authApi } from "../../../http";
import { useNavigate } from "react-router-dom";
import { authActions } from "../auth.state";

export default function LoginPage() {
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [isForgotPassword, setIsForgotPassword] = useState(false);
  const [isForgotPasswordInProgress, setIsForgotPasswordInProgress] =
    useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const {
    register: registerForgotPassword,
    handleSubmit: handleSubmitForgotPassword,
    formState: { errors: errorsForgotPassword },
  } = useForm();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const emailRef = useRef<HTMLInputElement>();

  useEffect(() => {
    emailRef.current?.focus();
  }, [emailRef.current]);

  const onSubmit = async (data) => {
    try {
      setIsSubmitting(true);
      const response = await authApi.signin(data.username, data.password);
      if (response["twoFactorEnabled"]) {
        dispatch(authActions.signIn2fa(data));
        navigate("/2fa");
      } else {
        dispatch(authActions.signInSuccess(response));
      }
    } catch (error) {
    } finally {
      setIsSubmitting(false);
    }
  };

  const onSubmitForgotPassword = async (data) => {
    try {
      setIsForgotPasswordInProgress(true);
      await authApi.forgotPassword(data.email);
      enqueueSnackbar(
        `Please check reset password email sent to ${data.email}.`,
        { variant: "info" }
      );
    } catch (error) {
    } finally {
      setIsForgotPasswordInProgress(false);
    }
  };

  return isForgotPassword ? (
    <>
      <Typography className="w-full mb-1">Enter your email below:</Typography>
      <form onSubmit={handleSubmitForgotPassword(onSubmitForgotPassword)}>
        <FormControl className="w-full" variant="standard">
          <InputLabel htmlFor="email">Email</InputLabel>
          <Input
            id="email"
            {...registerForgotPassword("email", {
              required: true,
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                message: "invalid email address",
              },
            })}
            error={!!errorsForgotPassword?.email}
            inputRef={emailRef}
          />
        </FormControl>

        <LoadingButton
          className="mt-4 float-right text-xs"
          type="submit"
          color="info"
          size="small"
          loading={isForgotPasswordInProgress}
        >
          Send code
        </LoadingButton>
        <LoadingButton
          className="mt-4 float-left text-xs"
          color="inherit"
          size="small"
          onClick={() => setIsForgotPassword(false)}
          tabIndex={-1}
          startIcon={<ArrowBack />}
        >
          Back
        </LoadingButton>
      </form>
    </>
  ) : (
    <>
      <Typography className="w-full mb-1">
        Welcome to Coimex admin panel
      </Typography>

      <form onSubmit={handleSubmit(onSubmit)}>
        <FormControl className="w-full" variant="standard">
          <InputLabel htmlFor="username">Username</InputLabel>
          <Input
            id="username"
            {...register("username", { required: true })}
            error={!!errors?.email}
            autoFocus
          />
        </FormControl>

        <FormControl className="w-full mt-3" variant="standard">
          <InputLabel htmlFor="password">Password</InputLabel>
          <Input
            id="password"
            {...register("password", { required: true })}
            error={!!errors?.password}
            type={passwordVisible ? "text" : "password"}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  onClick={() => setPasswordVisible((visible) => !visible)}
                >
                  {passwordVisible ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            }
          />
        </FormControl>

        <LoadingButton
          className="mt-4 float-right text-xs"
          color="info"
          size="small"
          loading={isForgotPasswordInProgress}
          onClick={() => setIsForgotPassword(true)}
        >
          Forgot Password?
        </LoadingButton>

        <LoadingButton
          type="submit"
          className="mt-6 w-full"
          variant="contained"
          color="secondary"
          loading={isSubmitting}
        >
          Sign In
        </LoadingButton>
      </form>
    </>
  );
}
