import { Check } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { Chip } from "@mui/material";
import { useEffect, useState } from "react";
import { useDebounce } from "usehooks-ts";
import { blockchainApi } from "../../../http/blockchain.api";
import { useWs } from "../../../providers/WsProvider";
import { BlockchainNotificationType } from "../../../types";
import { hasBlockChainTxEventOfType } from "../../../utils/blockchain-utils";
import {
  BlockchainEventType,
  BlockchainTxUpdatedNotification,
  SmartDeal,
  SmartDealDocument,
  SmartDealDocumentState,
} from "../types";
import SmartDealDocumentsTable from "./SmartDealDocumentsTable";

interface IProps {
  smartDeal: SmartDeal;
  onUpdate: (update: Partial<SmartDeal>) => void;
}

export default function SmartDealDocuments({ smartDeal, onUpdate }: IProps) {
  const [documents, setDocuments] = useState<SmartDealDocument[]>([]);
  const [isGetDocumentsPending, setIsGetDocumentsPending] = useState(true);
  const [isEnablingApproveDocuments, setIsEnablingApproveDocuments] =
    useState(false);
  const { onBlockchainEvent } = useWs();
  const [invalidateGetDocumentsObj, setInvalidateGetDocumentsObj] =
    useState<object>();
  const debouncedInvalidateGetDocuments = useDebounce(
    invalidateGetDocumentsObj,
    1000
  );

  const getDocuments = async () => {
    try {
      setIsGetDocumentsPending(true);
      setDocuments(await blockchainApi.getSmartDealDocuments(smartDeal.id));
    } finally {
      setIsGetDocumentsPending(false);
    }
  };

  const enableApproveDocuments = async () => {
    try {
      setIsEnablingApproveDocuments(true);
      smartDeal = await blockchainApi.enableSmartDealApproveDocuments(
        smartDeal.id
      );
      onUpdate({ ...smartDeal });
    } finally {
      setIsEnablingApproveDocuments(false);
    }
  };

  const handleBlockchainDraftDocCreatedNotification = (
    notification: SmartDealDocument
  ) => {
    if (smartDeal.id == notification.smartDealId) {
      setInvalidateGetDocumentsObj({});
    }
  };

  const handleBlockchainDocPublishedNotification = (
    notification: SmartDealDocument
  ) => {
    if (documents?.some((d) => d.id == notification.id)) {
      setInvalidateGetDocumentsObj({});
    }
  };

  const handleBlockchainDocApprovedNotification = (notification: SmartDeal) => {
    if (smartDeal.id == notification.id) {
      setInvalidateGetDocumentsObj({});
      onUpdate({
        isDocumentsApproveEnabled: notification.isDocumentsApproveEnabled,
      });
    }
  };

  const handleBLockchainTxUpdatedNotification = (
    notification: BlockchainTxUpdatedNotification
  ) => {
    if (documents.some((d) => d.createDocTx?.txHash == notification.txHash)) {
      setInvalidateGetDocumentsObj({});
    }
  };

  useEffect(() => {
    if (debouncedInvalidateGetDocuments) {
      getDocuments();
    }
  }, [debouncedInvalidateGetDocuments]);

  useEffect(() => {
    onBlockchainEvent?.on(
      BlockchainNotificationType.DraftDocCreated,
      handleBlockchainDraftDocCreatedNotification
    );

    onBlockchainEvent?.on(
      BlockchainNotificationType.DocApproved,
      handleBlockchainDocApprovedNotification
    );

    onBlockchainEvent?.on(
      BlockchainNotificationType.TxUpdated,
      handleBLockchainTxUpdatedNotification
    );

    return () => {
      onBlockchainEvent?.off(
        BlockchainNotificationType.DraftDocCreated,
        handleBlockchainDraftDocCreatedNotification
      );

      onBlockchainEvent?.off(
        BlockchainNotificationType.DocApproved,
        handleBlockchainDocApprovedNotification
      );

      onBlockchainEvent?.off(
        BlockchainNotificationType.TxUpdated,
        handleBLockchainTxUpdatedNotification
      );
    };
  }, [onBlockchainEvent, smartDeal, documents]);

  useEffect(() => {
    onBlockchainEvent?.on(
      BlockchainNotificationType.DocPublished,
      handleBlockchainDocPublishedNotification
    );

    return () => {
      onBlockchainEvent?.off(
        BlockchainNotificationType.DocPublished,
        handleBlockchainDocPublishedNotification
      );
    };
  }, [onBlockchainEvent, documents]);

  useEffect(() => {
    smartDeal?.id && getDocuments();
  }, [smartDeal?.id]);

  return (
    <div className="relative max-w-3xl mx-auto flex flex-col">
      <div
        className="flex-1 overflow-auto"
        style={{ maxHeight: "calc(100vh - 240px)" }}
      >
        <SmartDealDocumentsTable
          documents={documents}
          isLoading={isGetDocumentsPending}
        />
      </div>

      <div className="px-4 mt-4 flex justify-end">
        {smartDeal.isDocumentsApproveEnabled ? (
          <Chip
            icon={<Check />}
            label="Approve Documents is Enabled"
            color="secondary"
            size="small"
          />
        ) : (
          <LoadingButton
            variant="contained"
            color="secondary"
            size="small"
            loading={isEnablingApproveDocuments}
            disabled={
              !documents?.length ||
              documents.some((d) => d.state === SmartDealDocumentState.Draft) ||
              !documents.some(
                (d) => d.state === SmartDealDocumentState.Blockchain
              ) ||
              documents.some(
                (d) =>
                  !hasBlockChainTxEventOfType(
                    d.createDocTx,
                    BlockchainEventType.DocCreated
                  )
              )
            }
            onClick={enableApproveDocuments}
          >
            Enable Approve Documents
          </LoadingButton>
        )}
      </div>
    </div>
  );
}
