import {
  Add,
  CurrencyExchangeOutlined,
  MoneyOffOutlined,
  MoreVert,
  PauseOutlined,
  PlayArrowOutlined,
  TuneOutlined,
} from "@mui/icons-material";
import { Button, Divider, IconButton } from "@mui/material";
import { DataGrid, GridColDef, GridFooter } from "@mui/x-data-grid";
import classNames from "classnames";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useEffectOnce } from "usehooks-ts";
import MoreMenu from "../../../components/MenuMore/MoreMenu";
import NoRowsOverlay from "../../../components/NoRowsOverlay/NoRowsOverlay";
import useYesNoConfirm from "../../../hooks/use-yes-no-confirm";
import { commodityTokenApi } from "../../../http/commodity-tokens.api";
import { useModal } from "../../../providers/ModalProvider";
import { AppPage, AppRefreshStatus, CommodityToken } from "../../../types";
import { formatNumberWithSeparator } from "../../../utils/number-utils";
import {
  selectRefreshPage,
  selectRefreshStatus,
} from "../../app/app.selectors";
import { getCommodityTokensAction } from "../commodity-tokens.effects";
import {
  selectCommodityTokens,
  selectIsGetCommodityTokensPending,
} from "../commodity-tokens.selectors";
import AddNewCommodityTokenForm from "./AddNewCommodityTokenForm";
import CommodityAddTokenSupplyForm from "./CommodityTokenAddSupplyForm";
import CommodityTokenPage from "./CommodityTokenPage";
import SetCommodityTokenCommissionForm from "./SetCommodityTokenCommissionForm";
import SetCommodityTokenMinMaxAmountForm from "./SetCommodityTokenMinMaxAmountForm";
import SetCommodityTokenPriceForm from "./SetCommodityTokenPriceForm";

export default function CommodityTokensTable() {
  const commodityTokens = useSelector(selectCommodityTokens);
  const isGetCommodityTokensPending = useSelector(
    selectIsGetCommodityTokensPending
  );
  const [rows, setRows] = useState([]);
  const refreshPage = useSelector(selectRefreshPage);
  const refreshStatus = useSelector(selectRefreshStatus);
  const [selectedMenuCommodityToken, setSelectedMenuCommodityToken] =
    useState<CommodityToken | null>(null);
  const [menuAnchorEl, setMenuAnchorEl] = useState<HTMLButtonElement | null>(
    null
  );
  const openModal = useModal();
  const dispatch = useDispatch();
  const confirmTogglePause = useYesNoConfirm();
  const cols = useMemo<GridColDef[]>(() => {
    const priceTokens = new Set<CommodityToken["symbol"]>();
    commodityTokens.forEach((token) => {
      Object.keys(token.prices).forEach((symbol) => priceTokens.add(symbol));
    });
    return [
      ..._cols,
      ...Array.from(priceTokens).map(
        (token) =>
          ({
            field: token,
            headerName: token,
            renderCell: ({ row }) => (
              <div className="flex flex-col">
                <div className="grid grid-cols-[32px_1fr]">
                  Buy: <span>{row.prices[token]?.buyPrice || 0}</span>
                </div>
                <div className="grid grid-cols-[32px_1fr]">
                  Sell: <span>{row.prices[token]?.sellPrice || 0}</span>
                </div>
              </div>
            ),
            headerAlign: "center",
            align: "center",
            flex: 1,
            sortable: false,
          } as GridColDef)
      ),
      {
        field: "",
        renderCell: (params) => (
          <IconButton onClick={(e) => showMenu(e, params.row)}>
            <MoreVert />
          </IconButton>
        ),
        width: 60,
        sortable: false,
      },
    ];
  }, [commodityTokens]);

  const getCommodityTokens = () => {
    dispatch(getCommodityTokensAction());
  };

  const handleRowClick = async (token: CommodityToken) => {
    await openModal((props) => <CommodityTokenPage token={token} {...props} />);
  };

  const handleAddNewToken = async () => {
    closeMenu();
    if (await openModal((props) => <AddNewCommodityTokenForm {...props} />)) {
      dispatch(getCommodityTokensAction());
    }
  };

  const handleAddTokenSupply = async () => {
    closeMenu();
    if (
      await openModal((props) => (
        <CommodityAddTokenSupplyForm
          token={selectedMenuCommodityToken}
          {...props}
        />
      ))
    ) {
      dispatch(getCommodityTokensAction());
    }
  };

  const handleSetPrice = async () => {
    closeMenu();
    if (
      await openModal((props) => (
        <SetCommodityTokenPriceForm
          token={selectedMenuCommodityToken}
          {...props}
        />
      ))
    ) {
      dispatch(getCommodityTokensAction());
    }
  };

  const handleSetCommission = async () => {
    closeMenu();
    if (
      await openModal((props) => (
        <SetCommodityTokenCommissionForm
          token={selectedMenuCommodityToken}
          {...props}
        />
      ))
    ) {
      dispatch(getCommodityTokensAction());
    }
  };

  const handleSetMinMax = async () => {
    closeMenu();
    if (
      await openModal((props) => (
        <SetCommodityTokenMinMaxAmountForm
          token={selectedMenuCommodityToken}
          {...props}
        />
      ))
    ) {
      dispatch(getCommodityTokensAction());
    }
  };

  const handleTogglePause = async () => {
    closeMenu();
    if (
      await confirmTogglePause({
        title: `Confirm ${
          selectedMenuCommodityToken.isPaused ? "Resume" : "Pause"
        }`,
        message: `Are you sure you want to ${
          selectedMenuCommodityToken.isPaused ? "resume" : "pause"
        } ${selectedMenuCommodityToken.symbol}-${
          selectedMenuCommodityToken.name
        }?`,
        onYes: () =>
          commodityTokenApi.toggleTokenPause(
            selectedMenuCommodityToken.address,
            !selectedMenuCommodityToken.isPaused
          ),
      })
    ) {
      dispatch(getCommodityTokensAction());
    }
  };

  const showMenu = (
    e: React.MouseEvent<HTMLButtonElement>,
    token: CommodityToken
  ) => {
    e.stopPropagation();
    setSelectedMenuCommodityToken(token);
    setMenuAnchorEl(e.currentTarget);
  };

  const closeMenu = () => {
    setMenuAnchorEl(null);
  };

  useEffect(() => {
    setRows(
      commodityTokens?.map((token, index) => ({
        ...token,
        id: index,
        rowNo: index + 1,
      }))
    );
  }, [commodityTokens]);

  useEffectOnce(() => {
    getCommodityTokens();
  });

  useEffect(() => {
    if (
      refreshStatus === AppRefreshStatus.Invalidated &&
      refreshPage === AppPage.CommodityTokens
    ) {
      getCommodityTokens();
    }
  }, [refreshStatus]);

  return (
    <>
      <Button
        className="mt-3 float-right rounded-3xl"
        variant="contained"
        color="secondary"
        size="small"
        onClick={handleAddNewToken}
      >
        <Add /> Add New Token
      </Button>

      <div className="w-full mt-4">
        <DataGrid
          columns={cols}
          rows={rows}
          disableColumnMenu
          disableSelectionOnClick
          style={{ minHeight: "300px", height: "calc(100vh - 200px)" }}
          loading={isGetCommodityTokensPending}
          autoPageSize
          components={{
            NoRowsOverlay: () => (
              <NoRowsOverlay emptyMessage="No Commodity Tokens" />
            ),
            Footer: (props) => (
              <div className="relative">
                <GridFooter {...props} />
                <div className="absolute left-0 top-0 bottom-0 px-6 flex gap-6">
                  <div className="flex items-center gap-2">
                    <div className="w-4 h-4 rounded bg-yellow-300" />
                    <small>Paused</small>
                  </div>

                  <div className="flex items-center gap-2">
                    <div className="w-4 h-4 rounded bg-red-300" />
                    <small>Not Available</small>
                  </div>
                </div>
              </div>
            ),
          }}
          getRowClassName={({ row }: { row: CommodityToken }) =>
            classNames(
              "cursor-pointer",
              !row.exist && row.isPaused
                ? "bg-gradient-to-r from-yellow-200 to-red-300"
                : !row.exist
                ? "bg-red-200"
                : row.isPaused
                ? "bg-yellow-200"
                : null
            )
          }
          onRowClick={({ row }) => handleRowClick(row)}
        />
        <MoreMenu anchorEl={menuAnchorEl} onClose={closeMenu}>
          <div className="flex flex-col px-1">
            <Button
              className="justify-start"
              variant="text"
              color="info"
              startIcon={<Add />}
              onClick={handleAddTokenSupply}
            >
              Add Supply
            </Button>
            <Divider />
            <Button
              className="justify-start"
              variant="text"
              color="smoke"
              startIcon={<CurrencyExchangeOutlined />}
              onClick={handleSetPrice}
            >
              Set Price
            </Button>
            <Divider />
            <Button
              className="justify-start"
              variant="text"
              color="smoke"
              startIcon={<MoneyOffOutlined />}
              onClick={handleSetCommission}
            >
              Set Commission
            </Button>
            <Divider />
            <Button
              className="justify-start"
              variant="text"
              color="smoke"
              startIcon={<TuneOutlined />}
              onClick={handleSetMinMax}
            >
              Set Min Max
            </Button>
            <Divider />
            <Button
              className="justify-start"
              color={selectedMenuCommodityToken?.isPaused ? "success" : "error"}
              variant="text"
              startIcon={
                selectedMenuCommodityToken?.isPaused ? (
                  <PlayArrowOutlined />
                ) : (
                  <PauseOutlined />
                )
              }
              onClick={handleTogglePause}
            >
              {selectedMenuCommodityToken?.isPaused ? "Resume" : "Pause"}
            </Button>
          </div>
        </MoreMenu>
      </div>
    </>
  );
}

const _cols: GridColDef[] = [
  {
    field: "rowNo",
    headerName: "",
    width: 50,
    sortable: false,
  },
  {
    field: "name",
    headerName: "Name",
    renderCell: ({ row }) => (
      <div className="whitespace-pre">
        {row.symbol}
        {" - "}
        <span className="text-text-1">{row.name}</span>
      </div>
    ),
    flex: 1,
  },
  {
    field: "amountAvailableToBuy",
    headerName: "Amount Available",
    renderCell: ({ value }) => formatNumberWithSeparator(value),
    headerAlign: "right",
    align: "right",
    flex: 1,
  },
  {
    field: "totalSupply",
    headerName: "Total Supply",
    renderCell: ({ value }) => formatNumberWithSeparator(value),
    flex: 1,
    headerAlign: "right",
    align: "right",
  },
];
