import { EffectCallback, useEffect } from "react";
import { useSelector } from "react-redux";
import { selectIsAuthenticated } from "../modules/auth/auth.selectors";

export default function useAuthenticated(effect: EffectCallback) {
    const isAuthenticated = useSelector(selectIsAuthenticated);

    useEffect(() => {
        if (isAuthenticated) {
            effect();
        }
    }, [isAuthenticated])
}