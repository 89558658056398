import { ModalProps, Popover } from "@mui/material";
import { PropsWithChildren } from "react";

interface IProps {
  anchorEl?: null | Element | ((element: Element) => Element);
  onClose?: ModalProps["onClose"];
}

export default function MoreMenu({
  anchorEl,
  onClose,
  children,
}: PropsWithChildren<IProps>) {
  const open = Boolean(anchorEl);

  return (
    <Popover
      anchorEl={anchorEl as any}
      open={open}
      onClose={onClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
    >
      {children}
    </Popover>
  );
}
