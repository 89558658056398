import { Chip } from "@mui/material";
import {
  PlanUpgradeRequestStatusText,
  getPlanUpgradeRequestStatusColor,
} from "../../../utils/coimex-utils";
import { PlanUpgradeRequestStatus } from "../types/plan-upgrade-request-status.type";

interface IProps {
  status: PlanUpgradeRequestStatus;
}

export default function PlanUpgradeRequestStatusChip({ status }: IProps) {
  return (
    <Chip
      className="pointer-events-none"
      label={PlanUpgradeRequestStatusText[status]}
      color={getPlanUpgradeRequestStatusColor(status)}
      size="small"
      variant="outlined"
    />
  );
}
