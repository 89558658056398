import { createSlice } from "@reduxjs/toolkit";
import { Notification } from "../../types";
import { addCommonReducers } from "../../utils/store-utils";
import { authActions } from "../auth/auth.state";
import { NotificationsStatus } from "./types/notifications-status.type";
import {
    getNotificationsAction,
    getNotificationsStatusAction,
    updateLastSeenNotificationAction,
} from "./notifications.effects";

export const NOTIFICATIONS_STATE_NAME = "notifications";

export interface NotificationsState {
    notifications: Notification[];
    total: number;
    status: NotificationsStatus;
}

const initialState: NotificationsState = {
    notifications: [],
    total: null,
    status: {
        notSeen: null,
        lastSeen: null
    },
};

export const notificationsState = createSlice({
    name: NOTIFICATIONS_STATE_NAME,
    initialState,
    reducers: {
        notificationReceived(state, action) {
            state.notifications = [...state.notifications, action.payload].distinct(
                (n) => n.id
            );
            state.status.notSeen += 1;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getNotificationsAction.fulfilled, (state, action) => {
                if (action.payload) {
                    state.notifications = [
                        ...state.notifications,
                        ...action.payload.data,
                    ].distinct((n) => n.id);
                    state.total = action.payload.total;
                }
            })
            .addCase(getNotificationsStatusAction.fulfilled, (state, action) => {
                state.status = action.payload;
            })
            .addCase(
                updateLastSeenNotificationAction.fulfilled,
                (state, action) => {
                    state.status.notSeen = 0;
                    state.status.lastSeen = action.meta.arg;
                }
            )
            .addCase(authActions.logout, () => {
                return initialState;
            });
        addCommonReducers(builder, NOTIFICATIONS_STATE_NAME);
    },
});

export const notificationsActions = notificationsState.actions;
export const notificationsReducer = notificationsState.reducer;
