import classNames from "classnames";
import { SmartDealDocumentState } from "../../modules/blockchain/types";
import { getSmartDealDocumentStatusIndicatorColor } from "../../utils/coimex-utils";

interface IProps {
  status: SmartDealDocumentState;
  className?: string;
}

export default function SmartDealDocumentStatusIndicator({
  status,
  className,
}: IProps) {
  const color = getSmartDealDocumentStatusIndicatorColor(status);

  return (
    <div className={classNames("flex items-center gap-1 text-xs", className)}>
      <span className="mr-1">Status</span>
      <span
        className={classNames("rounded-full", "w-2", "h-2", `bg-${color}`)}
      ></span>
      <span className={classNames(`text-${color}`)}>{status}</span>
    </div>
  );
}
