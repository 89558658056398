const LS_KEYS = {
    TOKEN: 'TOKEN'
};
class LocalStorage {
    get token(): string | null {
        return localStorage.getItem(LS_KEYS.TOKEN) || '';
    }

    set token(value: string | null) {
        if (value) {
            localStorage.setItem(LS_KEYS.TOKEN, value);
        } else {
            localStorage.removeItem(LS_KEYS.TOKEN);
        }
    }
}

const ls = new LocalStorage();
export default ls;