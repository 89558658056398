import { CircularProgress } from "@mui/material";

interface IProps {
    size?: number
}

export default function Loading({ size = 60 }: IProps) {
    return (
        <div className="h-full flex justify-center items-center">
            <CircularProgress size={size} />
        </div>
    )
}