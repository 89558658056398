import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, CircularProgress, Tab } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import BottomSheetLayout from "../../../components/BottomSheetLayout/BottomSheetLayout";
import { investApi } from "../../../http";
import { InvestPool, InvestPoolType } from "../../../types";
import { getInvestPoolsAction } from "../invest.effects";
import InvestPoolInfo from "./InvestPoolInfo";
import InvestPoolInvests from "./InvestPoolInvests";

interface IProps {
  id: InvestPool["id"];
  investPool?: InvestPool;
  onClose: () => void;
}

export default function PromptPayPoolPage(props: IProps) {
  const [tab, setTab] = useState("0");
  const [investPool, setInvestPool] = useState<InvestPool>(props.investPool);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const getInvestPool = async () => {
    try {
      setInvestPool(await investApi.getInvestPool(props.id));
    } catch (ignored) {
      navigate(-1);
    }
  };

  const handleUpdate = (update: Partial<InvestPool>) => {
    setInvestPool((smartDeal) => ({
      ...smartDeal,
      ...update,
    }));
    dispatch(getInvestPoolsAction({ type: InvestPoolType.PromptPay }));
  };

  const handleTabChange = (_, tab) => {
    setTab(tab);
  };

  useEffect(() => {
    if (!props.investPool) {
      getInvestPool();
    }
  }, [props.id, props.investPool]);

  return (
    <BottomSheetLayout title="Investment Pool" onClose={props.onClose}>
      <div className="p-4 centered">
        <TabContext value={tab}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList onChange={handleTabChange}>
              <Tab className="normal-case" label="Pool Info" value="0" />
              <Tab className="normal-case" label="Transactions" value="1" />
            </TabList>
          </Box>

          {investPool ? (
            <>
              <TabPanel value="0">
                <InvestPoolInfo pool={investPool} onUpdate={handleUpdate} />
              </TabPanel>

              <TabPanel value="1">
                <InvestPoolInvests
                  investPool={investPool}
                  onUpdate={handleUpdate}
                />
              </TabPanel>
            </>
          ) : (
            <div className="absolute left-0 right-0 top-48 flex justify-center">
              <CircularProgress color="primary" size="32px" />
            </div>
          )}
        </TabContext>
      </div>
    </BottomSheetLayout>
  );
}
