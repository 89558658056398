import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { useEffectOnce } from "usehooks-ts";
import useBottomSheet from "../../../hooks/use-bottom-sheet";
import { AppPage } from "../../../types/app-page.type";
import { AppRefreshStatus } from "../../../types/app-refresh-status.type";
import {
  selectRefreshPage,
  selectRefreshStatus,
} from "../../app/app.selectors";
import {
  getCompaniesAction,
  getCompaniesStatusCountsAction,
} from "../companies.effects";
import CompaniesFilters from "./CompaniesFilters";
import CompaniesTable from "./CompaniesTable";
import CompanyPage from "./CompanyPage";
import { selectIsAuthenticated } from "../../auth/auth.selectors";

export default function Companies() {
  const refreshPage = useSelector(selectRefreshPage);
  const refreshStatus = useSelector(selectRefreshStatus);
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const setBottomSheet = useBottomSheet();
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const getCompanies = () => {
    dispatch(getCompaniesStatusCountsAction({ changeRefresh: true }));
    dispatch(getCompaniesAction({ page: 0 }));
  };

  const handleCompanyPageClose = () => {
    setBottomSheet(null);
    navigate(-1);
  };

  const showCompanyPage = (id) => {
    setBottomSheet({
      component: <CompanyPage id={id} onClose={handleCompanyPageClose} />,
      onClose: handleCompanyPageClose,
    });
  };

  useEffectOnce(() => {
    dispatch(getCompaniesStatusCountsAction({ changeRefresh: true }));
  });

  useEffect(() => {
    const { id } = params;
    id && isAuthenticated ? showCompanyPage(id) : setBottomSheet(null);
  }, [params?.id, isAuthenticated]);

  useEffect(() => {
    if (
      refreshStatus === AppRefreshStatus.Invalidated &&
      refreshPage === AppPage.Companies
    ) {
      getCompanies();
    }
  }, [refreshStatus]);

  return (
    <div className="w-constraint">
      <CompaniesFilters />
      <CompaniesTable />
    </div>
  );
}
