import { createSlice } from "@reduxjs/toolkit";
import { Configs } from "../../types";
import { addCommonReducers } from "../../utils/store-utils";
import { getConfigsAction } from "./settings.effects";

export const SETTING_STATE_NAME = 'settings';

export interface SettingsState {
    configs: Configs | null
}

export const settingsState = createSlice({
    name: SETTING_STATE_NAME,
    initialState: {
        configs: null
    } as SettingsState,
    reducers: {
        updateConfigs(state, { payload }: { payload: Partial<Configs> }) {
            state.configs = {
                ...(state.configs || {}),
                ...payload
            } as Configs
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getConfigsAction.fulfilled, (state, action) => {
                state.configs = action.payload;
            });
        addCommonReducers(builder, SETTING_STATE_NAME);
    }
});

export const settingsActions = settingsState.actions;
export const settingsReducer = settingsState.reducer;