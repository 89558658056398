import { Chip } from "@mui/material";
import { PaymentMethod } from "../../types";
import { getPaymentMethodColor } from "../../utils/coimex-utils";

interface IProps {
  method: PaymentMethod;
}

export default function PaymentMethodChip({ method }: IProps) {
  return (
    <Chip
      className="border-none"
      label={method}
      color={getPaymentMethodColor(method)}
      size="small"
      variant="outlined"
    />
  );
}
