import { TablePagination } from "@mui/material";
import "./Dashboard.scss";

interface IProps {
    page: number
    pageSize: number
    total: number
    onPageChange: (page: number) => void
}


export default function WidgetPaginator({ page, pageSize, total, onPageChange }: IProps) {
    return (
        <div className="WidgetPaginator">
            <TablePagination
                component="div"
                count={total}
                page={page}
                onPageChange={(_, page) => onPageChange(page)}
                rowsPerPage={pageSize}
                rowsPerPageOptions={[]}
            />
        </div>
    )
}