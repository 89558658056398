import { Business } from "@mui/icons-material";
import { Avatar } from "@mui/material";
import { DataGrid, GridColDef, GridSortModel } from "@mui/x-data-grid";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import NoRowsOverlay from "../../../components/NoRowsOverlay/NoRowsOverlay";
import PlanSubscriptionChip from "../../../components/PlanSubscriptionChip/PlanSubscriptionChip";
import { Pagination } from "../../../types";
import { toPurposeOfUse } from "../../../utils/coimex-utils";
import { getPlanSubscriptionsAction } from "../plans.effects";
import {
  selectIsGetPlanSubscriptionsPending,
  selectPlanSubscriptions,
  selectPlanSubscriptionsOrderByFilter,
  selectPlanSubscriptionsPagination,
} from "../plans.selectors";
import SubscriptionStatusChip from "./SubscriptionStatusChip";

export default function PlanSubscriptions() {
  const subscriptions = useSelector(selectPlanSubscriptions);
  const isGetSubscriptionsPending = useSelector(
    selectIsGetPlanSubscriptionsPending
  );
  const pagination = useSelector(selectPlanSubscriptionsPagination);
  const orderBy = useSelector(selectPlanSubscriptionsOrderByFilter);
  const [rows, setRows] = useState([]);
  const dispatch = useDispatch();

  const handlePagination = (
    pg: Partial<Pick<Pagination, "page" | "pageSize">>
  ) => {
    if (
      (pg.page != null && pg.page != pagination.page) ||
      (pg.pageSize != null && pg.pageSize != pagination.pageSize)
    ) {
      dispatch(getPlanSubscriptionsAction(pg));
    }
  };

  const handleSorting = (sort: GridSortModel) => {
    dispatch(getPlanSubscriptionsAction({ filters: { orderBy: sort?.[0] } }));
  };

  useEffect(() => {
    setRows(
      subscriptions?.map((subscription, index) => ({
        ...subscription,
        rowNo: pagination.page * pagination.pageSize + index + 1,
      }))
    );
  }, [subscriptions]);

  return (
    <div className="w-full mt-4">
      <DataGrid
        columns={cols}
        rows={rows}
        disableColumnMenu
        disableSelectionOnClick
        style={{ minHeight: "300px", height: "calc(100vh - 200px)" }}
        loading={isGetSubscriptionsPending}
        pagination
        paginationMode="server"
        autoPageSize
        rowCount={pagination.total}
        page={pagination.page}
        sortingMode="server"
        sortModel={orderBy ? [orderBy] : []}
        onSortModelChange={handleSorting}
        onPageChange={(page) => handlePagination({ page })}
        onPageSizeChange={(pageSize) => handlePagination({ pageSize })}
        components={{
          NoRowsOverlay: () => (
            <NoRowsOverlay emptyMessage="No Plan Subscriptions" />
          ),
        }}
      />
    </div>
  );
}

const cols: GridColDef[] = [
  {
    field: "rowNo",
    headerName: "",
    width: 50,
    sortable: false,
  },
  {
    field: "company",
    headerName: "Company",
    renderCell: ({ value }) => (
      <Link
        className="link flex items-center gap-x-2"
        to={`/companies/${value.id}`}
        onClick={(e) => e.stopPropagation()}
      >
        <Avatar src={value.avatarUrl} sx={{ width: 28, height: 28 }}>
          <Business fontSize="small" />
        </Avatar>
        <span>{value.name}</span>
      </Link>
    ),
    flex: 1,
  },
  {
    field: "subscription",
    headerName: "Plan",
    renderCell: ({ row }) => (
      <div className="flex flex-col gap-0.5">
        <PlanSubscriptionChip
          type={row.type}
          durationType={row.duration.type}
          purposeOfUse={toPurposeOfUse(row.roles)}
        />
      </div>
    ),
    flex: 1,
  },
  {
    field: "isActive",
    headerName: "Subscription Status",
    renderCell: ({ value }) => <SubscriptionStatusChip isActive={value} />,
    flex: 1,
  },
  {
    field: "endDate",
    headerName: "Expires",
    renderCell: ({ value }) => moment(value).fromNow(),
    flex: 1,
  },
  {
    field: "createdAt",
    headerName: "Created At",
    valueFormatter: (params) => moment(params.value).format("YYYY-MM-DD"),
    width: 120,
  },
];
