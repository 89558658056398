import api from ".";
import { Configs } from "../types";
import { ConfigFileUploadAs } from "../types/config-file-upload-as.type";

export const configsApi = {
    getConfigs: async (): Promise<Configs> => {
        return (await api.get("/configs")).data;
    },
    upload: async (
        file: File,
        as: ConfigFileUploadAs,
        params?: Record<string, any>
    ): Promise<any> => {
        const form = new FormData();
        form.append("file", file);
        form.append("as", as);
        Object.entries(params || {}).forEach(([k, v]) => form.append(k, v));
        return (
            await api.post("/configs/upload", form, {
                headers: {
                    "content-type": "multipart/form-data",
                },
            })
        ).data;
    },
    updateConfigs: async (configs: Partial<Configs>): Promise<void> => {
        return await api.put("/configs", configs);
    },
};
