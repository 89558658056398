import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useEffectOnce } from "usehooks-ts";
import { AppPage, AppRefreshStatus } from "../../../types";
import {
  selectRefreshPage,
  selectRefreshStatus,
} from "../../app/app.selectors";
import {
  getPlanSubscriptionsAction,
  getPlanUpgradeRequestsAction,
} from "../plans.effects";
import PlansFilters from "./PlansFilters";
import { PlanFiltersTabType } from "../types/plans-filters-tab-type.type";
import PlanSubscriptions from "./PlanSubscriptions";
import PlanUpgradeRequests from "./PlanUpgradeRequests";

export default function Plans() {
  const [selectedTab, setSelectedTab] = useState(
    PlanFiltersTabType.Subscription
  );
  const refreshPage = useSelector(selectRefreshPage);
  const refreshStatus = useSelector(selectRefreshStatus);
  const dispatch = useDispatch();

  const getPlanSubscriptionsAndUpgradeRequests = () => {
    dispatch(getPlanSubscriptionsAction({ page: 0 }));
    dispatch(getPlanUpgradeRequestsAction());
  };

  useEffectOnce(() => {
    getPlanSubscriptionsAndUpgradeRequests();
  });

  useEffect(() => {
    if (
      refreshStatus === AppRefreshStatus.Invalidated &&
      refreshPage === AppPage.Plans
    ) {
      getPlanSubscriptionsAndUpgradeRequests();
    }
  }, [refreshStatus]);

  return (
    <div className="w-constraint">
      <PlansFilters onSelectedTabChanged={setSelectedTab} />
      {selectedTab == PlanFiltersTabType.Subscription ? (
        <PlanSubscriptions />
      ) : (
        <PlanUpgradeRequests />
      )}
    </div>
  );
}
