import { ReactNode, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import ToggleBtnGroup from "../../../components/ToggleBtnGroup/ToggleBtnGroup";

export enum InvestPoolsFiltersTab {
  PromptPayPools = "prompt-pay-pools",
  ExpansionFundPools = "expansion-fund-pools",
}

const TABS: { label: ReactNode; value: InvestPoolsFiltersTab }[] = [
  {
    value: InvestPoolsFiltersTab.PromptPayPools,
    label: (
      <Link to={`/investment-pools/${InvestPoolsFiltersTab.PromptPayPools}`}>
        Prompt Pay Pools
      </Link>
    ),
  },
  {
    value: InvestPoolsFiltersTab.ExpansionFundPools,
    label: (
      <Link
        to={`/investment-pools/${InvestPoolsFiltersTab.ExpansionFundPools}`}
      >
        Expansion Fund Pools
      </Link>
    ),
  },
];

export default function InvestPoolsFilters() {
  const [tab, setTab] = useState<InvestPoolsFiltersTab>(
    InvestPoolsFiltersTab.PromptPayPools
  );
  const params = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    setTab((params.tab as any) || InvestPoolsFiltersTab.PromptPayPools);
    if (!params?.tab) {
      navigate(InvestPoolsFiltersTab.PromptPayPools, { replace: true });
    }
  }, [params?.tab]);

  return <ToggleBtnGroup items={TABS} selectedValue={tab} />;
}
