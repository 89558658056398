import { createAsyncThunk } from "@reduxjs/toolkit";
import { schedulesApi } from "../../http/schedules.api";
import { Schedule } from "../../types";
import { AppPage } from "../../types/app-page.type";
import { AppRefreshStatus } from "../../types/app-refresh-status.type";
import { appActions } from "../app/app.state";

export const getSchedulesAction = createAsyncThunk(
    'schedules/getSchedules',
    async (_, thunk) => {
        try {
            thunk.dispatch(appActions.changeRefresh({
                status: AppRefreshStatus.InProgress,
                page: AppPage.Schedules
            }));
            const response = await schedulesApi.getSchedules();
            return response;
        } finally {
            thunk.dispatch(appActions.changeRefresh({
                status: AppRefreshStatus.Idle,
                page: AppPage.Schedules
            }));
        }
    }
)

export const forceRunScheduleAction = createAsyncThunk(
    'schedules/forceRun',
    async ({ id }: { id: string }) => {
        return schedulesApi.forceRunSchedule(id);
    }
)

export const updateScheduleAction = createAsyncThunk(
    'schedules/updateSchedule',
    async ({ id, update }: { id: string, update: Partial<Schedule> }) => {
        return schedulesApi.updateSchedule(id, update);
    }
)