interface IProps {
  className?: string;
}

export default function CometStart({ className }: IProps) {
  return (
    <svg
      className={className}
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 1L8 8M6 1L9.5 4.5M1 6L4.5 9.5M12.5 15.5L9.5 17L10 13.5L8 11.5L11 11L12.5 8L14 11L17 11.5L15 13.5L15.5 17L12.5 15.5Z"
        stroke=""
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
