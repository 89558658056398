import { createAsyncThunk } from "@reduxjs/toolkit";
import { opportunitiesApi } from "../../http";
import { Notification, NotificationType, Opportunity, OpportunityType } from "../../types";
import { AppPage } from "../../types/app-page.type";
import { AppRefreshStatus } from "../../types/app-refresh-status.type";
import { appActions } from "../app/app.state";
import { RootState } from "../store";
import { RequestsState } from "./requests.state";
import { RequestsFilters } from "./types/requests-filters.type";
import { notificationsActions } from "../notifications/notifications.state";
import listenerMiddleware from "../store/middlewares/listener.middleware";

export const getRequestsAction = createAsyncThunk(
    'requests/getRequests',
    async ({ page, pageSize, filters }: { page?: number, pageSize?: number, filters?: Partial<RequestsFilters> }, thunk) => {
        const requestsState: RequestsState = (<RootState>thunk.getState()).requests;
        page = page ?? requestsState.pagination.page;
        pageSize = pageSize ?? requestsState.pagination.pageSize;
        filters = {
            ...requestsState.filters,
            ...(filters || {})
        };
        const paginatedRequests = await opportunitiesApi.getOpportunities({
            from: page * pageSize,
            take: pageSize,
            filters: JSON.stringify({
                ...filters,
                type: OpportunityType.Request
            })
        });
        return { paginatedRequests: paginatedRequests, page, pageSize, filters: <RequestsFilters>filters };
    }
)

export const getRequestsStatusCountsAction = createAsyncThunk(
    'requests/getRequestsStatusCounts',
    async ({ changeRefresh }: { changeRefresh: boolean }, thunk) => {
        try {
            changeRefresh && thunk.dispatch(appActions.changeRefresh({
                status: AppRefreshStatus.InProgress,
                page: AppPage.Requests
            }));
            const response = await opportunitiesApi.getOpportunitiesStatusCounts(OpportunityType.Request);
            return response;
        } finally {
            changeRefresh && thunk.dispatch(appActions.changeRefresh({
                status: AppRefreshStatus.Idle,
                page: AppPage.Requests
            }));
        }
    }
)

listenerMiddleware.startListening({
    actionCreator: notificationsActions.notificationReceived,
    effect: (action, listenerApi) => {
        const notification = action.payload as Notification<{ opportunity: Opportunity }>;
        if (notification?.type === NotificationType.OpportunityUnderReview &&
            notification?.payload?.opportunity?.type === OpportunityType.Request) {
            listenerApi.dispatch(getRequestsAction({}));
            listenerApi.dispatch(getRequestsStatusCountsAction({ changeRefresh: false }));
        }
    }
})