import { Chip } from "@mui/material";
import { SmartDealDocumentState } from "../../modules/blockchain/types";
import { getSmartDealDocumentStateColor } from "../../utils/coimex-utils";

interface IProps {
  state: SmartDealDocumentState;
}

export default function SmartDealDocumentStateChip({ state }: IProps) {
  return (
    <Chip
      label={state}
      color={getSmartDealDocumentStateColor(state)}
      size="small"
    />
  );
}
