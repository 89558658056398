export default function CommodityTokenSvg() {
  return (
    <div className="flex scale-[1.4]">
      <svg className="scale-110" viewBox="0 0 512 512">
        <rect width="512" height="512" rx="15%" fill="transparent" />
        <path fill="rgba(0,0,0,0.4)" d="m256 362v107l131-185z" />
        <path fill="rgba(0,0,0,0.4)" d="m256 41l131 218-131 78-132-78" />
        <path fill="rgba(0,0,0,0.4)" d="m256 41v158l-132 60m0 25l132 78v107" />
        <path fill="rgba(0,0,0,0.4)" d="m256 199v138l131-78" />
        <path fill="rgba(0,0,0,0.4)" d="m124 259l132-60v138" />
      </svg>

      <svg
        className="-mx-1 -translate-x-[1px] scale-y scale-x-[1.4]"
        width="32"
        height="32"
        viewBox="0 0 163.575 163.575"
        fill="rgba(0,0,0,0.6)"
      >
        <g>
          <rect x="79.34" y="114.68" width="2.447" height="33.439"></rect>
          <rect x="81.788" y="114.68" width="2.447" height="33.439"></rect>
          <path d="M81.788,115.404c0,0,0.006-21.123-22.669-29.624C59.119,85.78,58.088,115.404,81.788,115.404z"></path>
          <path d="M81.788,87.326c0,0,0.006-21.123-22.669-29.624C59.119,57.702,58.088,87.326,81.788,87.326z"></path>
          <path d="M81.788,59.248c0,0,0.006-21.124-22.669-29.625C59.119,29.624,58.088,59.248,81.788,59.248z"></path>
          <path d="M81.788,40.443V0C81.788,0,61.179,21.638,81.788,40.443z"></path>
          <path d="M81.788,115.404c0,0-0.007-21.123,22.669-29.624C104.457,85.78,105.487,115.404,81.788,115.404z"></path>
          <path d="M81.788,87.326c0,0-0.007-21.123,22.669-29.624C104.457,57.702,105.487,87.326,81.788,87.326z"></path>
          <path d="M81.788,59.248c0,0-0.007-21.124,22.669-29.625C104.457,29.624,105.487,59.248,81.788,59.248z"></path>
          <path d="M81.788,40.443V0C81.788,0,102.395,21.638,81.788,40.443z"></path>
        </g>
      </svg>
    </div>
  );
}
