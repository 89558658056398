import classNames from "classnames";
import NoRowsOverlay from "../../../components/NoRowsOverlay/NoRowsOverlay";
import { CloseModal } from "../../../providers/ModalProvider";
import { BlockchainEvent } from "../types";
import "./SmartDeals.scss";

interface IProps {
  title: string;
  events: BlockchainEvent[];
  targetHash?: string;
  close?: CloseModal;
}

export default function BlockchainEvents({
  title,
  events,
  targetHash,
}: IProps) {
  return (
    <div className="BlockchainEvents max-w-full p-4" style={{ width: "600px" }}>
      <header className="modal-header flex justify-between items-center pr-12">
        {title}
      </header>
      <div
        className="flex-1 overflow-auto flex flex-col gap-4"
        style={{ maxHeight: "calc(100vh - 240px)" }}
      >
        {events?.length ? (
          <div className="flex flex-col gap-2">
            <header className="events-list-row">
              <span className="events-list-row__col-type">Type</span>
              <span className="events-list-row__col-data">Data</span>
            </header>
            {events?.map((e, idx) => (
              <div key={idx} className="events-list-row">
                <span className="events-list-row__col-type">
                  {e.type.toSplitCase()}
                </span>
                <div className="events-list-row__col-data flex flex-col gap-1">
                  {e.data.map((d, idx) => (
                    <span
                      className={classNames({
                        "text-secondary": d == targetHash,
                      })}
                      key={idx}
                    >
                      {d}
                    </span>
                  ))}
                </div>
              </div>
            ))}
          </div>
        ) : (
          <NoRowsOverlay emptyMessage="No events" />
        )}
      </div>
      <footer className="modal-footer flex justify-between"></footer>
    </div>
  );
}
