import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { getRequestsAction, getRequestsStatusCountsAction } from "./requets.effects";

export const selectRequestsState = (state: RootState) => state.requests;

export const selectRequests = createSelector(
    selectRequestsState,
    state => state.requests || []
)

export const selectIsGetRequestsPending = createSelector(
    selectRequestsState,
    state => state[getRequestsAction.pending.type]
)

export const selectRequestsPagination = createSelector(
    selectRequestsState,
    state => state.pagination
)

export const selectRequestsStatusCounts = createSelector(
    selectRequestsState,
    state => state.statusCounts
)

export const selectRequestsUnderViewCount = createSelector(
    selectRequestsStatusCounts,
    counts => counts?.UnderReview
)

export const selectIsGetRequestsStatusCountsPending = createSelector(
    selectRequestsState,
    state => state[getRequestsStatusCountsAction.pending.type]
)

export const selectRequestsFilters = createSelector(
    selectRequestsState,
    state => state.filters
)

export const selectRequestsStatusFilter = createSelector(
    selectRequestsFilters,
    filters => filters.status
)

export const selectRequestsSubCategoryIdFilter = createSelector(
    selectRequestsFilters,
    filters => filters.subCategoryId
)

export const selectRequestsSearchFilter = createSelector(
    selectRequestsFilters,
    filters => filters.search
)

export const selectRequestsOrderByFilter = createSelector(
    selectRequestsFilters,
    filters => filters.orderBy
)