import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useEffectOnce } from "usehooks-ts";
import api from ".";
import { authActions } from "../modules/auth/auth.state";
import { getErrorMessage } from "../utils/http-utils";

export default function useHttpErrorInterceptor() {
    const [error, setError] = useState({ message: null });
    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useDispatch();

    useEffect(() => {
        if (error?.message) {
            enqueueSnackbar(error.message, { variant: 'error' });
        }
    }, [error]);

    useEffectOnce(() => {
        api.interceptors.response.use(response => {
            return response;
        }, error => {
            if (error?.response?.status === 401) {
                dispatch(authActions.logout);
            } else {
                const message = getErrorMessage(error);
                setError({ message });
                return Promise.reject(message);
            }
        });
    })
}