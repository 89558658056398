import { useState } from "react";
import { useSelector } from "react-redux";
import ToggleBtnGroup from "../../../components/ToggleBtnGroup/ToggleBtnGroup";
import { PlanFiltersTabTypeText } from "../../../utils/coimex-utils";
import {
  selectGetPlanUpgradeRequests,
  selectIsGetPlanSubscriptionsPending,
  selectIsGetPlanUpgradeRequestsPending,
  selectPlanSubscriptionsPagination,
} from "../plans.selectors";
import { PlanFiltersTabType } from "../types/plans-filters-tab-type.type";

interface Props {
  onSelectedTabChanged?: (tab: PlanFiltersTabType) => void;
}

export default function PlansFilters({ onSelectedTabChanged }: Props) {
  const [selectedTab, setSelectedTab] = useState(
    PlanFiltersTabType.Subscription
  );
  const planSubscriptionsTotal = useSelector(
    selectPlanSubscriptionsPagination
  ).total;
  const isGetPlanSubscriptionsPending = useSelector(
    selectIsGetPlanSubscriptionsPending
  );
  const planUpgradeRequestsTotal = useSelector(
    selectGetPlanUpgradeRequests
  ).length;
  const isGetPlanUpgradeRequestsPending = useSelector(
    selectIsGetPlanUpgradeRequestsPending
  );

  const handleSelectedTabChanged = (tab: PlanFiltersTabType) => {
    setSelectedTab(tab);
    onSelectedTabChanged?.(tab);
  };

  return (
    <ToggleBtnGroup
      items={[
        {
          label: `${PlanFiltersTabTypeText[PlanFiltersTabType.Subscription]} 
          (${isGetPlanSubscriptionsPending ? "?" : planSubscriptionsTotal})`,
          value: PlanFiltersTabType.Subscription,
        },
        {
          label: `${PlanFiltersTabTypeText[PlanFiltersTabType.Upgrade]} 
          (${
            isGetPlanUpgradeRequestsPending ? "?" : planUpgradeRequestsTotal
          })`,
          value: PlanFiltersTabType.Upgrade,
        },
      ]}
      selectedValue={selectedTab}
      onSelectedValueChanged={handleSelectedTabChanged}
    />
  );
}
