import classNames from "classnames";
import { JSXElementConstructor, useState } from "react";
import { Notification, NotificationType } from "../../../types";
import { NotificationTypeText } from "../../../utils/coimex-utils";
import { fromNow } from "../../../utils/dt-utils";
import { NotificationTemplateProps } from "../types/notification-template.type";
import CompanyUnderReviewNotification from "./templates/CompanyUnderReviewNotification";
import { Button } from "@mui/material";
import OpportunityUnderReviewNotification from "./templates/OpportunityUnderReviewNotification";
import PlanUpgradeRequestUnderReviewNotification from "./templates/PlanUpgradeRequestUnderReviewNotification";
import PromptPayRequestUnderReviewNotification from "./templates/PromptPayRequestUnderReviewNotification";

interface Props {
  notification: Notification;
  notSeen: boolean;
}

const TEMPLATES: Partial<
  Record<
    NotificationType,
    {
      component: JSXElementConstructor<NotificationTemplateProps>;
      isExpandable?: boolean;
    }
  >
> = {
  CompanyUnderReview: {
    component: CompanyUnderReviewNotification,
  },
  OpportunityUnderReview: {
    component: OpportunityUnderReviewNotification,
    isExpandable: true,
  },
  PlanUpgradeRequestUnderReview: {
    component: PlanUpgradeRequestUnderReviewNotification,
  },
  PromptPayRequestUnderReview: {
    component: PromptPayRequestUnderReviewNotification,
  },
};

export default function NotificationWrapper({ notification, notSeen }: Props) {
  const [isExpanded, setIsExpanded] = useState(false);
  const template = TEMPLATES[notification.type];
  const Component = template?.component;

  return (
    <div className="flex flex-col gap-2 border rounded-lg px-4 pt-3 pb-2">
      <div className="flex justify-between items-center">
        <h4 className="text-[15px] font-medium">
          {NotificationTypeText[notification.type] ?? notification.type}
        </h4>
        <div
          className={classNames("w-2.5 h-2.5 mb-0.5 rounded-full", {
            "bg-red-600": notSeen,
            "bg-slate-300": !notSeen,
          })}
        />
      </div>

      <div className="flex-1 text-sm">
        {Component ? (
          <Component payload={notification.payload} isExpanded={isExpanded} />
        ) : (
          notification.fallback?.text
        )}
      </div>

      {template?.isExpandable && (
        <Button
          className="self-start -ml-1 -mt-1 -mb-2.5"
          size="small"
          color="info"
          onClick={() => setIsExpanded((isExpanded) => !isExpanded)}
        >
          Show {isExpanded ? "less" : "more"}
        </Button>
      )}

      <div>
        <span className="text-[13px] text-text-1">
          {fromNow(notification.createdAt)}
        </span>
      </div>
    </div>
  );
}
