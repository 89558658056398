import { Logout } from "@mui/icons-material";
import {
  Divider,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  PopoverProps,
  Switch,
} from "@mui/material";
import { authActions } from "../../modules/auth/auth.state";
import { useDispatch, useSelector } from "react-redux";
import { selectMe } from "../../modules/auth/auth.selectors";
import { useState } from "react";
import { useModal } from "../../providers/ModalProvider";
import EnableTwoFactorForm from "./EnableTwoFactorForm";
import { useSnackbar } from "notistack";
import DisableTwoFactorForm from "./DisableTwoFactorForm";

interface IProps {
  open: boolean;
  anchorEl: PopoverProps["anchorEl"];
  onClose: () => void;
}

export default function ProfileMenu({ open, anchorEl, onClose }: IProps) {
  const dispatch = useDispatch();
  const me = useSelector(selectMe);
  const [twoFactorEnabled, setTwoFactorEnabled] = useState(
    me?.twoFactorEnabled
  );
  const openModal = useModal();
  const { enqueueSnackbar } = useSnackbar();

  const handleSignOut = () => {
    onClose();
    dispatch(authActions.logout());
  };

  const handle2faChange = async (enabled: boolean) => {
    setTwoFactorEnabled(enabled);
    const result: { twoFactorEnabled: boolean } = await openModal(
      (props) =>
        enabled ? (
          <EnableTwoFactorForm {...props} />
        ) : (
          <DisableTwoFactorForm {...props} />
        ),
      {
        closeOnClickOutside: false,
        hideCloseButton: true,
      }
    );
    if (result) {
      dispatch(authActions.twoFactorEnabledChanged(result));
      enqueueSnackbar(
        `2FA ${result.twoFactorEnabled ? "enabled" : "disabled"} successfully.`,
        { variant: "info" }
      );
    } else {
      setTwoFactorEnabled(!enabled);
    }
    onClose();
  };

  return (
    <Menu id="basic-menu" anchorEl={anchorEl} open={open} onClose={onClose}>
      <MenuItem className="w-[140px] flex justify-between">
        <ListItemText>2FA</ListItemText>
        <ListItemIcon>
          <Switch
            size="small"
            checked={twoFactorEnabled}
            onChange={(_, checked) => handle2faChange(checked)}
          />
        </ListItemIcon>
      </MenuItem>
      <Divider />
      <MenuItem
        className="w-[140px] flex justify-between"
        onClick={handleSignOut}
      >
        <ListItemText>Sign out</ListItemText>
        <ListItemIcon>
          <Logout className="ml-3" fontSize="small" />
        </ListItemIcon>
      </MenuItem>
    </Menu>
  );
}
