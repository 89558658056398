import {
  AutoAwesomeMotionOutlined,
  AutoModeOutlined,
  BusinessOutlined,
  DashboardOutlined,
  FileDownloadOutlined,
  FileUploadOutlined,
  LinkOutlined,
  PaidOutlined,
  SettingsOutlined,
} from "@mui/icons-material";
import CommodityTokenSvg from "../components/Svg/CommodityTokenSvg";
import InvestmentPoolSvg from "../components/Svg/InvestmentPoolSvg";

export interface MenuItem {
  label: string;
  link: string;
  renderIcon: () => any;
}

export const Menu: MenuItem[][] = [
  [
    {
      label: "Dashboard",
      link: "/",
      renderIcon: () => <DashboardOutlined />,
    },
    {
      label: "Companies",
      link: "/companies",
      renderIcon: () => <BusinessOutlined />,
    },
    {
      label: "Payments",
      link: "/payments",
      renderIcon: () => <PaidOutlined />,
    },
    {
      label: "Offers",
      link: "/offers",
      renderIcon: () => <FileUploadOutlined />,
    },
    {
      label: "Requests",
      link: "/requests",
      renderIcon: () => <FileDownloadOutlined />,
    },
  ],
  [
    {
      label: "Plans",
      link: "/plans",
      renderIcon: () => <AutoAwesomeMotionOutlined />,
    },
  ],
  [
    {
      label: "Smart Deals",
      link: "/smart-deals",
      renderIcon: () => <LinkOutlined />,
    },
    {
      label: "Commodity Tokens",
      link: "/commodity-tokens",
      renderIcon: () => <CommodityTokenSvg />,
    },
    {
      label: "Investment Pools",
      link: "/investment-pools",
      renderIcon: () => <InvestmentPoolSvg />,
    },
  ],
  [
    {
      label: "Schedules",
      link: "/schedules",
      renderIcon: () => <AutoModeOutlined />,
    },
  ],
];

export const SettingsMenu = {
  label: "Settings",
  link: "/settings",
  renderIcon: () => <SettingsOutlined />,
};
