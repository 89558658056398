import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import { useState } from "react";
import "./Tabs.scss";

interface IProps<T> {
    items: T[];
    renderItem: (item: T) => JSX.Element;
    onTabChange: (index: number) => void;
}

export default function Tabs<T extends unknown>({
    items,
    renderItem,
    onTabChange,
}: IProps<T>) {
    const [selectedTab, setSelectedTab] = useState(0);

    const handleTabChange = (tab) => {
        setSelectedTab(tab);
        onTabChange(tab);
    };

    return (
        <div className="Tabs">
            <ToggleButtonGroup
                color="secondary"
                exclusive
                value={selectedTab}
                onChange={(_, tab) => handleTabChange(tab)}
            >
                {items.map((item, i) => (
                    <ToggleButton key={i} value={i}>{renderItem(item)}</ToggleButton>
                ))}
            </ToggleButtonGroup>
        </div>
    );
}
