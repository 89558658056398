import { yupResolver } from "@hookform/resolvers/yup";
import { LoadingButton } from "@mui/lab";
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { useEffect, useMemo, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import useFocus from "../../../hooks/use-focus";
import { commodityTokenApi } from "../../../http/commodity-tokens.api";
import { CommodityToken } from "../../../types";
import { formatNumberWithSeparator } from "../../../utils/number-utils";

interface IProps {
  token: CommodityToken;
  close?: (modified?: boolean) => void;
}

export default function SetCommodityTokenMinMaxAmountForm({
  token,
  close,
}: IProps) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const inputRef = useRef<any>();
  useFocus(inputRef);
  const { enqueueSnackbar } = useSnackbar();
  const symbols = useMemo<CommodityToken["symbol"][]>(
    () => Object.keys(token.prices),
    [token.prices]
  );
  const validationSchema = Yup.object()
    .shape({
      symbol: Yup.string().required(),
      minAmount: Yup.number()
        .required()
        .test("min", function (value) {
          return value > 0;
        }),
      maxAmount: Yup.number()
        .required()
        .test("min", function (value) {
          return value > 0;
        }),
    })
    .test(
      "amounts",
      "Min amount should not be more than Max amount",
      function (values) {
        const { minAmount, maxAmount } = values;
        if (minAmount > maxAmount) {
          return this.createError({
            path: "minAmount",
            message: "Min amount should not be more than Max amount",
          });
        }
        return true;
      }
    );
  const form = useForm({
    resolver: yupResolver(validationSchema),
    mode: "onSubmit",
    defaultValues: {
      symbol: Object.keys(token.prices)?.[0],
      minAmount: null,
      maxAmount: null,
    },
  });
  const symbol = form.watch("symbol");
  const minAmount = +(form.watch("minAmount") || undefined);
  const maxAmount = +(form.watch("maxAmount") || undefined);
  const selectedToken = token.prices[symbol];

  const onSubmit = async ({ symbol, minAmount, maxAmount }) => {
    try {
      setIsSubmitting(true);
      await commodityTokenApi.setTokenMinMaxAmount(
        token.address,
        token.prices[symbol].address,
        minAmount,
        maxAmount
      );
      close(true);
      enqueueSnackbar("Token min-max amount was set successfully", {
        variant: "info",
      });
    } catch {
      setIsSubmitting(false);
    }
  };

  useEffect(() => {
    if (selectedToken) {
      form.setValue("minAmount", selectedToken.minAmount);
      form.setValue("maxAmount", selectedToken.maxAmount);
    }
  }, [selectedToken]);

  const renderChange = (oldAmount: number, newAmount: number) => {
    const change = ((newAmount - oldAmount) * 100) / oldAmount;
    return (
      <div className="mt-2 ml-3.5 flex flex-col text-[13px] opacity-70">
        <div className="grid grid-cols-[80px_1fr]">
          Old amount:
          <span>{formatNumberWithSeparator(oldAmount)}</span>
        </div>

        <div className="grid grid-cols-[80px_1fr]">
          <span>Change:</span>
          <span>
            {change > 0 ? "+" : change < 0 ? "-" : ""}
            {isNaN(newAmount) ? "" : (newAmount - oldAmount).toFixed(3)}
          </span>
        </div>
      </div>
    );
  };

  return (
    <form className="p-4 w-[420px]" onSubmit={form.handleSubmit(onSubmit)}>
      <header className="modal-header text-lg">
        Set {token.symbol}-{token.name} Min-Max Amount
      </header>

      <div className="flex flex-col gap-8">
        <FormControl fullWidth size="small" variant="filled" required>
          <InputLabel>Token</InputLabel>
          <Select
            label="Token"
            {...form.register("symbol")}
            defaultValue={symbols?.[0]}
            error={!!form.formState.errors?.symbol}
          >
            {symbols.map((symbol) => (
              <MenuItem key={symbol} value={symbol}>
                {symbol}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <div className="flex gap-4">
          <div>
            <TextField
              inputRef={inputRef}
              className="w-full"
              {...form.register("minAmount")}
              inputMode="decimal"
              label="Min Amount"
              variant="filled"
              size="small"
              error={!!form.formState.errors?.minAmount}
            />

            {selectedToken && renderChange(selectedToken.minAmount, minAmount)}
          </div>

          <div>
            <TextField
              className="w-full"
              {...form.register("maxAmount")}
              inputMode="decimal"
              label="Max Amount"
              variant="filled"
              size="small"
              error={!!form.formState.errors?.maxAmount}
            />

            {selectedToken && renderChange(selectedToken.maxAmount, maxAmount)}
          </div>
        </div>
      </div>

      <footer className="modal-footer flex flex-row-reverse gap-4">
        <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
          Save
        </LoadingButton>
        <Button type="button" variant="text" onClick={() => close(false)}>
          Cancel
        </Button>
      </footer>
    </form>
  );
}
