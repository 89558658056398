import moment from "moment";

export function getElapsedTime(dt: moment.MomentInput) {
  return moment(dt).fromNow().capitalizeFirstLetter();
}

export function fromNow(dt: moment.MomentInput): string {
  return dt ? moment(dt).fromNow() : null;
}

export function getAge(dt: moment.MomentInput): string {
  return moment(dt).fromNow(true);
}
