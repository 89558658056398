import { Paper } from "@mui/material";
import Carousel from "react-multi-carousel";
import useImagePreview from "../../hooks/use-image-preview";

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 6,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 6,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 5,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 4,
  },
};

interface IProps {
  urls: string[];
}

export default function ImageSlider({ urls }: IProps) {
  const openImagePreviewer = useImagePreview();

  return urls?.length ? (
    <Carousel
      className="p-1"
      responsive={responsive}
      autoPlay={false}
      swipeable={false}
      draggable={false}
    >
      {urls.map((url, index) => (
        <Paper
          className="w-20 h-24 flex justify-center items-center overflow-hidden cursor-pointer"
          elevation={1}
          key={url}
          onClick={() => openImagePreviewer(urls, index)}
        >
          <img className="w-full h-full" src={url} />
        </Paper>
      ))}
    </Carousel>
  ) : (
    <></>
  );
}
