import { AddOutlined } from "@mui/icons-material";
import classNames from "classnames";
import ContractCurrency from "../ContractCurrency/ContractCurrency";

interface IProps {
  availablePaymentBalance: number;
  availableFeeBalance: number;
  paymentTokenId: string;
  feeTokenId: string;
  paymentTokenIcon: string;
  feeTokenIcon: string;
  className?: string;
}

export default function ContractAvailableBalance({
  availablePaymentBalance,
  availableFeeBalance,
  paymentTokenId,
  feeTokenId,
  paymentTokenIcon,
  feeTokenIcon,
  className,
}: IProps) {
  return (
    <span className={classNames(className, "flex gap-2 items-center")}>
      <ContractCurrency
        icon={paymentTokenIcon}
        amount={availablePaymentBalance}
      />
      {feeTokenId != paymentTokenId && (
        <>
          <AddOutlined className="opacity-75" fontSize="small" />
          <ContractCurrency icon={feeTokenIcon} amount={availableFeeBalance} />
        </>
      )}
    </span>
  );
}
