import { createContext, useCallback, useContext } from "react";
import { useModal } from "../../../providers/ModalProvider";
import BlockchainEvents from "../components/BlockchainEvents";
import { BlockchainEvent, BlockchainTx } from "../types";

interface IProps {
  showEvents: (tx: BlockchainTx, targetHash?: string) => void;
}

export const BlockchainEventsModalContext = createContext<IProps>(null);

export default function BlockchainEventsModalProvider({ children }) {
  const openModal = useModal();

  const showEvents: IProps["showEvents"] = useCallback((tx, targetHash) => {
    openModal(
      (props) => (
        <BlockchainEvents
          title={`${tx.type.toSplitCase()} Tx Events`}
          events={tx.events}
          targetHash={targetHash}
          {...props}
        />
      ),
      { closeOnClickOutside: true }
    );
  }, []);

  return (
    <BlockchainEventsModalContext.Provider value={{ showEvents }}>
      {children}
    </BlockchainEventsModalContext.Provider>
  );
}

export function useBlockchainEventsModal() {
  const { showEvents } = useContext(BlockchainEventsModalContext);
  return showEvents;
}
