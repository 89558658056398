import {
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator,
} from "@mui/lab";
import classNames from "classnames";
import { PropsWithChildren, ReactNode } from "react";
import BlockchainTxStatusIndicator from "../../../components/BlockchainTxStatusIndicator/BlockchainTxStatusIndicator";
import { BlockchainTxStatus } from "../types";

interface IProps {
  title?: string;
  timestamp?: string;
  status?: BlockchainTxStatus;
  renderTitle?: () => ReactNode;
  hideConnector?: boolean;
  className?: string;
}

export default function BlockchainFlowItem({
  title,
  timestamp,
  status,
  renderTitle,
  className,
  hideConnector,
  children,
}: PropsWithChildren<IProps>) {
  return (
    <TimelineItem>
      <TimelineOppositeContent>{timestamp}</TimelineOppositeContent>
      <TimelineSeparator>
        <TimelineDot />
        {!hideConnector && <TimelineConnector />}
      </TimelineSeparator>
      <TimelineContent>
        {renderTitle ? (
          renderTitle()
        ) : (
          <div className="flex items-center gap-1">
            <span className="flex-[0_0_210px] text-text">{title}</span>
            {status && (
              <BlockchainTxStatusIndicator status={status} hideTitle />
            )}
          </div>
        )}
        <div
          className={classNames("text-sm mt-2", { "ml-2": !!title }, className)}
        >
          {children}
        </div>
      </TimelineContent>
    </TimelineItem>
  );
}
