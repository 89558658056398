import { MenuItem, Select, SelectProps } from "@mui/material";

interface IProps<T> {
    items: {
        label: string;
        value: T;
    }[];
    selectedValue: T;
    onValueChange: (value: T) => void;
}

export default function WidgetSelect<T extends unknown>({
    items,
    selectedValue,
    onValueChange,
    ...rest
}: IProps<T> & SelectProps) {
    return (
        <div className="WidgetSelect">
            <Select
                value={selectedValue}
                size="small"
                onChange={(e) => {
                    onValueChange(e.target.value as T);
                }}
                {...rest}
            >
                {items.map((item) => (
                    <MenuItem key={item.value as any} value={item.value as any}>
                        {item.label}
                    </MenuItem>
                ))}
            </Select>
        </div>
    );
}
