import { Chip } from "@mui/material";

interface IProps {
  isVerified: boolean;
}

export default function VerificationStatusChip({ isVerified }: IProps) {
  return (
    <Chip
      label={isVerified ? "Verified" : "Not Verified"}
      color={isVerified ? "success" : "error"}
      size="small"
      variant="outlined"
    />
  );
}
