import { yupResolver } from "@hookform/resolvers/yup";
import { LoadingButton } from "@mui/lab";
import { Button, Divider, TextField } from "@mui/material";
import { useRef, useState } from "react";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import useFocus from "../../../hooks/use-focus";
import { CommodityToken } from "../../../types";
import { commodityTokenApi } from "../../../http/commodity-tokens.api";
import { useSnackbar } from "notistack";
import { formatNumberWithSeparator } from "../../../utils/number-utils";

interface IProps {
  token: CommodityToken;
  close?: (modified?: boolean) => void;
}

const validationSchema = Yup.object().shape({
  amount: Yup.number().required("This field is required.").min(1),
});

export default function CommodityAddTokenSupplyForm({ token, close }: IProps) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const inputRef = useRef<any>();
  useFocus(inputRef);
  const form = useForm({
    resolver: yupResolver(validationSchema),
    mode: "onSubmit",
  });
  const { enqueueSnackbar } = useSnackbar();
  const amount = form.watch("amount") || 0;

  const onSubmit = async ({ amount }) => {
    try {
      setIsSubmitting(true);
      await commodityTokenApi.addTokenSupply(token.address, amount);
      close(true);
      enqueueSnackbar("Token supply was increased successfully", {
        variant: "info",
      });
    } catch {
      setIsSubmitting(false);
    }
  };

  return (
    <form className="p-4 w-[320px]" onSubmit={form.handleSubmit(onSubmit)}>
      <header className="modal-header text-lg">
        Add {token.symbol}-{token.name} Supply
      </header>

      <TextField
        inputRef={inputRef}
        className="w-full"
        {...form.register("amount")}
        type="number"
        inputMode="decimal"
        label="Amount"
        variant="filled"
        size="small"
        helperText={form.formState.errors.amount?.message}
        error={!!form.formState.errors?.amount}
      />

      <div className="mt-4 flex flex-col text-sm opacity-75">
        <div className="flex">
          <span className="w-[105px]">Current supply:</span>
          {formatNumberWithSeparator(token.totalSupply)}
        </div>
        <div className="flex">
          <span className="w-[105px]">Change:</span>
          {amount > 0 ? "+" : amount < 0 ? "-" : ""}
          {formatNumberWithSeparator(amount)}
        </div>
        <Divider className="w-full my-1" />
        <div className="flex">
          <span className="w-[105px]">New supply:</span>
          {formatNumberWithSeparator(token.totalSupply + Number(amount))}
        </div>
      </div>

      <footer className="modal-footer flex flex-row-reverse gap-4">
        <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
          Save
        </LoadingButton>
        <Button type="button" variant="text" onClick={() => close(false)}>
          Cancel
        </Button>
      </footer>
    </form>
  );
}
