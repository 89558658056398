import { AddOutlined } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { Button } from "@mui/material";
import { useSnackbar } from "notistack";
import { useState } from "react";
import ContractAvailableBalance from "../../../../components/ContractAvailableBalance/ContractAvailableBalance";
import ContractCurrency from "../../../../components/ContractCurrency/ContractCurrency";
import { blockchainApi } from "../../../../http/blockchain.api";
import { CloseModal } from "../../../../providers/ModalProvider";
import { Payment } from "../../../../types";
import {
  SmartDealPaymentActionText,
  isBalanceSufficientForSmartDealPaymentAction,
} from "../../../../utils/coimex-utils";
import {
  ProformaInvoice,
  SmartDealPaymentAction,
  SmartDealPaymentActionType,
  SmartDealPaymentBlockchainInfo,
} from "../../types";

export interface IProps {
  id: Payment["id"];
  type: SmartDealPaymentActionType;
  proformaInvoice: ProformaInvoice;
  paymentInfo: SmartDealPaymentBlockchainInfo;
  close: CloseModal<SmartDealPaymentAction>;
}

export default function SmartDealPaymentFlowConfirm({
  id,
  type,
  proformaInvoice,
  paymentInfo,
  close,
}: IProps) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const title = SmartDealPaymentActionText[type];
  const isBalanceSufficient = isBalanceSufficientForSmartDealPaymentAction(
    type,
    paymentInfo,
    proformaInvoice
  );
  const { enqueueSnackbar } = useSnackbar();

  const handleSubmit = async () => {
    try {
      setIsSubmitting(true);
      const action = await blockchainApi.executeSmartDealPaymentAction(
        id,
        type
      );
      close(action);
      enqueueSnackbar(`${title} transaction sent successfully.`, {
        variant: "info",
      });
    } catch {
      setIsSubmitting(false);
    }
  };

  const renderRequiredBalance = () => {
    switch (type) {
      case SmartDealPaymentActionType.ReleaseUpfrontPayment:
      case SmartDealPaymentActionType.RevertUpfrontPayment:
        return (
          <span className="flex items-center gap-2">
            <ContractCurrency
              amount={proformaInvoice.deposit}
              icon={proformaInvoice.paymentTokenIcon}
            />
            {paymentInfo.fixedFeePaid ? null : (
              <>
                <AddOutlined className="opacity-75" fontSize="small" />
                <ContractCurrency
                  amount={proformaInvoice.fixedFee}
                  icon={proformaInvoice.feeTokenIcon}
                />
                Fee{" "}
              </>
            )}
          </span>
        );

      case SmartDealPaymentActionType.ReleaseFullPayment:
        return (
          <span className="flex items-center gap-2">
            <ContractCurrency
              amount={proformaInvoice.balance}
              icon={proformaInvoice.paymentTokenIcon}
            />
            {paymentInfo.commissionFeePaid ? null : (
              <>
                <AddOutlined className="opacity-75" fontSize="small" />
                <ContractCurrency
                  amount={proformaInvoice.commissionFee}
                  icon={proformaInvoice.feeTokenIcon}
                />
                Fee
              </>
            )}
          </span>
        );

      case SmartDealPaymentActionType.RevertFullPayment:
        return (
          <span className="flex items-center gap-2">
            <ContractCurrency
              amount={proformaInvoice.balance}
              icon={proformaInvoice.paymentTokenIcon}
            />
          </span>
        );
    }
  };

  return (
    <div className="p-4" style={{ width: "500px" }}>
      <header className="modal-header">{title}</header>

      <div className="flex flex-col gap-2">
        Are you sure you want to {title}?
        <div className="-mt-1 text-red-700 font-medium">
          You can't undo this action.
        </div>
        <div className="mt-4 flex items-center gap-1">
          <span className="basis-[140px]">Available Balance:</span>
          <ContractAvailableBalance {...(paymentInfo as any)} />
        </div>
        <div className="flex items-center gap-1 mb-2">
          <span className="basis-[140px]">Required Balance:</span>{" "}
          {renderRequiredBalance()}
        </div>
        {isBalanceSufficient ? (
          <div className="text-green-600 font-medium">
            Buyer has sufficient balance
          </div>
        ) : (
          <div className="text-red-700 font-medium">
            Buyer's balance is not sufficient!
          </div>
        )}
      </div>

      <footer className="modal-footer flex justify-end gap-4">
        <Button type="button" variant="text" onClick={() => close()}>
          Close
        </Button>
        <LoadingButton
          type="submit"
          variant="contained"
          color="secondary"
          disabled={!isBalanceSufficient}
          loading={isSubmitting}
          onClick={handleSubmit}
        >
          Proceed
        </LoadingButton>
      </footer>
    </div>
  );
}
