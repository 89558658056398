import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { getPaymentsAction, getPaymentsStatusCountsAction } from "./payments.effects";

export const selectPaymentsState = (state: RootState) => state.payments;

export const selectPayments = createSelector(
    selectPaymentsState,
    state => state.payments || []
)

export const selectIsGetPaymentsPending = createSelector(
    selectPaymentsState,
    state => state[getPaymentsAction.pending.type]
)

export const selectPaymentsPagination = createSelector(
    selectPaymentsState,
    state => state.pagination
)

export const selectPaymentsStatusCounts = createSelector(
    selectPaymentsState,
    state => state.statusCounts
)

export const selectIsGetPaymentsStatusCountsPending = createSelector(
    selectPaymentsState,
    state => state[getPaymentsStatusCountsAction.pending.type]
)

export const selectPaymentsFilters = createSelector(
    selectPaymentsState,
    state => state.filters
)

export const selectPaymentsStatusFilter = createSelector(
    selectPaymentsFilters,
    filters => filters.status
)

export const selectPaymentsSearchFilter = createSelector(
    selectPaymentsFilters,
    filters => filters.search
)

export const selectPaymentsOrderByFilter = createSelector(
    selectPaymentsFilters,
    filters => filters.orderBy
)