import { Chip } from "@mui/material";
import { PurposeOfUse } from "../../types";
import { getPurposeOfUseColor } from "../../utils/coimex-utils";
import { renderPurposeOfUseArrowIcon } from "../../utils/tsx-utils";

interface IProps {
  purposeOfUse: PurposeOfUse;
}

export default function PurposeOfUseChip({ purposeOfUse }: IProps) {
  return (
    <Chip
      icon={renderPurposeOfUseArrowIcon(purposeOfUse)}
      label={purposeOfUse}
      color={getPurposeOfUseColor(purposeOfUse)}
      size="small"
    />
  );
}
