import { ArrowBack } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { FormControl, Input, InputLabel, Typography } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import useFocus from "../../../hooks/use-focus";
import { authApi } from "../../../http";
import { selectCredentialsTemp } from "../auth.selectors";
import { authActions } from "../auth.state";

export default function TwoFactorPage() {
  const credentialsTemp = useSelector(selectCredentialsTemp);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const tokenRef = useRef<HTMLInputElement>();
  useFocus(tokenRef);

  const onSubmit = async (data) => {
    try {
      setIsSubmitting(true);
      const response = await authApi.verify2fa(
        credentialsTemp.username,
        credentialsTemp.password,
        data.token
      );
      dispatch(authActions.signInSuccess(response));
    } catch (error) {
    } finally {
      setIsSubmitting(false);
    }
  };

  useEffect(() => {
    !credentialsTemp && navigate("/login");
  }, [credentialsTemp]);

  return (
    <>
      <Typography className="w-full mb-2">
        Enter authentication code from your authenticator app
      </Typography>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormControl className="w-full" variant="standard">
          <InputLabel htmlFor="token">Authentication code</InputLabel>
          <Input
            {...register("token", {
              required: true,
            })}
            inputRef={tokenRef}
            error={!!errors.token}
          />
        </FormControl>

        <LoadingButton
          className="mt-4 float-right text-xs"
          type="submit"
          color="info"
          size="small"
          loading={isSubmitting}
        >
          Verify
        </LoadingButton>
        <LoadingButton
          className="mt-4 float-left text-xs"
          color="inherit"
          size="small"
          onClick={() => navigate(-1)}
          tabIndex={-1}
          startIcon={<ArrowBack />}
        >
          Back
        </LoadingButton>
      </form>
    </>
  );
}
