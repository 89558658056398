import { Contract, JsonRpcProvider } from "ethers";
import EventEmitter from "events";
import { createContext, useContext, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { isInClosingState } from "../../../utils/blockchain-utils";
import { selectBlockchainConfigs } from "../../settings/settings.selectors";

interface ContextProps {
  onPaymentTransfer: EventEmitter;
  onFeeTransfer: EventEmitter;
}

const abi = [
  "event Transfer(address indexed from, address indexed to, uint256 value)",
];

const BlockchainTransferEventContext = createContext<ContextProps>(null);

export default function BlockchainTransferEventProvider({ children }) {
  const blockchainConfigs = useSelector(selectBlockchainConfigs);
  const onPaymentTransfer = useMemo(() => new EventEmitter(), []);
  const onFeeTransfer = useMemo(() => new EventEmitter(), []);
  const provider = useMemo(
    () =>
      blockchainConfigs?.providerUrlRpc
        ? new JsonRpcProvider(blockchainConfigs.providerUrlRpc)
        : null,
    [blockchainConfigs?.providerUrlRpc]
  );
  const paymentTokenContract = useMemo(
    () =>
      blockchainConfigs?.paymentTokenContractAddress && provider
        ? new Contract(
            blockchainConfigs.paymentTokenContractAddress,
            abi,
            provider
          )
        : null,
    [blockchainConfigs?.paymentTokenContractAddress, provider]
  );
  const feeTokenContract = useMemo(
    () =>
      blockchainConfigs?.feeTokenId != blockchainConfigs?.paymentTokenId &&
      blockchainConfigs?.feeTokenContractAddress &&
      provider
        ? new Contract(blockchainConfigs.feeTokenContractAddress, abi, provider)
        : null,
    [
      blockchainConfigs?.feeTokenId,
      blockchainConfigs?.paymentTokenId,
      blockchainConfigs?.feeTokenContractAddress,
      provider,
    ]
  );

  useEffect(() => {
    paymentTokenContract?.on("Transfer", (from, to, amount, event) => {
      onPaymentTransfer.emit(from, { from, to, amount, event });
      onPaymentTransfer.emit(to, { from, to, amount, event });
    });

    return () => {
      !isInClosingState(provider) && paymentTokenContract?.removeAllListeners();
    };
  }, [paymentTokenContract]);

  useEffect(() => {
    feeTokenContract?.on("Transfer", (from, to, amount, event) => {
      onFeeTransfer.emit(from, { from, to, amount, event });
      onFeeTransfer.emit(to, { from, to, amount, event });
    });

    return () => {
      !isInClosingState(provider) && feeTokenContract?.removeAllListeners();
    };
  }, [feeTokenContract]);

  useEffect(() => {
    return () => {
      provider?.removeAllListeners();
    };
  }, [provider]);

  return (
    <BlockchainTransferEventContext.Provider
      value={{ onPaymentTransfer, onFeeTransfer }}
    >
      {children}
    </BlockchainTransferEventContext.Provider>
  );
}

export const useBlockchainTransferEvent = () =>
  useContext(BlockchainTransferEventContext);
