import api from ".";
import { CompaniesCountryDistribution } from "../modules/dashboard/types/companies-country-distribution.type";
import { CurrentPrecedingStatistic } from "../modules/dashboard/types/current-preceding-statistic.type";
import { FindOutWhy, FindOutWhyType } from "../modules/dashboard/types/find-out-why.type";
import { OpportunitiesStatistics } from "../modules/dashboard/types/opportunities-statistics.type";
import { PlanSubscriptionStatistic } from "../modules/dashboard/types/plan-subscription-statistic.type";
import { TopCompany } from "../modules/dashboard/types/top-company.type";
import { PurposeOfUse, TimeWindow } from "../types";

export const dashboardApi = {
    getCompaniesCountryDistribution: async (): Promise<CompaniesCountryDistribution[]> => {
        return (await api.get('/dashboard/companies-country-distribution')).data;
    },
    getCurrentPrecedingStatistics: async (days: number): Promise<CurrentPrecedingStatistic[]> => {
        return (await api.get('/dashboard/current-preceding-statistics', {
            params: { days }
        })).data;
    },
    getPlanSubscriptionStatistics: async (days: TimeWindow): Promise<PlanSubscriptionStatistic[]> => {
        return (await api.get('/dashboard/plan-subscription-statistics', {
            params: { days }
        })).data;
    },
    getOpportunitiesStatistics: async (days: number): Promise<OpportunitiesStatistics> => {
        return (await api.get('/dashboard/opportunities-statistics', {
            params: { days }
        })).data;
    },
    getFindOutWhy: async (): Promise<FindOutWhy> => {
        return (await api.get('/dashboard/find-out-why')).data;
    },
    getFindOutWhyDetail: async (type: FindOutWhyType): Promise<any[]> => {
        return (await api.get(`/dashboard/find-out-why-detail/${type}`)).data;
    },
    getTopCompanies: async (type: Exclude<PurposeOfUse, 'Both'>, days: TimeWindow): Promise<TopCompany[]> => {
        return (await api.get(`/dashboard/top-companies/${type}`, {
            params: { days }
        })).data;
    }
}