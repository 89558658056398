import { Chip, Tooltip } from "@mui/material";
import { TransactionStatus } from "../../types";
import {
  getTransactionStatusColor,
  getTransactionStatusText,
} from "../../utils/coimex-utils";

interface IProps {
  status: TransactionStatus;
  message?: string;
  failedStage?: string;
}

export default function TransactionStatusChip({
  status,
  message,
  failedStage,
}: IProps) {
  return (
    <Tooltip title={message ?? ""}>
      <div className="flex items-center gap-1">
        <Chip
          label={getTransactionStatusText(status, failedStage!)}
          color={getTransactionStatusColor(status)}
          size="small"
          variant="filled"
        />
      </div>
    </Tooltip>
  );
}
