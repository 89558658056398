export function formatNumberCompact(
    n: number,
    options?: Partial<{
        maximumFractionDigits: number;
        minimumFractionDigits: number;
    }>
): string {
    const { maximumFractionDigits, minimumFractionDigits } = options || {};
    return n != null
        ? new Intl.NumberFormat('en-US', {
            maximumFractionDigits,
            minimumFractionDigits,
            notation: 'compact',
            compactDisplay: 'short',
            roundingMode: 'halfEven',
        } as Intl.NumberFormatOptions).format(n)
        : '';
}

export function formatNumberWithSeparator(
    number: number | string,
    options?: Partial<{
        thousandSeparator: string;
        minimumFractionDigits: number;
    }>
): string {
    number = Number(number);
    const { thousandSeparator, minimumFractionDigits } = {
        thousandSeparator: options?.thousandSeparator ?? ',',
        minimumFractionDigits: options?.minimumFractionDigits ?? 0,
    };
    const decimalSeparator: string = thousandSeparator === ',' ? '.' : ',';
    const numberString: string = Math.abs(number).toString();
    const parts: string[] = numberString.split('.');
    const integerPartWithSeparator: string =
        (number < 0 ? '-' : '') +
        parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, thousandSeparator);
    if (parts.length === 2) {
        const formattedNumber: string =
            integerPartWithSeparator +
            decimalSeparator +
            parts[1].padEnd(minimumFractionDigits, '0');
        return formattedNumber;
    } else {
        return (
            integerPartWithSeparator +
            (minimumFractionDigits
                ? decimalSeparator +
                [...Array(minimumFractionDigits)].map(() => 0).join('')
                : '')
        );
    }
}