import { AnyAction } from 'redux';
import { getCompaniesAction } from '../../companies/companies.effects';

const RaceActions: any[] = [
  getCompaniesAction
];
const validFulfilledIds = {};

const discardStaleActionsMiddleware =
  (store) => (next) => (action: AnyAction) => {
    const ra = RaceActions.find(
      (ra) =>
        action.type === ra.pending.type || action.type === ra.fulfilled.type
    );
    if (ra) {
      if (action.type === ra.pending.type) {
        validFulfilledIds[ra.fulfilled.type] = action.meta?.requestId;
      } else if (
        validFulfilledIds[ra.fulfilled.type] !== action.meta?.requestId
      ) {
        return;
      }
    }
    next(action);
  };

export default discardStaleActionsMiddleware;
