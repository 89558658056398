import { Business } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { Avatar, Button, TextField } from "@mui/material";
import { useSnackbar } from "notistack";
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import CurrencyFormat from "../../../components/CurrencyFormat/CurrencyFormat";
import ImageSlider from "../../../components/ImageSlider/ImageSlider";
import Loading from "../../../components/Loading/Loading";
import OpportunityStatusIndicator from "../../../components/OpportunityStatusIndicator/OpportunityStatusIndicator";
import { opportunitiesApi } from "../../../http";
import {
  Opportunity,
  OpportunityChangeStatus,
  OpportunityState,
} from "../../../types";
import { AppPage } from "../../../types/app-page.type";
import { AppRefreshStatus } from "../../../types/app-refresh-status.type";
import {
  canApproveOpportunity,
  canRejectOpportunity,
  getCurrencySymbol,
  toYesOrNoText,
} from "../../../utils/coimex-utils";
import { fromNow } from "../../../utils/dt-utils";
import { appActions } from "../../app/app.state";
import "./OfferPage.scss";

interface IProps {
  id: string;
  close?: (cancelNavigate?: boolean) => void;
}

export default function OfferPage({ id, close = () => {} }: IProps) {
  const [offer, setOffer] = useState<Opportunity | null>(null);
  const remarksRef = useRef<HTMLInputElement>();
  const [isSubmitting, setIsSubmitting] =
    useState<OpportunityChangeStatus | null>();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const getOffer = async () => {
    try {
      setOffer(await opportunitiesApi.getOpportunity(id));
    } catch (ignored) {
      close();
    }
  };

  const handleChangeStatus = async (status: OpportunityChangeStatus) => {
    try {
      setIsSubmitting(status);
      await opportunitiesApi.changeOpportunityStatus(
        offer!.id,
        status,
        remarksRef.current?.value!
      );
      close();
      dispatch(
        appActions.changeRefresh({
          page: AppPage.Offers,
          status: AppRefreshStatus.Invalidated,
        })
      );
      enqueueSnackbar(
        `Offer ${
          status === OpportunityChangeStatus.Approve ? "approved" : "rejected"
        } successfully.`,
        {
          variant: "info",
        }
      );
    } catch (error) {
      setIsSubmitting(null);
    }
  };

  const navigateToCompany = (companyId: string) => {
    if (companyId) {
      close(true);
      navigate(`companies/${companyId}`);
    }
  };

  useEffect(() => {
    id && getOffer();
  }, [id]);

  return offer ? (
    <div className="max-w-full p-4" style={{ width: "600px" }}>
      <header className="modal-header flex justify-between items-center pr-4">
        {offer?.productName}
        <OpportunityStatusIndicator status={offer.state} />
      </header>

      <div
        className="flex-1 overflow-auto flex flex-col gap-4"
        style={{ maxHeight: "calc(100vh - 240px)" }}
      >
        <section className="opp-info-section">
          <div className="mb-4">
            <ImageSlider urls={offer?.productPhotos!} />
          </div>
          <div className="info-section-item">
            <span>Product Details</span>
            <span className="info-section-item__value">
              {offer?.productDetails}
            </span>
          </div>
          <div className="info-section-item">
            <span>Created At</span>
            <span className="info-section-item__value">
              {fromNow(offer?.createdAt)}
            </span>
          </div>
          <div className="info-section-item">
            <span>Category</span>
            <span className="info-section-item__value">
              {offer?.subCategory?.name}
            </span>
          </div>
          <div className="info-section-item">
            <span>Looking for Buyers</span>
            <span className="info-section-item__value">
              {offer?.lookingSupply}
            </span>
          </div>
          <div className="info-section-item">
            <span>Company</span>
            <span
              className="link info-section-item__value"
              onClick={() => navigateToCompany(offer?.company?.id)}
            >
              <div className="flex items-center gap-x-2">
                <Avatar
                  src={offer?.company?.avatarUrl}
                  sx={{ width: 28, height: 28 }}
                >
                  <Business fontSize="small" />
                </Avatar>
                <span>{offer?.company?.name}</span>
              </div>
            </span>
          </div>
        </section>

        <section className="opp-info-section">
          <div className="info-section-item">
            <span>Price</span>
            <span className="info-section-item__value">
              <CurrencyFormat
                amount={offer?.price}
                symbol={getCurrencySymbol(offer.currency)}
              />
              {" / "}
              {offer.priceDescription}
            </span>
          </div>
          <div className="info-section-item">
            <span>Quantity</span>
            <span className="info-section-item__value">
              <CurrencyFormat amount={offer.quantity} /> {offer.quantityUnit}
            </span>
          </div>
          <div className="info-section-item">
            <span>Minimum Order</span>
            <span className="info-section-item__value">{offer?.minOrder}</span>
          </div>
          <div className="info-section-item">
            <span>Packaging</span>
            <span className="info-section-item__value">{offer?.packaging}</span>
          </div>
          <div className="info-section-item">
            <span>Specification</span>
            <span className="info-section-item__value">
              {offer?.specification}
            </span>
          </div>
          <div className="info-section-item">
            <span>Order Sample</span>
            <span className="info-section-item__value">
              {toYesOrNoText(offer?.orderSample)}
            </span>
          </div>
        </section>

        <section className="opp-info-section">
          <div className="info-section-item">
            <span>Payment Terms</span>
            <span className="info-section-item__value">
              {offer.paymentTerms}
            </span>
          </div>
          <div className="info-section-item">
            <span>Shipping Terms</span>
            <span className="info-section-item__value">
              {offer.shippingTerms}
            </span>
          </div>
          <div className="info-section-item">
            <span>Loading Port</span>
            <span className="info-section-item__value">
              {offer?.loadingPort}
            </span>
          </div>
          <div className="info-section-item">
            <span>Others</span>
            <span className="info-section-item__value">{offer?.others}</span>
          </div>
        </section>

        <section className="opp-info-section px-2">
          <TextField
            className="w-full"
            inputRef={remarksRef}
            defaultValue={offer.remarks}
            label="Remarks"
            multiline
            rows={2}
          />
        </section>
      </div>
      <footer className="modal-footer flex justify-between">
        {offer.state === OpportunityState.UnderReview ? (
          <Button variant="text" size="small" onClick={() => close()}>
            Review Later
          </Button>
        ) : (
          <div></div>
        )}
        <div className="flex gap-3">
          <LoadingButton
            variant="outlined"
            color="error"
            size="small"
            disabled={
              !canRejectOpportunity(offer.state) ||
              isSubmitting === OpportunityChangeStatus.Approve
            }
            loading={isSubmitting === OpportunityChangeStatus.Reject}
            onClick={() => handleChangeStatus(OpportunityChangeStatus.Reject)}
          >
            {offer.state === OpportunityState.Active ? "Disapprove" : "Reject"}
          </LoadingButton>
          <LoadingButton
            variant="contained"
            color="secondary"
            size="small"
            disabled={
              !canApproveOpportunity(offer.state) ||
              isSubmitting === OpportunityChangeStatus.Reject
            }
            loading={isSubmitting === OpportunityChangeStatus.Approve}
            onClick={() => handleChangeStatus(OpportunityChangeStatus.Approve)}
          >
            Approve
          </LoadingButton>
        </div>
      </footer>
    </div>
  ) : (
    <div className="p-12" style={{ width: "600px" }}>
      <Loading size={42} />
    </div>
  );
}
