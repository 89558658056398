import { createSlice } from "@reduxjs/toolkit";
import { DEFAULTS } from "../../common/constants";
import { InvestPoolMeta, InvestPoolType, Pagination } from "../../types";
import { addCommonReducers } from "../../utils/store-utils";
import { getInvestPoolsAction } from "./invest.effects";

export const INVEST_STATE_NAME = "invest";

export interface InvestState {
    [InvestPoolType.PromptPay]: {
        investPools: InvestPoolMeta[];
        pagination: Pagination;
    }
}

export const investState = createSlice({
    name: INVEST_STATE_NAME,
    initialState: {
        [InvestPoolType.PromptPay]: {
            investPools: [],
            pagination: {
                page: 0,
                pageSize: DEFAULTS.PageSize,
                total: 0,
            },
        },
        [getInvestPoolsAction.pending.type]: true,
    } as InvestState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getInvestPoolsAction.fulfilled, (state, action) => {
            const { type, paginatedInvestPools, page, pageSize } = action.payload;
            state[type] = {
                ...state[type],
                investPools: paginatedInvestPools.data,
                pagination: {
                    page,
                    pageSize,
                    total: paginatedInvestPools.total
                }
            };
        });
        addCommonReducers(builder, INVEST_STATE_NAME);
    },
});

export const investActions = investState.actions;
export const investReducer = investState.reducer;
