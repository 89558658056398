import classNames from "classnames";
import { OpportunityState } from "../../types";
import {
  getOpportunityStatusIndicatorColor,
  getOpportunityStatusIndicatorText,
} from "../../utils/coimex-utils";

interface IProps {
  status: OpportunityState;
}

export default function OpportunityStatusIndicator({ status }: IProps) {
  const color = getOpportunityStatusIndicatorColor(status);
  return (
    <div className="flex items-center gap-1 text-xs">
      <span className="mr-1">Status</span>
      <span
        className={classNames("rounded-full", "w-2", "h-2", `bg-${color}`)}
      ></span>
      <span className={classNames(`text-${color}`)}>
        {getOpportunityStatusIndicatorText(status)}
      </span>
    </div>
  );
}
