import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../store";

export const selectAppState = (state: RootState) => state.app;

export const selectRefresh = createSelector(
    selectAppState,
    state => state.refresh
)

export const selectRefreshPage = createSelector(
    selectRefresh,
    refresh => refresh.selectedPage
)

export const selectRefreshStatus = createSelector(
    selectRefresh,
    selectRefreshPage,
    (refresh, refreshPage) => refreshPage ? refresh.status[refreshPage] : null
)

export const selectRefreshUpdatedAt = createSelector(
    selectRefresh,
    refresh => refresh.selectedPage ? refresh.updatedAt[refresh.selectedPage] : null
)