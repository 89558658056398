import { createContext, useCallback, useEffect, useState } from "react";
import ImageViewer from "react-simple-image-viewer";

export type ImagePreviewProps = (
  urls: string[],
  index: number,
  title?: string
) => void;

export const ImagePreviewContext = createContext<ImagePreviewProps>(() => {});

export default function ImagePreviewProvider({ children }) {
  const [urls, setUrls] = useState<string[]>([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [title, setTitle] = useState("");
  const [isViewerOpen, setIsViewerOpen] = useState(false);

  const openImageViewer = useCallback(
    (urls: string[], index: number = 0, title?: string) => {
      setUrls(urls);
      setCurrentIndex(index);
      setTitle(title);
      setIsViewerOpen(true);
    },
    []
  );

  const closeImageViewer = () => {
    setCurrentIndex(0);
    setIsViewerOpen(false);
  };

  const handleEsc = useCallback((e) => {
    if (e.key === "Escape") {
      e.stopPropagation();
      closeImageViewer();
    }
  }, []);

  const handleMouseDown = useCallback((e) => {
    e.stopPropagation();
  }, []);

  useEffect(() => {
    if (isViewerOpen) {
      document.addEventListener("keydown", handleEsc, true);
      document.addEventListener("mousedown", handleMouseDown, true);
    }

    return () => {
      document.removeEventListener("keydown", handleEsc, true);
      document.removeEventListener("mousedown", handleMouseDown, true);
    };
  }, [isViewerOpen]);

  return (
    <ImagePreviewContext.Provider value={openImageViewer}>
      <>
        {children}
        {isViewerOpen && (
          <>
            <ImageViewer
              src={urls}
              currentIndex={currentIndex}
              disableScroll={false}
              closeOnClickOutside={true}
              onClose={closeImageViewer}
              backgroundStyle={{
                backgroundColor: "rgba(0,0,0,0.9)",
              }}
            />
            <span className="fixed inset-x-0 top-10 z-[2001] text-white text-3xl text-center">
              {title}
            </span>
          </>
        )}
      </>
    </ImagePreviewContext.Provider>
  );
}
