import { Search } from "@mui/icons-material";
import {
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useDebounce } from "usehooks-ts";
import ToggleBtnGroup from "../../../components/ToggleBtnGroup/ToggleBtnGroup";
import useSkipFirstRenderEffect from "../../../hooks/use-skip-first-render-effect";
import { OpportunityType } from "../../../types";
import {
  OpportunitiesStatusFilters,
  OpportunitiesStatusFilterText,
} from "../../../utils/coimex-utils";
import { selectSubCategories } from "../../settings/settings.selectors";
import {
  selectIsGetRequestsStatusCountsPending,
  selectRequestsSearchFilter,
  selectRequestsStatusCounts,
  selectRequestsStatusFilter,
  selectRequestsSubCategoryIdFilter,
} from "../requests.selectors";
import { getRequestsAction } from "../requets.effects";

export default function RequestsFilters() {
  const [search, setSearch] = useState("");
  const debouncedSearch = useDebounce(search, 500);
  const selectedStatus = useSelector(selectRequestsStatusFilter);
  const selectedSubCategoryId = useSelector(selectRequestsSubCategoryIdFilter);
  const statusCounts = useSelector(selectRequestsStatusCounts);
  const searchValue = useSelector(selectRequestsSearchFilter);
  const isGetRequestsStatusCountsPending = useSelector(
    selectIsGetRequestsStatusCountsPending
  );
  const subCategories = useSelector(selectSubCategories);
  const dispatch = useDispatch();

  useSkipFirstRenderEffect(() => {
    dispatch(getRequestsAction({ filters: { search: debouncedSearch } }));
  }, [debouncedSearch]);

  const handleSelectedStatusChange = (status) => {
    dispatch(getRequestsAction({ filters: { status } }));
  };

  const handleSelectedSubCategoryIdChange = (subCategoryId) => {
    dispatch(getRequestsAction({ filters: { subCategoryId } }));
  };

  return (
    <div className="flex justify-between">
      <ToggleBtnGroup
        items={OpportunitiesStatusFilters.map((status) => ({
          label: `${
            OpportunitiesStatusFilterText(OpportunityType.Request)[status]
          } 
          (${isGetRequestsStatusCountsPending ? "?" : statusCounts[status]})`,
          value: status,
        }))}
        selectedValue={selectedStatus}
        onSelectedValueChanged={handleSelectedStatusChange}
      />

      <div>
        <FormControl sx={{ minWidth: 120, marginRight: 2 }} size="small">
          <InputLabel>Category</InputLabel>
          <Select
            label="Category"
            value={selectedSubCategoryId}
            onChange={(e) => handleSelectedSubCategoryIdChange(e.target.value)}
          >
            <MenuItem value="">
              <em>All</em>
            </MenuItem>
            {subCategories.map((sub) => (
              <MenuItem key={sub.id} value={sub.id}>
                {sub.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <TextField
          placeholder="Search(Alt+S)"
          sx={{ width: "180px" }}
          InputProps={{
            className: "search-input",
            startAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            ),
            autoComplete: "off",
            accessKey: "s",
          }}
          defaultValue={searchValue}
          variant="outlined"
          size="small"
          onChange={(e) => setSearch(e.target.value)}
        />
      </div>
    </div>
  );
}
