export enum BlockchainEventType {
  DealCreated = "DealCreated",
  DocCreated = "DocCreated",
  DocSigned = "DocSigned",
  PaymentCreated = "PaymentCreated",
  UpfrontPaymentReleased = "UpfrontPaymentReleased",
  FullPaymentReleased = "FullPaymentReleased",
  UpfrontPaymentReversed = "UpfrontPaymentReversed",
  FullPaymentReversed = "FullPaymentReversed",
  FeePaid = "FeePaid"
}

export const BlockChainEventTypes = Object.values(BlockchainEventType);
