import NumberFormat from "react-number-format";

interface IProps {
  amount: number;
  symbol?: string;
}

export default function CurrencyFormat({ amount, symbol }: IProps) {
  return (
    <NumberFormat
      value={amount}
      thousandSeparator={true}
      prefix={symbol}
      displayType="text"
    />
  );
}
