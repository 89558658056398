import { yupResolver } from "@hookform/resolvers/yup";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useSearchParams } from "react-router-dom";
import * as Yup from "yup";
import { authApi } from "../../../http";

const validationSchema = Yup.object().shape({
  password: Yup.string()
    .required("New Password is required")
    .min(6, "Password must be at least 8 characters"),
  confirmPassword: Yup.string()
    .required("Confirm New Password is required")
    .oneOf([Yup.ref("password")], "Passwords must match"),
});

export default function ResetPasswordPage() {
  const [params] = useSearchParams();
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const formOptions = {
    resolver: yupResolver(validationSchema),
    mode: "onChange",
  };
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm(formOptions as any);

  const onSubmit = async (value) => {
    setIsSubmitting(true);
    try {
      await authApi.resetPassword(
        params.get("email")!,
        params.get("code")!,
        value.password
      );
      enqueueSnackbar("Password reset successfully.", { variant: "success" });
      navigate("/login");
    } catch (error) {
      setIsSubmitting(false);
    }
  };

  return (
    <>
      <Typography className="w-full mb-1">
        Please enter your new password
      </Typography>

      <form onSubmit={handleSubmit(onSubmit)}>
        <TextField
          className="w-full mt-3"
          {...register("password")}
          error={!!errors?.password}
          type={passwordVisible ? "text" : "password"}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => setPasswordVisible((visible) => !visible)}
                >
                  {passwordVisible ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }}
          label="New Password"
          autoFocus
          variant="standard"
          helperText={errors.password?.message}
        />

        <TextField
          className="w-full mt-3"
          {...register("confirmPassword")}
          error={!!errors?.confirmPassword}
          type={confirmPasswordVisible ? "text" : "password"}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={() =>
                    setConfirmPasswordVisible((visible) => !visible)
                  }
                >
                  {confirmPasswordVisible ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }}
          label="Confirm New Password"
          variant="standard"
          helperText={errors.confirmPassword?.message}
        />

        <LoadingButton
          type="submit"
          className="mt-10 w-full"
          variant="contained"
          color="secondary"
          loading={isSubmitting}
        >
          Reset Password
        </LoadingButton>
      </form>
    </>
  );
}
