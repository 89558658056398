export enum BlockchainTxType {
  CreateDeal = "CreateDeal",
  CreateDoc = "CreateDoc",
  SignDoc = "SignDoc",
  CreatePayment = "CreatePayment",
  ReleaseUpfrontPayment = "ReleaseUpfrontPayment",
  RevertUpfrontPayment = "RevertUpfrontPayment",
  ReleaseFullPayment = "ReleaseFullPayment",
  RevertFullPayment = "RevertFullPayment",
}
