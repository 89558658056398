import { Business } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { Avatar, Button, ButtonGroup, TextField } from "@mui/material";
import moment from "moment";
import { useSnackbar } from "notistack";
import { useEffect, useRef, useState } from "react";
import Loading from "../../../components/Loading/Loading";
import PlanCard from "../../../components/PlanCard/PlanCard";
import PlanSubscriptionChip from "../../../components/PlanSubscriptionChip/PlanSubscriptionChip";
import { plansApi } from "../../../http/plans.api";
import { Plan, PlanDurationType } from "../../../types";
import { toPurposeOfUse } from "../../../utils/coimex-utils";
import { EligibleUpgradePlansResponse } from "../types/eligible-upgrade-plans-response.type";
import { PlanUpgradeRequestChangeStatus } from "../types/plan-upgrade-request-change-status.type";
import { PlanUpgradeRequestStatus } from "../types/plan-upgrade-request-status.type";
import { PlanUpgradeRequest } from "../types/plan-upgrade-request.type";
import PlanUpgradeRequestStatusIndicator from "./PlanUpgradeRequestStatusIndicator";

interface IProps {
  id: PlanUpgradeRequest["id"];
  close?: (modified?: boolean) => void;
}

export default function PlanUpgradeForm({ id, close = () => {} }: IProps) {
  const [planId, setPlanId] = useState<Plan["id"]>(null);
  const [upgradeRequestEligiblePlans, setUpgradeRequestEligiblePlans] =
    useState<EligibleUpgradePlansResponse>(null);
  const [durationType, setDurationType] = useState<PlanDurationType>(
    PlanDurationType.Annually
  );
  const [isSubmitting, setIsSubmitting] =
    useState<PlanUpgradeRequestChangeStatus | null>();
  const remarksRef = useRef<HTMLInputElement>();
  const { enqueueSnackbar } = useSnackbar();

  const getUpgradeRequestEligiblePlans = async () => {
    try {
      setUpgradeRequestEligiblePlans(
        await plansApi.getUpgradeRequestEligiblePlans(id)
      );
    } catch (ignored) {
      close();
    }
  };

  const handleChangeStatus = async (status: PlanUpgradeRequestChangeStatus) => {
    try {
      setIsSubmitting(status);
      status == PlanUpgradeRequestChangeStatus.Approve
        ? await plansApi.approveUpgradePlanRequest(id, planId)
        : await plansApi.rejectUpgradePlanRequest(
            id,
            remarksRef.current?.value
          );

      close(true);
      enqueueSnackbar(
        `Upgrade plan request ${
          status === PlanUpgradeRequestChangeStatus.Approve
            ? "approved"
            : "rejected"
        } successfully.`,
        {
          variant: "info",
        }
      );
    } catch (error) {
      setIsSubmitting(null);
    }
  };

  useEffect(() => {
    id && getUpgradeRequestEligiblePlans();
  }, [id]);

  return upgradeRequestEligiblePlans ? (
    <div className="max-w-full p-4" style={{ width: "500px" }}>
      <header className="modal-header flex justify-between items-center pr-12">
        Plan Upgrade Request
        <PlanUpgradeRequestStatusIndicator
          status={upgradeRequestEligiblePlans.request.status}
        />
      </header>

      <div
        className="flex-1 overflow-auto flex flex-col gap-4"
        style={{ maxHeight: "calc(100vh - 240px)" }}
      >
        <section className="opp-info-section">
          <div className="info-section-item">
            <span>Company</span>
            <span className="info-section-item__value">
              <div className="flex items-center gap-x-2">
                <Avatar
                  src={upgradeRequestEligiblePlans?.request?.company?.avatarUrl}
                  sx={{ width: 28, height: 28 }}
                >
                  <Business fontSize="small" />
                </Avatar>
                <span>
                  {upgradeRequestEligiblePlans?.request?.company?.name}
                </span>
              </div>
            </span>
          </div>

          <div className="info-section-item">
            <span>Active Plan</span>
            <div className="info-section-item__value flex flex-wrap gap-2">
              {upgradeRequestEligiblePlans?.request?.company.activePlan && (
                <PlanSubscriptionChip
                  key={
                    upgradeRequestEligiblePlans?.request?.company.activePlan.id
                  }
                  type={
                    upgradeRequestEligiblePlans?.request?.company.activePlan
                      .type
                  }
                  durationType={
                    upgradeRequestEligiblePlans?.request?.company.activePlan
                      .duration?.type
                  }
                  purposeOfUse={toPurposeOfUse(
                    upgradeRequestEligiblePlans?.request?.company.activePlan
                      .roles
                  )}
                />
              )}
            </div>
          </div>

          <div className="info-section-item">
            <span>Requested At</span>
            <div className="info-section-item__value">
              {moment(
                upgradeRequestEligiblePlans?.request?.createdAt
              ).fromNow()}
            </div>
          </div>
        </section>

        <section className="opp-info-section items-center">
          <ButtonGroup
            className="mb-2"
            disableElevation
            variant="outlined"
            size="small"
          >
            <Button
              className="normal-case w-32"
              variant={
                durationType == PlanDurationType.Annually
                  ? "contained"
                  : "outlined"
              }
              onClick={() => setDurationType(PlanDurationType.Annually)}
            >
              Annually
            </Button>
            <Button
              className="normal-case w-32"
              variant={
                durationType == PlanDurationType.Biannually
                  ? "contained"
                  : "outlined"
              }
              onClick={() => setDurationType(PlanDurationType.Biannually)}
            >
              Biannually
            </Button>
          </ButtonGroup>
          {upgradeRequestEligiblePlans?.plans
            .filter((p) => p.duration.type == durationType)
            .map((p) => (
              <PlanCard
                key={p.id}
                plan={p}
                selected={planId == p.id}
                onSelect={(plan) => setPlanId(plan.id)}
              />
            ))}
        </section>

        <section className="opp-info-section px-2">
          <TextField
            className="w-full"
            inputRef={remarksRef}
            defaultValue={upgradeRequestEligiblePlans.request.remarks}
            label="Remarks"
            multiline
            rows={2}
          />
        </section>
      </div>
      <footer className="modal-footer flex justify-between">
        {upgradeRequestEligiblePlans?.request?.status ===
        PlanUpgradeRequestStatus.UnderReview ? (
          <Button
            variant="text"
            size="small"
            disabled={!!isSubmitting}
            onClick={() => close()}
          >
            Review Later
          </Button>
        ) : (
          <div></div>
        )}

        <div className="flex gap-3">
          {upgradeRequestEligiblePlans?.request?.status ==
            PlanUpgradeRequestStatus.UnderReview && (
            <LoadingButton
              variant="outlined"
              color="error"
              size="small"
              disabled={isSubmitting === PlanUpgradeRequestChangeStatus.Approve}
              loading={isSubmitting === PlanUpgradeRequestChangeStatus.Reject}
              onClick={() =>
                handleChangeStatus(PlanUpgradeRequestChangeStatus.Reject)
              }
            >
              Reject
            </LoadingButton>
          )}

          {upgradeRequestEligiblePlans?.request?.status ==
            PlanUpgradeRequestStatus.UnderReview && (
            <LoadingButton
              variant="contained"
              color="secondary"
              size="small"
              disabled={
                !planId ||
                isSubmitting === PlanUpgradeRequestChangeStatus.Reject
              }
              loading={isSubmitting === PlanUpgradeRequestChangeStatus.Approve}
              onClick={() =>
                handleChangeStatus(PlanUpgradeRequestChangeStatus.Approve)
              }
            >
              Approve
            </LoadingButton>
          )}
        </div>
      </footer>
    </div>
  ) : (
    <div className="p-12" style={{ width: "600px" }}>
      <Loading size={42} />
    </div>
  );
}
