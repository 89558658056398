interface IProps<T> {
    items: T[]
    renderHeader: () => JSX.Element
    renderItem: (item: T) => JSX.Element
}

export default function WidgetList<T extends { id: any }>({ items, renderHeader, renderItem }: IProps<T>) {
    return (
        <div className="WidgetList">
            <div className="header">{renderHeader()}</div>
            {
                items?.map(item => (
                    <div className="row" key={item.id}>{renderItem(item)}</div>
                ))
            }
        </div>
    )
}