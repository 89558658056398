import { Chip } from "@mui/material";
import { PlanDurationType, PlanType, PurposeOfUse } from "../../types";
import { getPlanTypeColor } from "../../utils/coimex-utils";
import { renderPurposeOfUseArrowIcon } from "../../utils/tsx-utils";

interface IProps {
  type?: PlanType;
  purposeOfUse?: PurposeOfUse;
  durationType?: PlanDurationType;
}

export default function PlanSubscriptionChip({
  type,
  purposeOfUse,
  durationType,
}: IProps) {
  return type ? (
    <Chip
      icon={renderPurposeOfUseArrowIcon(purposeOfUse!)}
      label={`${type}, ${durationType}`}
      color={getPlanTypeColor(type)}
      size="small"
      variant="outlined"
    />
  ) : (
    <></>
  );
}
