import CopyToClipboard from "../../../components/CopyToClipboard/CopyToClipboard";
import { CommodityToken } from "../../../types";
import CommodityTokenBuySellPriceInfo from "./CommodityTokenBuySellPriceInfo";
import CommodityTokenCommissionInfo from "./CommodityTokenCommissionInfo";
import CommodityTokenMinMaxAmountInfo from "./CommodityTokenMinMaxAmountInfo";
import "./CommodityTokens.scss";

interface IProps {
  token: CommodityToken;
}

export default function CommodityTokenPricesInfo({ token }: IProps) {
  return (
    <div className="max-w-3xl mx-auto flex flex-col gap-y-6 ">
      {Object.values(token.prices).map((tp) => (
        <section className="commodity-token-info-section">
          <h2 className="text-lg font-medium">{tp.symbol}</h2>

          <div className="info-section-item">
            <span>Token Address</span>
            <span className="info-section-item__value">
              <a
                className="link"
                href={tp.addressUrl}
                target="_blank"
                onClick={(e) => e.stopPropagation()}
              >
                {tp.address}
              </a>
              <CopyToClipboard
                text={tp.address}
                defaultTooltip="Copy Address"
              />
            </span>
          </div>

          <CommodityTokenBuySellPriceInfo token={token} tokenPrice={tp} />

          <CommodityTokenCommissionInfo token={token} tokenPrice={tp} />

          <CommodityTokenMinMaxAmountInfo token={token} tokenPrice={tp} />
        </section>
      ))}
    </div>
  );
}
