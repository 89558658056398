import Lottie from "react-lottie";
import * as animationData from "./auth-lottie.json";

const defaultOptions = {
  loop: false,
  autoplay: true,
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

export default function AuthLottie() {
  return <Lottie options={defaultOptions} height={400} width={500} isClickToPauseDisabled />;
}
