import { HelpOutline } from "@mui/icons-material";
import { Tooltip } from "@mui/material";

interface IProps {
  text: string;
}

export default function InfoPopup({ text }: IProps) {
  return (
    <Tooltip title={text}>
      <HelpOutline className="mx-1 opacity-70" fontSize="small" />
    </Tooltip>
  );
}
