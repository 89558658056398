import api from ".";
import { CompaniesStatusFilter } from "../modules/companies/types/companies-status-filter.type";
import { Paginated } from "../types";
import { CompanyChangeStatus } from "../types/company-change-status.type";
import { Company } from "../types/company.type";
import { PaginationQuery } from "../types/pagination-query.type";

export const companiesApi = {
  getCompany: async (id: string): Promise<Company> => {
    return (await api.get(`/companies/${id}`)).data;
  },
  getCompanies: async (
    params?: PaginationQuery
  ): Promise<Paginated<Company>> => {
    return await api.get("/companies", { params });
  },
  getCompaniesStatusCounts: async (): Promise<
    Record<CompaniesStatusFilter, number>
  > => {
    return (await api.get("/companies/statuses")).data;
  },
  changeCompanyStatus: async (
    id: string,
    status: CompanyChangeStatus,
    remarks: string
  ): Promise<void> => {
    return api.put(`/companies/${id}/status`, {
      status,
      remarks,
    });
  },
  deleteCompany: (id: string): Promise<void> => {
    return api.delete(`/companies/${id}`);
  },
  addCompany: async (model: any): Promise<Company> => {
    return (await api.post("/companies", model)).data;
  },
};
