import { useCallback } from "react";
import YesNoConfirm, {
  IYesNoConfirmProps,
} from "../components/YesNoConfirm/YesNoConfirm";
import { useModal } from "../providers/ModalProvider";

export default function useYesNoConfirm() {
  const openModal = useModal();

  const confirm = useCallback(
    ({
      title,
      message,
      onYes,
    }: Pick<IYesNoConfirmProps, "title" | "message" | "onYes">) =>
      openModal((props) => (
        <YesNoConfirm
          title={title}
          message={message}
          onYes={onYes}
          {...props}
        />
      )),
    []
  );

  return confirm;
}
