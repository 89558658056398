import { yupResolver } from "@hookform/resolvers/yup";
import { Add, Close } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { Button, Paper, TextField } from "@mui/material";
import { useRef, useState } from "react";
import { set, useForm } from "react-hook-form";
import * as Yup from "yup";
import { CloseModal } from "../../providers/ModalProvider";

interface IProps {
  onFileUpload?: (file: File, params?: Record<string, any>) => Promise<any>;
  close: CloseModal<void>;
}

const validationSchema = Yup.object().shape({
  title: Yup.string().required(""),
  file: Yup.mixed().required(""),
});

export default function ImageUploaderWithTitleForm({
  onFileUpload,
  close,
}: IProps) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const inputRef = useRef<any>();
  const formOptions = {
    resolver: yupResolver(validationSchema),
    mode: "onChange",
  };
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { isValid, errors },
  } = useForm(formOptions as any);

  const onSubmit = async ({ title, file }) => {
    try {
      setIsSubmitting(true);
      await onFileUpload(file, { title });
      close();
    } finally {
      clearFileInput();
      setIsSubmitting(false);
    }
  };

  const patchFormValue = (key: string, value: any) => {
    setValue(key, value, {
      shouldDirty: true,
      shouldTouch: true,
      shouldValidate: true,
    });
  };

  const clearFileInput = () => {
    if (inputRef.current) {
      inputRef.current.value = null;
      inputRef.current.files = null;
    }
  };

  return (
    <form
      className="max-w-full p-4"
      style={{ width: "300px" }}
      onSubmit={handleSubmit(onSubmit)}
    >
      <header className="modal-header text-xl pt-1.5 pb-3">Add Image</header>

      <div className="flex-1 flex flex-col gap-6 px-1">
        <TextField
          className="w-full"
          {...register("title")}
          label="Title"
          error={!!errors?.title}
          variant="filled"
          autoFocus
        />

        {watch("file") ? (
          <div className={"relative w-full h-40 select-none group"}>
            <Paper className="w-full h-full overflow-hidden" elevation={3}>
              <img
                src={URL.createObjectURL(watch("file"))}
                className="w-full h-full object-fit"
                draggable={false}
              />
            </Paper>
            <Close
              className="absolute -right-2 -top-2 bg-white border border-gray-300 rounded-full text-gray-500 cursor-pointer p-1 hover:bg-gray-100 opacity-0 group-hover:opacity-100 transition-opacity duration-300"
              fontSize="medium"
              onClick={() => patchFormValue("file", null)}
            />
          </div>
        ) : (
          <div className="relative w-full h-40 border-2 border-dashed border-gray-300 rounded-md flex justify-center items-center cursor-pointer hover:bg-gray-100">
            <Add fontSize="large" />
            <input
              className="absolute inset-0 opacity-0 cursor-pointer"
              type="file"
              accept="image/*"
              onChange={(e) => patchFormValue("file", e.target.files?.[0])}
            />
          </div>
        )}
      </div>

      <footer className="modal-footer flex justify-end gap-4">
        <Button type="button" variant="text" onClick={() => close(null)}>
          Cancel
        </Button>
        <LoadingButton
          type="submit"
          variant="contained"
          color="secondary"
          disabled={!isValid}
          loading={isSubmitting}
        >
          Save
        </LoadingButton>
      </footer>
    </form>
  );
}
