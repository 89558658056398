import { createAsyncThunk } from "@reduxjs/toolkit";
import { meApi } from "../../http";
import { requestForToken } from "../../utils/firebase-utils";

export const initializeFirebaseAction = createAsyncThunk(
    'app/initializeFirebase',
    async () => {
        const token = await requestForToken();
        if (token) {
            await meApi.addNotificationToken(token);
        }
    }
)