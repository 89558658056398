import { CircularProgress } from "@mui/material";
import { useEffect, useLayoutEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import ls from "../common/ls";
import { getMeAction } from "../modules/auth/auth.effects";
import {
  selectIsAuthenticated,
  selectIsGetMePending
} from "../modules/auth/auth.selectors";
import { authActions } from "../modules/auth/auth.state";

export default function MeProvider({ children }) {
  const isGetMePending = useSelector(selectIsGetMePending);
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const renderLoading = () => {
    return (
      <div
        className="w-screen h-screen flex flex-col items-center"
        style={{ paddingTop: "20vh" }}
      >
        <h1 className="mb-4 text-xl text-primary">
          We are preparing your dashboard...
        </h1>
        <CircularProgress color="primary" size="24px" />
      </div>
    );
  };

  useEffect(() => {
    dispatch(ls.token ? getMeAction() : authActions.cancelGetMe());
  }, []);

  useLayoutEffect(() => {
    if (
      !isGetMePending &&
      !isAuthenticated &&
      !location.pathname.includes("reset-password")
    ) {
      navigate("/login");
      ls.token = null;
    }
  }, [isGetMePending, isAuthenticated]);

  return isGetMePending ? renderLoading() : <>{children}</>;
}
