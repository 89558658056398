import classNames from "classnames";
import { EChartsOption } from "echarts";
import ReactEcharts from "echarts-for-react";
import { useMemo } from "react";

interface IProps {
    title: string
    data: number[]
    total: number
}

const style = {
    height: "100%",
    width: "100%",
    padding: '0 0 16px'
};

export default function WidgetBarChart({ title, total: total, data, ...rest }: IProps & React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>) {
    const option = useMemo(() => ({
        xAxis: {
            data: []
        },
        yAxis: {
            type: 'value',
            axisLabel: {
                show: false
            },
            axisLine: {
                show: false,
            },
            splitLine: {
                show: false
            }
        },
        series: [
            {
                data: data,
                type: 'bar',
                animationDelay: (idx) => idx * 10,
                itemStyle: {
                    color: '#17a2b8',
                }
            }
        ],
        animationEasing: 'elasticOut',
        animationDelayUpdate: (idx) => idx * 5,
        grid: {
            top: 0,
            bottom: 0
        }
    } as EChartsOption), [data]);

    return (
        <div {...rest} className={classNames(rest?.className, 'flex flex-col gap-1')}>
            <span className="text-xs font-medium uppercase">{title}</span>
            <span className="text-3xl">{total}</span>
            <ReactEcharts option={option} style={style} />
        </div>
    )
}