import Lottie from "react-lottie";
import * as animationData from "./page-not-found-lottie.json";

export default function PageNotFound() {
  const defaultOptions = {
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <div className="w-screen h-screen flex justify-center items-center">
      <Lottie options={defaultOptions} height={400} width={500} isClickToPauseDisabled />
    </div>
  );
}
