import { ThemeProvider } from "@mui/material";
import { SnackbarProvider } from "notistack";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import theme from "./common/theme";
import "./index.scss";
import BlockchainTransferEventProvider from "./modules/blockchain/providers/BlockchainTransferEventProvider";
import store from "./modules/store";
import BottomSheetProvider from "./providers/BottomSheetProvider";
import ImageViewProvider from "./providers/ImagePreviewProvider";
import LocaleProvider from "./providers/LocaleProvider";
import MeProvider from "./providers/MeProvider";
import ModalProvider from "./providers/ModalProvider";
import WsProvider from "./providers/WsProvider";
import "./utils/array-utils";
import "./utils/number-utils";
import "./utils/string-utils";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <SnackbarProvider
          maxSnack={3}
          autoHideDuration={5000}
          anchorOrigin={{ horizontal: "right", vertical: "top" }}
        >
          <ImageViewProvider>
            <WsProvider>
              <BlockchainTransferEventProvider>
                <LocaleProvider>
                  <ModalProvider>
                    <BottomSheetProvider>
                      <MeProvider>
                        <App />
                      </MeProvider>
                    </BottomSheetProvider>
                  </ModalProvider>
                </LocaleProvider>
              </BlockchainTransferEventProvider>
            </WsProvider>
          </ImageViewProvider>
        </SnackbarProvider>
      </BrowserRouter>
    </ThemeProvider>
  </Provider>
);
