import { combineReducers } from "@reduxjs/toolkit";
import { appReducer, APP_STATE_NAME } from "../app/app.state";
import { authReducer, AUTH_STATE_NAME } from "../auth/auth.state";
import { companiesReducer, COMPANIES_STATE_NAME } from "../companies/companies.state";
import { dashboardReducer, DASHBOARD_STATE_NAME } from "../dashboard/dashboard.state";
import { offersReducer, OFFERS_STATE_NAME } from "../offers/offers.state";
import { paymentsReducer, PAYMENTS_STATE_NAME } from "../payments/payments.state";
import { plansReducer, PLANS_STATE_NAME } from "../plans/plans.state";
import { requestsReducer, REQUESTS_STATE_NAME } from "../requests/requests.state";
import { schedulesReducer, SCHEDULES_STATE_NAME } from "../schedules/schedules.state";
import { settingsReducer, SETTING_STATE_NAME } from "../settings/settings.state";
import { NOTIFICATIONS_STATE_NAME, notificationsReducer } from "../notifications/notifications.state";
import { BLOCKCHAIN_STATE_NAME, blockchainReducer } from "../blockchain/blockchain.state";
import { COMMODITY_TOKENS_STATE_NAME, commodityTokensReducer } from "../commodity-tokens/commodity-tokens.state";
import { INVEST_STATE_NAME, investReducer } from "../invest/invest.state";

export const rootReducer = combineReducers({
    [APP_STATE_NAME]: appReducer,
    [DASHBOARD_STATE_NAME]: dashboardReducer,
    [AUTH_STATE_NAME]: authReducer,
    [COMPANIES_STATE_NAME]: companiesReducer,
    [SETTING_STATE_NAME]: settingsReducer,
    [PAYMENTS_STATE_NAME]: paymentsReducer,
    [OFFERS_STATE_NAME]: offersReducer,
    [BLOCKCHAIN_STATE_NAME]: blockchainReducer,
    [REQUESTS_STATE_NAME]: requestsReducer,
    [PLANS_STATE_NAME]: plansReducer,
    [SCHEDULES_STATE_NAME]: schedulesReducer,
    [NOTIFICATIONS_STATE_NAME]: notificationsReducer,
    [COMMODITY_TOKENS_STATE_NAME]: commodityTokensReducer,
    [INVEST_STATE_NAME]: investReducer
});

export type RootState = ReturnType<typeof rootReducer>;