import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { getPlanSubscriptionsAction, getPlanUpgradeRequestsAction, getPlansAction } from "./plans.effects";

export const selectPlansState = (state: RootState) => state.plans;

export const selectPlans = createSelector(
    selectPlansState,
    state => state.plans || []
)

export const selectIsGetPlansPending = createSelector(
    selectPlansState,
    state => state[getPlansAction.pending.type]
)

export const selectPlanSubscriptions = createSelector(
    selectPlansState,
    state => state.subscriptions || []
)

export const selectIsGetPlanSubscriptionsPending = createSelector(
    selectPlansState,
    state => state[getPlanSubscriptionsAction.pending.type]
)

export const selectPlanSubscriptionsPagination = createSelector(
    selectPlansState,
    state => state.pagination
)

export const selectPlanSubscriptionsFilters = createSelector(
    selectPlansState,
    state => state.filters
)

export const selectPlanSubscriptionsOrderByFilter = createSelector(
    selectPlanSubscriptionsFilters,
    filters => filters.orderBy
)

export const selectGetPlanUpgradeRequests = createSelector(
    selectPlansState,
    state => state.upgradeRequests || []
)

export const selectIsGetPlanUpgradeRequestsPending = createSelector(
    selectPlansState,
    state => state[getPlanUpgradeRequestsAction.pending.type]
)
