interface String {
  replaceAll(search: string, replacement: string): string;
  replaceAt(index: number, replacement: string): string;
  toTitleCase(): string;
  replaceBetween(start: number, end: number, what: string): string;
  capitalizeFirstLetter(): string;
  toSplitCase(): string;
}

String.prototype.replaceAll = function (search, replacement) {
  return this.split(search).join(replacement);
};

String.prototype.replaceAt = function (index, replacement) {
  return (
    this.substring(0, index) +
    replacement +
    this.substring(index + replacement.length)
  );
};

String.prototype.toTitleCase = function () {
  var target = this;
  return target.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
};

String.prototype.replaceBetween = function (start, end, what) {
  return this.substring(0, start) + what + this.substring(end);
};

String.prototype.capitalizeFirstLetter = function () {
  return this.charAt(0).toUpperCase() + this.slice(1);
};

String.prototype.toSplitCase = function () {
  return this.replace(/([A-Z]+)([A-Z][a-z])/g, " $1 $2")
    .replace(/([a-z\d])([A-Z])/g, "$1 $2")
    .replace(/([a-zA-Z])(\d)/g, "$1 $2")
    .replace(/^./, function (str) {
      return str.toUpperCase();
    })
    .trim();
};
