import { createSlice } from "@reduxjs/toolkit";
import { DEFAULTS } from "../../common/constants";
import { Pagination, Payment } from "../../types";
import { addCommonReducers } from "../../utils/store-utils";
import { getPaymentsAction, getPaymentsStatusCountsAction } from "./payments.effects";
import { PaymentsFilters } from "./types/payments-filters.type";
import { PaymentsStatusFilter } from "./types/payments-status.filter.type";

export const PAYMENTS_STATE_NAME = 'payments';

export interface PaymentsState {
    payments: Payment[]
    statusCounts: Record<PaymentsStatusFilter, number>
    filters: PaymentsFilters
    pagination: Pagination
}

export const paymentsState = createSlice({
    name: PAYMENTS_STATE_NAME,
    initialState: {
        payments: [],
        statusCounts: {
            All: 0,
            Succeeded: 0,
            Failed: 0
        },
        filters: {
            status: PaymentsStatusFilter.All,
            orderBy: {
                field: 'createdAt',
                sort: 'desc'
            }
        },
        pagination: {
            page: 0,
            pageSize: DEFAULTS.PageSize,
            total: 0
        },
        [getPaymentsAction.pending.type]: true
    } as PaymentsState,
    reducers: {
    },
    extraReducers: builder => {
        builder
            .addCase(getPaymentsAction.fulfilled, (state, action) => {
                const { paginatedPayments, page, pageSize, filters } = action.payload;
                state.payments = paginatedPayments.data;
                state.pagination.total = paginatedPayments.total;
                state.pagination.page = page;
                state.pagination.pageSize = pageSize;
                state.filters = filters;
            })
            .addCase(getPaymentsStatusCountsAction.fulfilled, (state, action) => {
                state.statusCounts = action.payload;
            });
        addCommonReducers(builder, PAYMENTS_STATE_NAME);
    }
});

export const paymentsActions = paymentsState.actions;
export const paymentsReducer = paymentsState.reducer;