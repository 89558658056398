import { createAsyncThunk } from "@reduxjs/toolkit";
import { companiesApi } from "../../http";
import { Notification, NotificationType } from "../../types";
import { AppPage } from "../../types/app-page.type";
import { AppRefreshStatus } from "../../types/app-refresh-status.type";
import { appActions } from "../app/app.state";
import { notificationsActions } from "../notifications/notifications.state";
import { RootState } from "../store";
import listenerMiddleware from "../store/middlewares/listener.middleware";
import { CompaniesState } from "./companies.state";
import { CompaniesFilters } from "./types/companies-filters.type";

export const getCompaniesAction = createAsyncThunk(
    'companies/getCompanies',
    async ({ page, pageSize, filters }: { page?: number, pageSize?: number, filters?: Partial<CompaniesFilters> }, thunk) => {
        const companiesState: CompaniesState = (<RootState>thunk.getState()).companies;
        page = page ?? companiesState.pagination.page;
        pageSize = pageSize ?? companiesState.pagination.pageSize;
        filters = {
            relations: ['owner', 'country', 'roles', 'planSubscriptions'],
            ...companiesState.filters,
            ...(filters || {}),
        };
        const paginatedCompanies = await companiesApi.getCompanies({
            from: page * pageSize,
            take: pageSize,
            filters: JSON.stringify(filters)
        });
        return { paginatedCompanies, page, pageSize, filters: <CompaniesFilters>filters };
    }
)

export const getCompaniesStatusCountsAction = createAsyncThunk(
    'companies/getCompaniesStatusCounts',
    async ({ changeRefresh }: { changeRefresh: boolean }, thunk) => {
        try {
            changeRefresh && thunk.dispatch(appActions.changeRefresh({
                status: AppRefreshStatus.InProgress,
                page: AppPage.Companies
            }));
            const response = await companiesApi.getCompaniesStatusCounts();
            return response;
        } finally {
            changeRefresh && thunk.dispatch(appActions.changeRefresh({
                status: AppRefreshStatus.Idle,
                page: AppPage.Companies
            }));
        }
    }
)

listenerMiddleware.startListening({
    actionCreator: notificationsActions.notificationReceived,
    effect: (action, listenerApi) => {
        if ((action.payload as Notification)?.type === NotificationType.CompanyUnderReview) {
            listenerApi.dispatch(getCompaniesAction({}));
            listenerApi.dispatch(getCompaniesStatusCountsAction({ changeRefresh: false }));
        }
    }
})