import { Search } from "@mui/icons-material";
import { InputAdornment, TextField } from "@mui/material";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useDebounce } from "usehooks-ts";
import ToggleBtnGroup from "../../../components/ToggleBtnGroup/ToggleBtnGroup";
import useSkipFirstRenderEffect from "../../../hooks/use-skip-first-render-effect";
import {
  PaymentsStatusFilters,
  PaymentsStatusFilterText,
} from "../../../utils/coimex-utils";
import { getPaymentsAction } from "../payments.effects";
import {
  selectIsGetPaymentsStatusCountsPending,
  selectPaymentsSearchFilter,
  selectPaymentsStatusCounts,
  selectPaymentsStatusFilter,
} from "../payments.selectors";

export default function PaymentsFilters() {
  const [search, setSearch] = useState("");
  const debouncedSearch = useDebounce(search, 500);
  const selectedStatus = useSelector(selectPaymentsStatusFilter);
  const statusCounts = useSelector(selectPaymentsStatusCounts);
  const searchValue = useSelector(selectPaymentsSearchFilter);
  const isGetPaymentsStatusCountsPending = useSelector(
    selectIsGetPaymentsStatusCountsPending
  );
  const dispatch = useDispatch();

  useSkipFirstRenderEffect(() => {
    dispatch(getPaymentsAction({ filters: { search: debouncedSearch } }));
  }, [debouncedSearch]);

  const handleSelectedStatusChange = (status) => {
    dispatch(getPaymentsAction({ filters: { status } }));
  };

  return (
    <div className="flex justify-between">
      <ToggleBtnGroup
        items={PaymentsStatusFilters.map((status) => ({
          label: `${PaymentsStatusFilterText[status]} 
          (${isGetPaymentsStatusCountsPending ? "?" : statusCounts[status]})`,
          value: status,
        }))}
        selectedValue={selectedStatus}
        onSelectedValueChanged={handleSelectedStatusChange}
      />

      <div>
        <TextField
          placeholder="Search(Alt+S)"
          sx={{ width: "180px" }}
          InputProps={{
            className: "search-input",
            startAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            ),
            autoComplete: "off",
            accessKey: "s",
          }}
          defaultValue={searchValue}
          variant="outlined"
          size="small"
          onChange={(e) => setSearch(e.target.value)}
        />
      </div>
    </div>
  );
}
