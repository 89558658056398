import { yupResolver } from "@hookform/resolvers/yup";
import { FormControlLabel, Switch } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import * as Yup from "yup";
import { investApi } from "../../../http";
import { InvestPoolStatus } from "../../../types";
import { usePromptPayPoolForm } from "../providers/PromptPayPoolFormProvider";

interface FormProps {
  publish: boolean;
}

const validationSchema = Yup.object().shape({});

export default function PromptPayPoolFormPublish({ children }) {
  const { value, setNexting, close } = usePromptPayPoolForm();
  const formOptions = {
    resolver: yupResolver(validationSchema),
    mode: "onSubmit",
    defaultValues: {
      publish: true,
    },
  };
  const { control, handleSubmit } = useForm(formOptions as any);

  const onSubmit = async ({ publish }: FormProps) => {
    try {
      let pool = value.pool;
      if (publish) {
        setNexting(true);
        await investApi.publishInvestPool(pool.id);
        pool.status = InvestPoolStatus.Published;
      }
      close(pool);
    } finally {
      setNexting(false);
    }
  };

  return (
    <form
      className="flex-1 w-full flex flex-col gap-4 mt-2"
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className="flex-1 flex flex-col gap-6 px-1">
        <Controller
          name="publish"
          control={control}
          render={({ field: { onChange, value } }) => (
            <FormControlLabel
              control={<Switch checked={value} onChange={onChange} />}
              label="Publish"
            />
          )}
        />
      </div>
      {children}
    </form>
  );
}
