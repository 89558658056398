import { yupResolver } from "@hookform/resolvers/yup";
import { LoadingButton } from "@mui/lab";
import { Button, TextField } from "@mui/material";
import { useRef, useState } from "react";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { CONSTANTS } from "../../common/constants";
import useFocus from "../../hooks/use-focus";
import { CloseModal } from "../../providers/ModalProvider";

export interface IDeleteConfirmProps {
  title: string;
  onConfirm: () => Promise<void> | void;
  close: CloseModal<boolean>;
}

const validationSchema = Yup.object().shape({
  confirmationText: Yup.string().required("This field is required."),
});

export default function DeleteConfirm({
  title,
  onConfirm = () => {},
  close,
}: IDeleteConfirmProps) {
  const [isDeleting, setIsDeleting] = useState(false);
  const inputRef = useRef<any>();
  useFocus(inputRef);
  const formOptions = {
    resolver: yupResolver(validationSchema),
    mode: "onSubmit",
  };
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm(formOptions as any);
  const confirmationText = watch("confirmationText");

  const onSubmit = async () => {
    try {
      setIsDeleting(true);
      await onConfirm();
      close(true);
    } catch (error) {
      setIsDeleting(false);
    }
  };

  return (
    <form
      className="p-4"
      style={{ width: "500px" }}
      onSubmit={handleSubmit(onSubmit)}
    >
      <header className="modal-header text-red-700">Delete {title}</header>
      <div>
        Are you sure you want to delete <b>{title}</b> permanently?
        <b> You can't undo this action.</b>
        <br />
        <p className="mt-2">
          To confirm deletion, enter the phrase{" "}
          <i className="text-red-700">
            {CONSTANTS.DeletePermanently.toLowerCase()}
          </i>
          .
        </p>
      </div>
      <TextField
        inputRef={inputRef}
        className="w-full mt-3"
        {...register("confirmationText")}
        placeholder="delete permanently"
        error={!!errors?.confirmationText}
        variant="outlined"
        size="small"
        helperText={errors.confirmationText?.message}
        inputProps={{
          autoComplete: "off",
        }}
      />
      <footer className="modal-footer flex justify-end gap-4">
        <Button type="button" variant="text" onClick={() => close(false)}>
          Cancel
        </Button>
        <LoadingButton
          type="submit"
          variant="contained"
          color="error"
          loading={isDeleting}
          disabled={confirmationText !== CONSTANTS.DeletePermanently}
        >
          Delete
        </LoadingButton>
      </footer>
    </form>
  );
}
