import { yupResolver } from "@hookform/resolvers/yup";
import {
  Avatar,
  FormControl,
  InputLabel,
  LinearProgress,
  MenuItem,
  Select,
} from "@mui/material";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { useSmartDealForm } from "../../providers/SmartDealFormProvider";
import { Business } from "@mui/icons-material";
import { Company, PurposeOfUse } from "../../../../types";
import { useMemo } from "react";
import { blockchainApi } from "../../../../http/blockchain.api";

interface FormProps {
  exporterCompany: Company;
  importerCompany: Company;
}

const validationSchema = Yup.object().shape({
  exporterCompany: Yup.object().required(),
  importerCompany: Yup.object().required(),
});

export default function SmartDealFormInfo({ children }) {
  const { companies, isGetCompaniesPending, next, setNexting } =
    useSmartDealForm();
  const formOptions = {
    resolver: yupResolver(validationSchema),
    mode: "onSubmit",
  };
  const {
    register,
    watch,
    getValues,
    handleSubmit,
    formState: { errors },
  } = useForm(formOptions as any);
  const exporterCompanies = useMemo(
    () =>
      companies
        .filter(
          (c) =>
            c.id !== getValues("importerCompany")?.id &&
            c.purposeOfUse !== PurposeOfUse.Importer
        )
        .sortBy("name"),
    [companies, watch("importerCompany")]
  );
  const importerCompanies = useMemo(
    () =>
      companies
        .filter(
          (c) =>
            c.id !== getValues("exporterCompany")?.id &&
            c.purposeOfUse !== PurposeOfUse.Exporter
        )
        .sortBy("name"),
    [companies, watch("exporterCompany")]
  );

  const onSubmit = async (value: FormProps) => {
    try {
      setNexting(true);
      const smartDeal = await blockchainApi.createSmartDeal({
        exporterCompanyId: value.exporterCompany.id,
        importerCompanyId: value.importerCompany.id,
      });
      next({ smartDeal });
    } finally {
      setNexting(false);
    }
  };

  return (
    <form
      className="flex-1 w-full flex flex-col gap-4 mt-2"
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className="flex-1 flex flex-col gap-6 px-1">
        <FormControl fullWidth size="small" variant="filled" required>
          <InputLabel>Exporter Company(Seller)</InputLabel>
          <Select
            label="Exporter Company"
            {...register("exporterCompany")}
            defaultValue={"" as any}
            error={!!errors?.exporterCompany}
            displayEmpty
            renderValue={(company: Company) =>
              isGetCompaniesPending ? (
                <LinearProgress className="absolute top-0 left-0 right-0 h-1" />
              ) : company ? (
                <div className="flex items-center gap-2">
                  <Avatar
                    src={company.avatarUrl}
                    sx={{ width: 28, height: 28 }}
                  >
                    <Business fontSize="small" />
                  </Avatar>
                  {company.name}
                </div>
              ) : null
            }
          >
            {exporterCompanies.map((c) => (
              <MenuItem key={c.id} value={c as any}>
                <Avatar
                  className="mr-2"
                  src={c.avatarUrl}
                  sx={{ width: 28, height: 28 }}
                >
                  <Business fontSize="small" />
                </Avatar>
                {c.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl fullWidth size="small" variant="filled" required>
          <InputLabel>Importer Company(Buyer)</InputLabel>
          <Select
            label="Importer Company"
            {...register("importerCompany")}
            defaultValue={"" as any}
            error={!!errors?.importerCompany}
            displayEmpty
            renderValue={(company: Company) =>
              isGetCompaniesPending ? (
                <LinearProgress className="absolute top-0 left-0 right-0 h-1" />
              ) : company ? (
                <div className="flex items-center gap-2">
                  <Avatar
                    src={company.avatarUrl}
                    sx={{ width: 28, height: 28 }}
                  >
                    <Business fontSize="small" />
                  </Avatar>
                  {company.name}
                </div>
              ) : null
            }
          >
            {importerCompanies.map((c) => (
              <MenuItem key={c.id} value={c as any}>
                <Avatar
                  className="mr-2"
                  src={c.avatarUrl}
                  sx={{ width: 28, height: 28 }}
                >
                  <Business fontSize="small" />
                </Avatar>
                {c.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
      {children}
    </form>
  );
}
