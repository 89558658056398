import moment from "moment";
import ConnectionStatus from "../ConnectionStatus/ConnectionStatus";

export default function Footer() {
  return (
    <div className="basis-10 px-8 flex justify-between items-center font-lato text-xs text-gray-500 select-none">
      All rights reserved Coimex &copy; {moment().format("YYYY")}
      <ConnectionStatus />
    </div>
  );
}
