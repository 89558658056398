import {
  ArrowDownward,
  ArrowUpward,
  AttachMoney,
  Check,
  Close,
  ImportExport
} from "@mui/icons-material";
import { PurposeOfUse } from "../types";

export const renderPurposeOfUseArrowIcon = (purposeOfUse: PurposeOfUse) => {
  switch (purposeOfUse) {
    case PurposeOfUse.Importer:
      return <ArrowDownward />;

    case PurposeOfUse.Exporter:
      return <ArrowUpward />;

    case PurposeOfUse.Both:
      return <ImportExport />;
  }
};

export const renderCurrencyIcon = (currency: string) => {
  switch (currency.toUpperCase()) {
    default:
      return <AttachMoney />;
  }
};

export const renderIsActiveIcon = (isActive: boolean) => {
  return isActive ? <Check /> : <Close />
};
