import { Paper } from "@mui/material";
import { Outlet } from "react-router-dom";
import useRedirectToHomeIfAuthenticated from "../../hooks/use-redirect-to-home-if-authenticated";
import AuthLottie from "./AuthLottie";

export default function AuthLayout() {
  useRedirectToHomeIfAuthenticated();

  return (
    <div className="relative w-screen h-screen bg-primary p-6 bg-cover bg-no-repeat">
      <img
        className="absolute"
        src="/assets/images/coimex.png"
        width="300"
        style={{
          left: "50%",
          top: "12vh",
          transform: "translateX(-50%)",
        }}
      />
      <div
        className="flex justify-center items-center"
        style={{ marginTop: "25vh" }}
      >
        <Paper
          className="w-80 flex flex-col gap-5 items-center px-8 py-12"
          elevation={3}
          style={{ opacity: 0.94 }}
        >
          <Outlet />
        </Paper>
        <div className="hidden lg:block ml-24 xl:ml-48 mt-4">
          <AuthLottie />
        </div>
      </div>
    </div>
  );
}
