import { Chip, Tooltip } from "@mui/material";
import { CompanyAccountState } from "../../types";
import {
  CompanyAccountStateText,
  getCompanyAccountStateColor,
} from "../../utils/coimex-utils";

interface IProps {
  status: CompanyAccountState;
  remarks?: string;
}

export default function CompanyAccountStateChip({
  status,
  remarks,
}: IProps) {
  return (
    <Tooltip title={remarks ?? ""}>
      <Chip
        label={CompanyAccountStateText[status]}
        color={getCompanyAccountStateColor(status)}
        size="small"
        variant="outlined"
      />
    </Tooltip>
  );
}
