import { AccountCircle, Cached } from "@mui/icons-material";
import MenuIcon from "@mui/icons-material/Menu";
import { LoadingButton } from "@mui/lab";
import { CircularProgress } from "@mui/material";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import IconButton from "@mui/material/IconButton";
import Toolbar from "@mui/material/Toolbar";
import { styled } from "@mui/material/styles";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useInterval } from "usehooks-ts";
import LogoIcon from "../../components/LogoIcon/LogoIcon";
import ProfileMenu from "../../components/ProfileMenu/ProfileMenu";
import {
  selectRefreshStatus,
  selectRefreshUpdatedAt,
} from "../../modules/app/app.selectors";
import { appActions } from "../../modules/app/app.state";
import { authActions } from "../../modules/auth/auth.state";
import NotificationsButton from "../../modules/notifications/components/NotificationsButton";
import { AppRefreshStatus } from "../../types/app-refresh-status.type";
import { getElapsedTime } from "../../utils/dt-utils";

interface MainLayoutHeaderProps extends MuiAppBarProps {
  open?: boolean;
  toggleDraw?: () => void;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<MainLayoutHeaderProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
}));

export function MainLayoutHeader({ open, toggleDraw }: MainLayoutHeaderProps) {
  const refreshStatus = useSelector(selectRefreshStatus);
  const refreshUpdatedAt = useSelector(selectRefreshUpdatedAt);
  const [refreshUpdatedAtRelative, setRefreshUpdatedAtRelative] =
    React.useState(null);
  const [profileAnchorEl, setProfileAnchorEl] =
    React.useState<null | HTMLElement>(null);
  const profileOpen = Boolean(profileAnchorEl);
  const dispatch = useDispatch();

  const updateRefreshTime = () => {
    setRefreshUpdatedAtRelative(
      refreshUpdatedAt ? (getElapsedTime(refreshUpdatedAt) as any) : null
    );
  };

  React.useEffect(() => {
    updateRefreshTime();
  }, [refreshUpdatedAt]);

  useInterval(updateRefreshTime, 60 * 1000);

  const handleRefresh = () => {
    dispatch(
      appActions.changeRefresh({
        status: AppRefreshStatus.Invalidated,
      })
    );
  };

  const handleProfileClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setProfileAnchorEl(event.currentTarget);
  };

  const handleProfileClose = () => {
    setProfileAnchorEl(null);
  };

  return (
    <AppBar position="fixed" open={open}>
      <Toolbar>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={toggleDraw}
          edge="start"
          sx={{
            marginRight: 5,
          }}
        >
          <MenuIcon />
        </IconButton>
        <LogoIcon />
        <div className="flex-1" />
        <div className="flex items-center gap-2">
          {refreshStatus && refreshStatus !== AppRefreshStatus.Hide && (
            <LoadingButton
              size="large"
              color="inherit"
              loading={refreshStatus !== AppRefreshStatus.Idle}
              loadingIndicator={<CircularProgress color="white" size={16} />}
              onClick={handleRefresh}
            >
              <div className="flex flex-col items-center">
                <Cached />
                <span className="font-lato text-xs normal-case first-letter:uppercase">
                  {refreshUpdatedAtRelative}
                </span>
              </div>
            </LoadingButton>
          )}
          <NotificationsButton />
          <IconButton
            size="large"
            edge="end"
            color="inherit"
            onClick={handleProfileClick}
          >
            <AccountCircle />
          </IconButton>
        </div>
        <ProfileMenu
          anchorEl={profileAnchorEl}
          open={profileOpen}
          onClose={() => handleProfileClose()}
        />
      </Toolbar>
    </AppBar>
  );
}
