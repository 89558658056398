import _ from "lodash";
import {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { Socket, io } from "socket.io-client";
import { CONSTANTS } from "../common/constants";
import ls from "../common/ls";
import { selectIsAuthenticated } from "../modules/auth/auth.selectors";
import { WsEvent } from "../modules/blockchain/types";
import { notificationsActions } from "../modules/notifications/notifications.state";
import { Notification } from "../types";
import EventEmitter from "events";

interface ContextProps {
  isConnected: boolean;
  onBlockchainEvent: EventEmitter;
}

const WsContext = createContext<ContextProps>(null);

export default function WsProvider({ children }) {
  const socket = useRef<Socket>(null);
  const [isConnected, setIsConnected] = useState(true);
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const dispatch = useDispatch();
  const onBlockchainEvent = useMemo(() => new EventEmitter(), []);

  const addListeners = () => {
    socket.current.on("connect", onConnect);
    socket.current.on("disconnect", onDisconnect);
    socket.current.on(WsEvent.Notification, onNotification);
    socket.current.on(WsEvent.Blockchain, onBlockchain);
  };

  const removeListeners = () => {
    socket.current?.off("connect", onConnect);
    socket.current?.off("disconnect", onDisconnect);
    socket.current?.off(WsEvent.Notification, onNotification);
    socket.current?.off(WsEvent.Blockchain, onBlockchain);
  };

  const onConnect = () => {
    onConnectionChanged(true);
  };

  const onDisconnect = () => {
    onConnectionChanged(false);
  };

  const onConnectionChanged = _.debounce((isConnected: boolean) => {
    console.log(`Web socket ${isConnected ? "connected" : "disconnected"}.`);
    setIsConnected(isConnected);
  }, 2000);

  const onNotification = (notification: Notification) => {
    dispatch(notificationsActions.notificationReceived(notification));
  };

  const onBlockchain = ({ type, event }) => {
    onBlockchainEvent.emit(type, event);
  };

  useEffect(() => {
    onConnectionChanged(false);
    if (isAuthenticated) {
      socket.current = io(CONSTANTS.WsUrl, {
        path: "/ws",
        auth: {
          token: ls.token,
        },
        transports: ["polling", "websocket"],
      });
      addListeners();
    }

    return () => {
      removeListeners();
      socket.current?.disconnect();
    };
  }, [isAuthenticated]);

  return (
    <WsContext.Provider value={{ isConnected, onBlockchainEvent }}>
      {children}
    </WsContext.Provider>
  );
}

export const useWs = () => {
  return useContext(WsContext);
};
