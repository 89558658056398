import { CssBaseline } from "@mui/material";
import useAppInitializer from "./hooks/use-app-initializer";
import useHttpErrorInterceptor from "./http/http-error.interceptor";
import "./http/index";
import AppRoutes from "./routes";

function App() {
  useAppInitializer();
  useHttpErrorInterceptor();

  return (
    <div className="h-screen w-screen">
      <CssBaseline />
      <AppRoutes />
    </div>
  );
}

export default App;
