import { BuildCircleOutlined } from "@mui/icons-material";
import ToggleBtnGroup from "../../../components/ToggleBtnGroup/ToggleBtnGroup";
import { AppPage } from "../../../types";

interface IProps<
  T = AppPage.CommodityTokens | AppPage.CommodityTokensSettings
> {
  tab: T;
  onSelectedTabChange: (tab: T) => void;
}

export default function CommodityTokensFilters({
  tab,
  onSelectedTabChange,
}: IProps) {
  return (
    <ToggleBtnGroup
      items={[
        {
          label: "Commodity Tokens",
          value: AppPage.CommodityTokens,
        },
        {
          label: (
            <span>
              <BuildCircleOutlined /> Settings
            </span>
          ),
          value: AppPage.CommodityTokensSettings,
        },
      ]}
      selectedValue={tab}
      onSelectedValueChanged={onSelectedTabChange}
    />
  );
}
