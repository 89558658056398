import { CompanyAccountState } from "./company-account-state.type";

export const CompanyAccountStateOrders: Record<CompanyAccountState, number> = {
    Draft: 0,
    BuyPlan: 1,
    CompleteProfile: 1,
    UnderReview: 2,
    Rejected: 3,
    Active: 3,
    Canceled: 3
} 