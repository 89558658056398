import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, CircularProgress, Tab } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import BottomSheetLayout from "../../../components/BottomSheetLayout/BottomSheetLayout";
import { companiesApi } from "../../../http";
import { Company } from "../../../types";
import CompanyPayments from "./CompanyPayments";
import CompanyInfo from "./CompanyInfo";

interface IProps {
  id: string;
  onClose: () => void;
}

export default function CompanyPage({ id, onClose }: IProps) {
  const [tab, setTab] = useState("0");
  const [company, setCompany] = useState<Company | null>(null);
  const navigate = useNavigate();

  const handleTabChange = (_, tab) => {
    setTab(tab);
  };

  const getCompany = async () => {
    setCompany(null);
    try {
      setCompany(await companiesApi.getCompany(id));
    } catch (ignored) {
      navigate(-1);
    }
  };

  useEffect(() => {
    id && getCompany();
  }, [id]);

  return (
    <BottomSheetLayout title={company?.name} onClose={onClose}>
      <div className="relative p-4 centered">
        <TabContext value={tab}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList onChange={handleTabChange}>
              <Tab
                className="normal-case"
                label="Company Information"
                value="0"
              />
              <Tab className="normal-case" label="Payments" value="1" />
              <Tab className="normal-case" label="Requests" value="2" />
              <Tab className="normal-case" label="Offers" value="3" />
            </TabList>
          </Box>

          <TabPanel value="0">
            {company ? (
              <CompanyInfo company={company!} onClose={onClose} />
            ) : (
              <div className="absolute left-0 right-0 top-32 flex justify-center">
                <CircularProgress color="primary" size="32px" />
              </div>
            )}
          </TabPanel>
          <TabPanel value="1">
            <CompanyPayments companyId={id} joinedAt={company?.createdAt!} />
          </TabPanel>
        </TabContext>
      </div>
    </BottomSheetLayout>
  );
}
