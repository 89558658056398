import { Avatar, AvatarGroup, AvatarGroupProps } from "@mui/material";
import React, { MouseEventHandler } from "react";
import useImagePreview from "../../hooks/use-image-preview";

interface IProps {
  urls: string[];
  max?: number;
}

export default function AvatarsOverlap({
  urls,
  max,
  ...rest
}: IProps & AvatarGroupProps) {
  const openImagePreviewer = useImagePreview();

  const handleClick: MouseEventHandler = (e) => {
    e.stopPropagation();
    openImagePreviewer(urls, 0);
  };

  return (
    <AvatarGroup
      className="justify-end"
      max={max}
      {...rest}
      onClick={handleClick}
    >
      {urls?.map((url) => (
        <Avatar key={url} src={url} />
      ))}
    </AvatarGroup>
  );
}
