import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, IconButton, Tab } from "@mui/material";
import { useState } from "react";
import { CommodityToken } from "../../../types";
import CommodityTokenInfo from "./CommodityTokenInfo";
import { Close } from "@mui/icons-material";
import CommodityTokenPricesInfo from "./CommodityTokenPricesInfo";

interface IProps {
  token: CommodityToken;
  close?: () => void;
}

export default function CommodityTokenPage({ token, close }: IProps) {
  const [tab, setTab] = useState("0");

  const handleTabChange = (_, tab) => {
    setTab(tab);
  };

  return (
    <div className="relative w-[590px] max-w-full p-4">
      <TabContext value={tab}>
        <Box
          className="sticky top-0 bg-bg z-10"
          sx={{ borderBottom: 1, borderColor: "divider" }}
        >
          <TabList onChange={handleTabChange}>
            <Tab className="normal-case" label="Token Information" value="0" />
            <Tab className="normal-case" label="Prices" value="1" />
            <Tab className="normal-case" label="Transactions" value="2" />
          </TabList>

          <IconButton
            tabIndex={-1}
            onClick={() => close()}
            className="absolute top-0 right-0"
          >
            <Close />
          </IconButton>
        </Box>

        <TabPanel value="0">
          <CommodityTokenInfo token={token} />
        </TabPanel>

        <TabPanel value="1">
          <CommodityTokenPricesInfo token={token} />
        </TabPanel>
      </TabContext>
    </div>
  );
}
