import { LoadingButton } from "@mui/lab";
import { useSmartDealForm } from "../providers/SmartDealFormProvider";
import SmartDealFormProformaInvoice from "./SmartDealForm/SmartDealFormProformaInvoice";
import { useEffect, useRef } from "react";

export default function ProformaInvoiceForm() {
  const { value, nexting, step, close } = useSmartDealForm();
  const PROFORMA_INVOICE_STEP = useRef(step);

  useEffect(() => {
    if (step !== PROFORMA_INVOICE_STEP.current) {
      close(value.proformaInvoice);
    }
  }, [step]);

  return (
    <div className="w-96 p-4 pb-6">
      <SmartDealFormProformaInvoice>
        <LoadingButton
          type="submit"
          variant="contained"
          color="secondary"
          loading={nexting}
        >
          Save
        </LoadingButton>
      </SmartDealFormProformaInvoice>
    </div>
  );
}
