import { Avatar, AvatarGroup } from "@mui/material";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import CurrencyFormat from "../../../../components/CurrencyFormat/CurrencyFormat";
import { Company, Opportunity } from "../../../../types";
import { getCurrencySymbol } from "../../../../utils/coimex-utils";
import { selectSubCategoryById } from "../../../settings/settings.selectors";
import { NotificationTemplateProps } from "../../types/notification-template.type";
import "../Notifications.scss";
import { ChevronRight } from "@mui/icons-material";

export default function OpportunityUnderReviewNotification({
  payload,
  isExpanded,
}: NotificationTemplateProps<{
  opportunity: Partial<Opportunity>;
  company: Partial<Company>;
}>) {
  const subCategory = useSelector((state) =>
    //@ts-ignore
    selectSubCategoryById(state, payload?.opportunity?.subCategory?.id)
  );

  return (
    <div className="OpportunityUnderReviewNotification">
      <Link
        className="link"
        to={`/companies/${payload?.company?.id}`}
        onClick={(e) => e.stopPropagation()}
      >
        {payload?.company?.name}
      </Link>{" "}
      posted new product {payload?.opportunity?.type?.toLowerCase()} for{" "}
      <Link
        className="link"
        to={`/${payload?.opportunity?.type?.toLowerCase()}s/${
          payload?.opportunity?.id
        }`}
        onClick={(e) => e.stopPropagation()}
      >
        {payload?.opportunity?.productName}
      </Link>
      .
      {isExpanded && (
        <div className="mt-2 flex flex-col items-start animate-fade-in">
          <AvatarGroup max={4}>
            {payload?.opportunity?.productPhotos?.map((p) => (
              <Avatar key={p} src={p} />
            ))}
          </AvatarGroup>

          <div className="ml-1 mt-3 italic">
            {subCategory?.category?.name} <ChevronRight fontSize="small" />
            {subCategory?.name} <ChevronRight fontSize="small" />
            {payload?.opportunity?.productName}
          </div>

          <ul>
            <li>
              <span className="key">Price</span>
              <span className="value">
                <CurrencyFormat
                  amount={payload?.opportunity?.price}
                  symbol={getCurrencySymbol(payload?.opportunity?.currency)}
                />
              </span>
            </li>

            <li>
              <span className="key">Description</span>
              <span className="value">
                {payload?.opportunity?.productDetails}
              </span>
            </li>

            <li>
              <span className="key">Specification</span>
              <span className="value">
                {payload?.opportunity?.specification}
              </span>
            </li>

            <li>
              <span className="key">Payment Terms</span>
              <span className="value">
                {payload?.opportunity?.paymentTerms}
              </span>
            </li>

            <li>
              <span className="key">Shipping Terms</span>
              <span className="value">
                {payload?.opportunity?.shippingTerms}
              </span>
            </li>

            <li>
              <span className="key">Quantity</span>
              <span className="value">
                {payload?.opportunity?.quantity}{" "}
                {payload?.opportunity?.quantityUnit}
              </span>
            </li>

            <li>
              <span className="key">Packaging</span>
              <span className="value">{payload?.opportunity?.packaging}</span>
            </li>

            {payload?.opportunity?.minOrder && (
              <li>
                <span className="key">Min order</span>
                <span className="value">{payload?.opportunity?.packaging}</span>
              </li>
            )}
          </ul>
        </div>
      )}
    </div>
  );
}
