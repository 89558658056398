import { createSlice } from "@reduxjs/toolkit";
import { Pagination, Plan, PlanSubscription } from "../../types";
import { addCommonReducers } from "../../utils/store-utils";
import { getPlanSubscriptionsAction, getPlanUpgradeRequestsAction, getPlansAction } from "./plans.effects";
import { DEFAULTS } from "../../common/constants";
import { PlanSubscriptionsFilters } from "./types/plan-subscriptions-filters.type";
import { PlanUpgradeRequest } from "./types/plan-upgrade-request.type";

export const PLANS_STATE_NAME = 'plans';

export interface PlansState {
    plans: Plan[]
    subscriptions: PlanSubscription[];
    filters: PlanSubscriptionsFilters
    pagination: Pagination;
    upgradeRequests: PlanUpgradeRequest[];
}

export const plansState = createSlice({
    name: PLANS_STATE_NAME,
    initialState: {
        plans: [],
        subscriptions: [],
        filters: {
            orderBy: {
                field: 'createdAt',
                sort: 'desc'
            }
        },
        pagination: {
            page: 0,
            pageSize: DEFAULTS.PageSize,
            total: 0
        },
        upgradeRequests: [],
        [getPlanSubscriptionsAction.pending.type]: true,
        [getPlanUpgradeRequestsAction.pending.type]: true
    } as PlansState,
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(getPlansAction.fulfilled, (state, action) => {
                state.plans = action.payload;
            })
            .addCase(getPlanSubscriptionsAction.fulfilled, (state, action) => {
                const { paginatedPlanSubscriptions, page, pageSize, filters } = action.payload;
                state.subscriptions = paginatedPlanSubscriptions.data;
                state.pagination.total = paginatedPlanSubscriptions.total;
                state.pagination.page = page;
                state.pagination.pageSize = pageSize;
                state.filters = filters;
            })
            .addCase(getPlanUpgradeRequestsAction.fulfilled, (state, action) => {
                state.upgradeRequests = action.payload.data;
            })
        addCommonReducers(builder, PLANS_STATE_NAME);
    }
});

export const plansActions = plansState.actions;
export const plansReducer = plansState.reducer;