import { FullscreenExitOutlined, FullscreenOutlined } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { scaleLinear } from "d3-scale";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import { useSelector } from "react-redux";
import {
    ComposableMap,
    Geographies,
    Geography
} from "react-simple-maps";
import LoadingOverlay from "../../../components/LoadingOverlay/LoadingOverlay";
import { selectCompaniesCountryDistribution, selectIsGetCompaniesCountriesDistributionPending } from "../dashboard.selectors";
import { CompaniesCountryDistribution } from "../types/companies-country-distribution.type";

const colorScale = scaleLinear<string>()
    .domain([0, 5])
    .range(["#f9f9f9", "#17a2b8"]);

const Map = ({ countries }: { countries: CompaniesCountryDistribution[] }) => (
    <ComposableMap
        projectionConfig={{
            rotate: [-20, -10, 0],
            scale: 80,
        }}
        width={380}
        height={210}
    >
        <Geographies geography="/assets/data/countries.json">
            {({ geographies }) =>
                geographies.map(geo => {
                    const country = countries.find(c => c.country.cca3 === geo.id);
                    return (
                        <Geography
                            key={geo.rsmKey}
                            geography={geo}
                            fill={country ? colorScale(country.companiesCount) : "#f9f9f9"}
                            stroke="#D6D6DA"
                            style={{
                                default: { outline: "none" },
                                hover: { outline: "none" },
                                pressed: { outline: "none" },
                            }}
                        />
                    );
                })
            }
        </Geographies>
    </ComposableMap>
)

export default function CompaniesCountryDistributionMap() {
    const countries = useSelector(selectCompaniesCountryDistribution);
    const isPending = useSelector(selectIsGetCompaniesCountriesDistributionPending);
    const handle = useFullScreenHandle();

    const toggleFullScreen = () => {
        if (handle.active) {
            handle.exit();
        } else {
            handle.enter();
        }
    }

    return (
        <LoadingOverlay loading={isPending}>
            <FullScreen handle={handle}>
                <div className="p-widget w-full flex flex-col">
                    <div className="widget-header mb-5 flex justify-between items-center">
                        Companies Country Distribution
                        <IconButton onClick={toggleFullScreen}>
                            {handle.active ? <FullscreenExitOutlined /> : <FullscreenOutlined />}
                        </IconButton>
                    </div>
                    <Map countries={countries} />
                </div>
            </FullScreen>
        </LoadingOverlay>
    );
}
