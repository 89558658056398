import { createSlice } from "@reduxjs/toolkit";
import { DEFAULTS } from "../../common/constants";
import { Company, Pagination } from "../../types";
import { addCommonReducers } from "../../utils/store-utils";
import { getCompaniesAction, getCompaniesStatusCountsAction } from "./companies.effects";
import { CompaniesFilters } from "./types/companies-filters.type";
import { CompaniesStatusFilter } from "./types/companies-status-filter.type";

export const COMPANIES_STATE_NAME = 'companies';

export interface CompaniesState {
    companies: Company[]
    statusCounts: Record<CompaniesStatusFilter, number>
    filters: CompaniesFilters
    pagination: Pagination
}

export const companiesState = createSlice({
    name: COMPANIES_STATE_NAME,
    initialState: {
        companies: [],
        statusCounts: {
            All: 0,
            UnderReview: 0,
            Rejected: 0,
            Active: 0
        },
        filters: {
            status: CompaniesStatusFilter.All,
            orderBy: {
                field: 'createdAt',
                sort: 'desc'
            }
        },
        pagination: {
            page: 0,
            pageSize: DEFAULTS.PageSize,
            total: 0
        }
    } as CompaniesState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getCompaniesAction.fulfilled, (state, action) => {
                const { paginatedCompanies, page, pageSize, filters } = action.payload;
                state.companies = paginatedCompanies.data;
                state.pagination.total = paginatedCompanies.total;
                state.pagination.page = page;
                state.pagination.pageSize = pageSize;
                state.filters = filters;
            })
            .addCase(getCompaniesStatusCountsAction.fulfilled, (state, action) => {
                state.statusCounts = action.payload;
            });
        addCommonReducers(builder, COMPANIES_STATE_NAME);
    }
});

export const companiesActions = companiesState.actions;
export const companiesReducer = companiesState.reducer;