import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../store";
import {
    getNotificationsAction,
    getNotificationsStatusAction,
} from "./notifications.effects";

export const selectNotificationsState = (state: RootState) =>
    state.notifications;

export const selectNotifications = createSelector(
    selectNotificationsState,
    (state) => (state.notifications || []).sortBy("createdAt", "des")
);

export const selectIsGetNotificationsPending = createSelector(
    selectNotificationsState,
    (state) => state[getNotificationsAction.pending.type]
);

export const selectNotificationsTotal = createSelector(
    selectNotificationsState,
    (state) => state.total
);

export const selectNotificationsStatus = createSelector(
    selectNotificationsState,
    (state) => state.status
);

export const selectNotSeenNotifications = createSelector(
    selectNotificationsStatus,
    (status) => status?.notSeen
);

export const selectLastSeenNotification = createSelector(
    selectNotificationsStatus,
    (status) => status?.lastSeen
);

export const selectIsGetNotificationsStatusPending = createSelector(
    selectNotificationsState,
    (state) => state[getNotificationsStatusAction.pending.type]
);

export const selectHasMoreNotifications = createSelector(
    selectNotifications,
    selectNotificationsTotal,
    (notifications, total) => notifications.length < total
);
