import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import classNames from "classnames";
import { ReactNode } from "react";

interface IProp {
  items: ToggleBtnGroupItem[];
  selectedValue: any | null;
  onSelectedValueChanged?: (value: any) => void;
}

export interface ToggleBtnGroupItem {
  label: ReactNode;
  value: any;
}

export default function ToggleBtnGroup({
  items,
  selectedValue,
  onSelectedValueChanged,
}: IProp) {
  return (
    <ToggleButtonGroup className="">
      {items.map((item) => (
        <ToggleButton
          className={classNames(
            "border-b-primary",
            "border-0",
            "rounded-none",
            "normal-case",
            "font-lato font-bold",
            { "border-b-2": item.value === selectedValue },
            { "text-primary": item.value === selectedValue }
          )}
          key={item.value}
          value="bold"
          onClick={() => onSelectedValueChanged?.(item.value)}
        >
          {item.label}
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  );
}
