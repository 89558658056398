import { LoadingButton } from "@mui/lab";
import { Button, TextField } from "@mui/material";
import { useRef, useState } from "react";
import { useForm } from "react-hook-form";
import useFocus from "../../hooks/use-focus";
import { authApi } from "../../http";
import { CloseModal } from "../../providers/ModalProvider";

interface IProps {
  close: CloseModal<{ twoFactorEnabled: boolean }>;
}

export default function DisableTwoFactorForm({ close }: IProps) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const tokenRef = useRef<HTMLInputElement>();
  useFocus(tokenRef);

  const onSubmit = async (data) => {
    try {
      setIsSubmitting(true);
      close(await authApi.enable2fa(false, data.token));
    } catch (error) {
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <form className="w-[400px] p-4" onSubmit={handleSubmit(onSubmit)}>
      <header className="modal-header">
        Disable Two Factor Authentication
      </header>
      <p className="mt-2">
        To confirm, enter authentication code from your authenticator app below:
      </p>
      <TextField
        inputRef={tokenRef}
        className="w-full mt-5"
        {...register("token", {
          required: true,
        })}
        placeholder="Verification Code"
        error={!!errors?.token}
        variant="outlined"
        size="small"
        inputProps={{
          autoComplete: "off",
        }}
      />
      <footer className="modal-footer flex flex-row-reverse gap-4">
        <LoadingButton
          type="submit"
          variant="contained"
          color="error"
          loading={isSubmitting}
        >
          Disable 2FA
        </LoadingButton>

        <Button type="button" variant="text" onClick={() => close()}>
          Cancel
        </Button>
      </footer>
    </form>
  );
}
