export function parseBadJson(badJson: string): object {
    return JSON.parse(
        badJson
            .replace(/:\s*"([^"]*)"/g, function (match, p1) {
                return ': "' + p1.replace(/:/g, "@colon@") + '"';
            })
            .replace(/:\s*'([^']*)'/g, function (match, p1) {
                return ': "' + p1.replace(/:/g, "@colon@") + '"';
            })
            .replace(/(['"])?([a-z0-9A-Z_]+)(['"])?\s*:/g, '"$2": ')
            .replace(/@colon@/g, ":")
            .replace(/,\n\s+/g, ',')
            .replace(",\n", "")
            .replace(/,(\s*})/, '$1')
    );
}
